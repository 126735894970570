import { Col, Row } from 'antd'
import React from 'react'
import { XItem } from '../../../components/common/Item'
import { VSpacer } from '../../../components/common/Space'
import { Text } from '../../../components/common/Text'
import { DrawerRowContainer } from '../../../components/input/container'
import { App, AppTableCH } from '../../../utils/app'
import { timeNowMS } from '../../../utils/time'
import {
    ProjectColumnAspectRatio,
    ProjectColumnDirector,
    ProjectColumnEndTime,
    ProjectColumnFrameRate,
    ProjectColumnHandleFrameEnd,
    ProjectColumnHandleFrameStart,
    ProjectColumnKind,
    ProjectColumnLabel,
    ProjectColumnLogo,
    ProjectColumnProducer,
    ProjectColumnResolution,
    ProjectColumnStartTime,
    ProjectColumnStatus,
} from './ProjectConfig'

const LabelWidth = 80
const ItemWidth = 358
const HasBorder = true
const EditAreaBG = 'transparent'

export const CompanyProjectInfoTab: React.FC<{ projectID: string }> = ({ projectID }) => {
    const [flushSeed, setFlushSeed] = React.useState(0)
    const [data, setData] = React.useState<any>({})

    React.useEffect(() => {
        if (projectID) {
            App.Api.details('company_project', [projectID])
                .then((v) => {
                    if (v && v.length === 1) {
                        setData(v[0])
                    } else {
                        setData({})
                    }
                })
                .catch(() => {
                    setData({})
                })
        }
    }, [projectID, flushSeed])

    const onChange = (adFlag: boolean) => {
        setFlushSeed(timeNowMS())
        if (adFlag) {
            App.EventHub.send(AppTableCH, 'FlushIndex', 'table.hub.my-company')
        } else {
            App.EventHub.send(AppTableCH, 'FlushPage', 'table.hub.my-company')
        }
    }

    return (
        <Col style={{ marginLeft: 16, width: 358 }}>
            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={ProjectColumnLogo.column?.title}
                width={ItemWidth}
                height={68}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        style={{ paddingLeft: 0, paddingRight: 8 }}
                        width={ItemWidth - LabelWidth - 8}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnLogo}
                        data={data}
                        onChange={onChange}
                    />
                }
            />

            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={ProjectColumnKind.column?.title}
                width={ItemWidth}
                height={32}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        width={ItemWidth - LabelWidth - 8}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnKind}
                        data={data}
                        onChange={onChange}
                    />
                }
            />

            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={ProjectColumnLabel.column?.title}
                width={ItemWidth}
                height={32}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        width={ItemWidth - LabelWidth - 8}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnLabel}
                        data={data}
                        onChange={onChange}
                    />
                }
            />

            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={ProjectColumnFrameRate.column?.title}
                width={ItemWidth}
                height={32}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        width={ItemWidth - LabelWidth - 8}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnFrameRate}
                        data={data}
                        onChange={onChange}
                    />
                }
            />

            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={ProjectColumnResolution.column?.title}
                width={ItemWidth}
                height={32}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        width={ItemWidth - LabelWidth - 8}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnResolution}
                        data={data}
                        onChange={onChange}
                    />
                }
            />

            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={ProjectColumnAspectRatio.column?.title}
                width={ItemWidth}
                height={32}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        width={ItemWidth - LabelWidth - 8}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnAspectRatio}
                        data={data}
                        onChange={onChange}
                    />
                }
            />

            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={'手柄帧'}
                width={ItemWidth}
                height={32}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        width={(ItemWidth - LabelWidth - 28) / 2}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnHandleFrameStart}
                        data={data}
                        placement={'bottomLeft'}
                        onChange={onChange}
                    />
                }
                delimiter={
                    <Row justify={'center'} style={{ width: 20 }}>
                        <Text>-</Text>
                    </Row>
                }
                right={
                    <XItem
                        width={(ItemWidth - LabelWidth - 28) / 2}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnHandleFrameEnd}
                        data={data}
                        placement={'bottomRight'}
                        onChange={onChange}
                    />
                }
            />

            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={ProjectColumnDirector.column?.title}
                width={ItemWidth}
                height={32}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        width={ItemWidth - LabelWidth - 8}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnDirector}
                        data={data}
                        onChange={onChange}
                    />
                }
            />

            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={ProjectColumnProducer.column?.title}
                width={ItemWidth}
                height={32}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        width={ItemWidth - LabelWidth - 8}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnProducer}
                        data={data}
                        onChange={onChange}
                    />
                }
            />

            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={ProjectColumnStartTime.column?.title}
                width={ItemWidth}
                height={32}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        width={ItemWidth - LabelWidth - 8}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnStartTime}
                        data={data}
                        onChange={onChange}
                    />
                }
            />

            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={ProjectColumnEndTime.column?.title}
                width={ItemWidth}
                height={32}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        width={ItemWidth - LabelWidth - 8}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnEndTime}
                        data={data}
                        onChange={onChange}
                    />
                }
            />

            <DrawerRowContainer
                style={{ marginTop: 12 }}
                label={ProjectColumnStatus.column?.title}
                width={ItemWidth}
                height={32}
                labelWidth={LabelWidth}
                left={
                    <XItem
                        width={ItemWidth - LabelWidth - 8}
                        bordered={HasBorder}
                        editAreaBG={EditAreaBG}
                        config={ProjectColumnStatus}
                        data={data}
                        onChange={onChange}
                    />
                }
            />

            <VSpacer height={40} />
        </Col>
    )
}
