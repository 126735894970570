import styled from 'styled-components'
import { Row, Tabs } from 'antd'
import { useElementSize } from 'usehooks-ts'
import React from 'react'
import config from '../../../package.json'

export const StyledTabs = styled(Tabs)`
    & .ant-tabs-nav {
        margin: 0 0 1px 0;
    }
    & .ant-tabs-tab:hover {
        color: ${config.app.fontColorStrong};
    }
    & .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${config.app.fontColorStrong};
    }
    & .ant-tabs-tab-btn:focus {
        color: ${config.app.fontColorStrong};
    }
    & .ant-tabs-nav-more {
        display: none;
    }
    & .ant-tabs-tab-btn > div {
        text-align: center;
    }
    & .ant-tabs-content {
        height: 100%;
    }
    & .ant-tabs-content > div {
        height: 100%;
    }
`

export const TabTitle: React.FC<{ width: number; value: string }> = ({ width, value }) => {
    return (
        <Row justify={'center'} style={{ width: width }}>
            {value}
        </Row>
    )
}

export const XTabs: React.FC<{
    style?: React.CSSProperties
    size: 'small' | 'middle'
    items: Array<{ label: string; key: string; children?: React.ReactNode }>
    activeKey?: string
    onChange?: (key: string) => void
    tabBarBackground?: string
}> = ({ style, size, activeKey, items, tabBarBackground, onChange }) => {
    const [elemRef, { width }] = useElementSize()
    const tabsWidth = (width - 32 * (items.length - 1)) / items.length
    const tabsItem = items.map((v) => {
        return { label: <div style={{ width: tabsWidth }}>{v.label}</div>, key: v.key, children: v.children }
    })

    return (
        <div ref={elemRef} style={{ ...style }}>
            <StyledTabs
                style={{ height: '100%' }}
                activeKey={activeKey}
                items={tabsItem}
                centered={true}
                size={size}
                tabBarStyle={{ background: tabBarBackground }}
                onChange={onChange}
            />
        </div>
    )
}
