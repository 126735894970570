import { Carousel, Col, Image, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'

const support_image_1_1 = require('../assets/support/1-1.png')
const support_image_1_2 = require('../assets/support/1-2.png')
const support_image_1_3 = require('../assets/support/1-3.png')
const support_image_1_4 = require('../assets/support/1-4.png')
const support_image_1_5 = require('../assets/support/1-5.png')
const support_image_1_6 = require('../assets/support/1-6.png')

const support_image_2_1 = require('../assets/support/2-1.png')
const support_image_2_2 = require('../assets/support/2-2.png')
const support_image_2_3 = require('../assets/support/2-3.png')

const support_image_3_1 = require('../assets/support/3-1.png')
const support_image_3_2 = require('../assets/support/3-2.png')
const support_image_3_3 = require('../assets/support/3-3.png')

const CarouselWrapper = styled(Carousel)`
    > .slick-dots li button {
        background: white;
    }
    > .slick-dots li.slick-active button {
        background: #364d79;
    }
`

export const AppSupportPage: React.FC = () => {
    return (
        <Row>
            <Row style={{ width: '100%' }} justify={'center'}>
                <h1 style={{ margin: 16 }}>Mandrillvfx场景 IOS App</h1>
            </Row>

            <Row style={{ width: '100%' }}>
                <h2 style={{ margin: 16 }}>介绍</h2>
            </Row>
            <Row style={{ width: '100%', marginLeft: 32 }}>
                <div>
                    MandrillVFX场景，一款为电影和电视剧专门设计拍摄数据记录软件，是您在拍摄过程中的得力助手。这款应用程序不仅优雅而直观，而且功能强大，可以让您轻松记录和管理所有重要的拍摄信息。
                </div>
            </Row>

            <Row style={{ width: '100%' }}>
                <h2 style={{ margin: 16 }}>软件功能</h2>
            </Row>
            <Row style={{ width: '100%', marginLeft: 0 }}>
                <ul>
                    <li>记录电影和电视剧拍摄过程中的镜头信息</li>
                    <li>记录现场参考图</li>
                    <li>记录导演对后期的制作要求</li>
                    <li>统计已经拍摄的信息</li>
                </ul>
            </Row>

            <Row style={{ width: '100%' }}>
                <h2 style={{ margin: 16 }}>使用指南</h2>
            </Row>

            <Row style={{ width: '100%' }}>
                <h4 style={{ margin: 16 }}>注册账号，创建项目并生成拍摄链接码</h4>
            </Row>

            <Col style={{ marginLeft: 32, width: 1080 }}>
                <CarouselWrapper dotPosition={'bottom'}>
                    <div style={{ position: 'absolute', width: 1080, height: 600 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>
                            第一步：登陆 <a href="https://hub.mandrillvfx.com">https://hub.mandrillvfx.com</a>
                        </h5>
                        <Image src={support_image_1_1} style={{ marginBottom: 30 }} preview={false}></Image>
                    </div>
                    <div style={{ position: 'absolute', width: 1080, height: 600 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>第二步：点击创建公司</h5>
                        <Image src={support_image_1_2} style={{ marginBottom: 30 }} preview={false}></Image>
                    </div>
                    <div style={{ position: 'absolute', width: 1080, height: 600 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>
                            第三步：选择创建的公司，并点击新建项目
                        </h5>
                        <Image src={support_image_1_3} style={{ marginBottom: 30 }} preview={false}></Image>
                    </div>
                    <div style={{ position: 'absolute', width: 1080, height: 600 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>
                            第四步：在项目页面上，点击右上角的加号，为项目添加一个拍摄记录人员
                        </h5>
                        <Image src={support_image_1_4} style={{ marginBottom: 30 }} preview={false}></Image>
                    </div>
                    <div style={{ position: 'absolute', width: 1080, height: 600 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>第五步：填写记录人员信息</h5>
                        <Image src={support_image_1_5} style={{ marginBottom: 30 }} preview={false}></Image>
                    </div>
                    <div style={{ position: 'absolute', width: 1080, height: 600 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>
                            第六步：点击查看链接，复制生成的链接码
                        </h5>
                        <Image src={support_image_1_6} style={{ marginBottom: 30 }} preview={false}></Image>
                    </div>
                </CarouselWrapper>
            </Col>

            <Row style={{ width: '100%' }}>
                <h4 style={{ margin: 16 }}>下载IOS应用，使用链接码导入应用</h4>
            </Row>

            <Col style={{ marginLeft: 32, width: 1080 }}>
                <CarouselWrapper dotPosition={'bottom'}>
                    <div style={{ position: 'absolute', width: 1080, height: 800 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>第一步：点击导入新项目</h5>
                        <Row style={{}} justify={'center'}>
                            <Image
                                src={support_image_2_1}
                                style={{ width: 600, marginBottom: 30 }}
                                preview={false}
                            ></Image>
                        </Row>
                    </div>

                    <div style={{ position: 'absolute', width: 1080, height: 800 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>
                            第二步：输入链接码，并点击导入确认。
                            （注意：因为字符混淆，请一定使用复制粘贴输入。请不要手动输入或使用扫描文本方式输入）
                        </h5>
                        <Row style={{}} justify={'center'}>
                            <Image
                                src={support_image_2_2}
                                style={{ width: 600, marginBottom: 30 }}
                                preview={false}
                            ></Image>
                        </Row>
                    </div>

                    <div style={{ position: 'absolute', width: 1080, height: 800 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>第三步：等待数据同步完成</h5>
                        <Row style={{}} justify={'center'}>
                            <Image
                                src={support_image_2_3}
                                style={{ width: 600, marginBottom: 30 }}
                                preview={false}
                            ></Image>
                        </Row>
                    </div>
                </CarouselWrapper>
            </Col>

            <Row style={{ width: '100%' }}>
                <h4 style={{ margin: 16 }}>记录拍摄数据</h4>
            </Row>

            <Col style={{ marginLeft: 32, width: 1080 }}>
                <CarouselWrapper dotPosition={'bottom'}>
                    <div style={{ position: 'absolute', width: 1080, height: 800 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>
                            第一步：创建场次。在主页面点击场次信息,再点击添加
                        </h5>
                        <Row style={{}} justify={'center'}>
                            <Image
                                src={support_image_3_1}
                                style={{ width: 600, marginBottom: 30 }}
                                preview={false}
                            ></Image>
                        </Row>
                    </div>

                    <div style={{ position: 'absolute', width: 1080, height: 800 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>
                            第二步：回到主页面，点击镜头录入。选择刚才添加的场次。
                        </h5>
                        <Row style={{}} justify={'center'}>
                            <Image
                                src={support_image_3_2}
                                style={{ width: 600, marginBottom: 30 }}
                                preview={false}
                            ></Image>
                        </Row>
                    </div>

                    <div style={{ position: 'absolute', width: 1080, height: 800 }}>
                        <h5 style={{ background: '#364d79', margin: 0, padding: 8 }}>第三步：开始记录镜头数据</h5>
                        <Row style={{}} justify={'center'}>
                            <Image
                                src={support_image_3_3}
                                style={{ width: 600, marginBottom: 30 }}
                                preview={false}
                            ></Image>
                        </Row>
                    </div>
                </CarouselWrapper>
            </Col>
            <Row style={{ width: '100%' }}>
                <h2 style={{ margin: 16 }}>特殊注意事项</h2>
            </Row>
            <Row style={{ width: '100%', marginLeft: 0 }}>
                <ul>
                    <li>
                        导入新项目时，一定要复制链接码，因为相近字符混淆，请不要手动输入或使用ios扫描文本的方式输入。
                    </li>
                    <li>为了数据安全，请及时导出数据并备份。</li>
                </ul>
            </Row>

            <Row style={{ width: '100%' }}>
                <h2 style={{ margin: 16 }}>隐私策略</h2>
            </Row>
            <Row style={{ width: '100%', marginLeft: 32 }}>
                <a href="http://mandrillvfx.com/privacy-policy.html">http://mandrillvfx.com/privacy-policy.html</a>
            </Row>

            <Row style={{ width: '100%' }}>
                <h2 style={{ margin: 16 }}>联系方式</h2>
            </Row>
            <Row style={{ width: '100%', marginLeft: 0 }}>
                <ul>
                    <li>联系电话：18612345678</li>
                    <li>公司邮箱：admin@mandrillvfx.com</li>
                    <li>技术支持邮箱：support@mandrillvfx.com</li>
                    <li>
                        公司网站：<a href="http://mandrillvfx.com">http://mandrillvfx.com</a>
                    </li>
                </ul>
            </Row>
        </Row>
    )
}
