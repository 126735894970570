import { Row, Tooltip } from 'antd'
import { copyToClipboard } from '../../utils/copy'
import { showError, showSuccess } from '../../utils/message'
import { IconButton } from '../common/Button'
import { XItemContext } from '../common/Item'
import { VSpacer } from '../common/Space'
import { Text } from '../common/Text'
import { PopupWindow } from '../common/Window'
import { ItemManager } from './index'

export const NewCompanyPopupButton: React.FC<{
    link: string
}> = ({ link }) => {
    const getContent = (confirmLoading: boolean) => {
        return (
            <>
                <Row justify={'center'} style={{ height: 13 }} />

                <Row justify={'center'} align={'middle'} style={{ marginTop: 6, marginLeft: 16, marginRight: 16 }}>
                    <Text>{link}</Text>

                    <Tooltip title={'复制链接码'}>
                        <span>
                            <IconButton
                                type={'icon'}
                                icon={'icon-fuzhi'}
                                onClick={() => {
                                    copyToClipboard(link)
                                        .then(() => {
                                            showSuccess('复制链接成功').finally()
                                        })
                                        .catch(() => {
                                            showError('复制链接失败').finally()
                                        })
                                }}
                            />
                        </span>
                    </Tooltip>
                </Row>

                <VSpacer height={16} />
            </>
        )
    }

    return (
        <PopupWindow
            title={'链接吗'}
            size={'middle'}
            checked={true}
            button={<IconButton type="text" label="查看链接" />}
            getContent={getContent}
            onSubmit={async () => {
                return true
            }}
            onOpenChange={(v) => {}}
        />
    )
}

export function initialize() {
    ItemManager.addPlugin('company-project-link', {
        renderItem: (ctx: XItemContext) => {
            if (ctx.data && ctx.config.key) {
                const v = ctx.data[ctx.config.key]
                return <NewCompanyPopupButton link={v} />
            } else {
                return ''
            }
        },
    })
}
