import React from 'react'
import { Input } from 'antd'
import { InputRectContainer } from './container'

export const PasswordRectInput: React.FC<{
    label: string
    value: string
    width: number
    placeholder: string
    allowClear?: boolean
    autoFocus?: boolean
    onChange: (v: any) => void
    disabled?: boolean
    error?: Error | null
}> = ({
    label,
    value,
    width,
    placeholder,
    allowClear,
    autoFocus,
    onChange,
    disabled,
    error,
}) => {
    return (
        <InputRectContainer
            label={label}
            width={width}
            disabled={disabled}
            element={
                <Input.Password
                    placeholder={placeholder}
                    value={value}
                    bordered={false}
                    allowClear={allowClear}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    onChange={(evt) => {
                        onChange(evt.target.value)
                    }}
                />
            }
            error={error}
        />
    )
}
