import React from 'react'
import { DatePicker } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import { utcMSToDate } from '../../utils/time'

export const StyledDiv = styled.div`
    position: relative;

    & .ant-picker-dropdown {
        top: 0 !important;
        left: 0 !important;
    }

    &.popup-container > div {
        position: relative !important;
    }

    & .ant-picker-panel-container {
        background-color: transparent !important;
        box-shadow: none;
    }

    & div {
        animation-duration: 0s !important;
    }
`

export const XDateEmbeddedPicker: React.FC<{
    style?: React.CSSProperties
    value: number
    onChange: (v: number | null) => void
}> = ({ style, value, onChange }) => {
    const [open, setOpen] = React.useState(false)
    const [containerElem, setContainerElem] = React.useState(null)

    React.useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 20)
    }, [])

    return (
        <div style={{ width: 280, height: 328, borderRadius: 2, overflow: 'hidden', ...style }}>
            {containerElem ? (
                <DatePicker
                    open={open}
                    value={value > 0 ? moment(utcMSToDate(value as number)) : undefined}
                    format={'YYYY-MM-DD'}
                    style={{ width: '100%' }}
                    onChange={(v) => {
                        if (v) {
                            const data = v.toDate()
                            data.setHours(0, 0, 0, 0)
                            onChange(data.getTime())
                        } else {
                            onChange(null)
                        }
                    }}
                    placement={'bottomLeft'}
                    getPopupContainer={(_: any) => {
                        return containerElem
                    }}
                />
            ) : null}

            <StyledDiv
                className={'popup-container'}
                ref={(ref) => {
                    setContainerElem(ref as any)
                }}
            ></StyledDiv>
        </div>
    )
}
