import React from 'react'
import { Col, Row } from 'antd'
import { Text } from './Text'
import config from '../../../package.json'
import { IconButton } from './Button'

export interface XSearchContext {
    config: XSearchConfig
    onSearchChange: (keys: string[], value: any) => void
}

export class SearchManager {
    private static pluginMap = new Map<string, (ctx: XSearchContext, search: any) => React.ReactNode>()

    public static addPlugin(kind: string, plugin: (ctx: XSearchContext, search: any) => React.ReactNode) {
        if (SearchManager.pluginMap.has(kind)) {
            throw new Error(`plugin ${kind} has already defined`)
        }

        SearchManager.pluginMap.set(kind, plugin)
    }

    public static getPlugin(kind: string): ((ctx: XSearchContext, search: any) => React.ReactNode) | undefined {
        return SearchManager.pluginMap.get(kind)
    }
}

export interface XSearchConfig {
    key: string
    size: number
    kind: string
    label?: {
        title: string
        width: number
    }
    getParams?: (search: { string: any }) => any
    clearWhenChange?: Array<XSearchConfig | string>
}

export const XSearchItem: React.FC<{
    config: XSearchConfig
    search: { string: any }
    onSearchChange: (keys: string[], value: any) => void
}> = ({ config, search, onSearchChange }) => {
    const ctx = {
        config: config,
        onSearchChange: onSearchChange,
    }
    const plugin = SearchManager.getPlugin(config.kind)

    return plugin ? (
        <Col span={config.size}>
            <Row align={'middle'} style={{ flexWrap: 'nowrap', height: 44 }}>
                {config.label ? (
                    <Text style={{ minWidth: config.label.width, marginRight: 8 }} dir={'rtl'}>
                        {config.label.title}
                    </Text>
                ) : null}

                <Row style={{ flexGrow: 1 }}>{plugin(ctx, search)}</Row>
            </Row>
        </Col>
    ) : (
        <Col span={config.size}>
            <Row style={{ flexWrap: 'nowrap', height: 44 }}>`error search kind ${config.kind}`</Row>
        </Col>
    )
}

function getFirstLineItem(searchConfigs: Array<XSearchConfig>): number {
    let firstLineItems = 0
    let firstLineSize = 0
    for (let i = 0; i < searchConfigs.length; i++) {
        if (searchConfigs[i].size <= 24 - firstLineSize) {
            firstLineItems++
            firstLineSize += searchConfigs[i].size
        } else {
            break
        }
    }
    return firstLineItems
}

function getNumberOfLines(searchConfigs: Array<XSearchConfig>): number {
    if (searchConfigs.length <= 0) {
        return 0
    }

    const first = getFirstLineItem(searchConfigs)

    if (first === 0) {
        return 0
    } else {
        return 1 + getNumberOfLines(searchConfigs.slice(first))
    }
}

export const XSearchPanel: React.FC<{
    search: any
    onSearchChange: (search: any) => void
    searchConfigs: Array<XSearchConfig>
    style?: React.CSSProperties
}> = ({ search, onSearchChange, searchConfigs, style }) => {
    const [expand, setExpand] = React.useState(false)

    let lines = getNumberOfLines(searchConfigs)
    const canExpand = lines > 1

    const removeKeys = (obj: any, removeKeys: Array<string>) => {
        const keys = Object.keys(obj)
        let ret: any = {}

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            if (removeKeys.indexOf(key) < 0) {
                ret[key] = obj[key]
            }
        }

        return ret
    }

    return (
        <div
            style={{
                backgroundColor: config.app.bgColorComponent,
                borderRadius: 2,

                paddingTop: 10,
                paddingBottom: 10,

                ...style,
            }}
        >
            <div
                style={{
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    display: 'flex',
                    flexDirection: 'row',
                    height: expand ? 44 * lines : 44,
                    transition: config.app.transitionHeight,
                    overflow: 'hidden',
                }}
            >
                <Row gutter={[0, 0]} style={{ minWidth: 960, flex: 1 }}>
                    {searchConfigs.map((config) => {
                        const clearKeys = config.clearWhenChange?.map((v) => {
                            if (typeof v === 'string') {
                                return v
                            } else {
                                return v.key
                            }
                        })

                        return (
                            <XSearchItem
                                key={config.key}
                                config={config}
                                search={search}
                                onSearchChange={(keys, value) => {
                                    let items: any = {}
                                    keys.forEach((v) => {
                                        items[v] = value
                                    })
                                    onSearchChange(removeKeys(Object.assign({}, search, items), clearKeys || []))
                                }}
                            />
                        )
                    })}
                </Row>

                {canExpand ? (
                    <div style={{ marginTop: 6, marginLeft: 8, marginRight: 8 }}>
                        <IconButton
                            type={'icon'}
                            icon={expand ? 'icon-zhankai' : 'icon-shouqi'}
                            onClick={() => {
                                setExpand(!expand)
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}
