type CallBackType = (message: string, value: any) => any

let getSeed: () => number = ((): any => {
    let seed: number = 10000
    return (): number => {
        seed++
        return seed
    }
})()

export class Subscription {
    ec: EventChannel
    seed: number

    constructor(ec: EventChannel, seed: number) {
        this.ec = ec
        this.seed = seed
    }

    public unsubscribe() {
        this.ec.unsubscribe(this.seed)
    }
}

export class EventChannel {
    private listeners: Map<number, CallBackType> = new Map()

    public subscribe(callback: CallBackType): Subscription {
        let seed: number = getSeed()
        this.listeners.set(seed, callback)
        return new Subscription(this, seed)
    }

    unsubscribe(seed: number) {
        if (this.listeners.has(seed)) {
            this.listeners.delete(seed)
        }
    }

    public send(message: string, value: any): number {
        let ret = 0

        this.listeners.forEach((fn) => {
            fn(message, value)
            ret++
        })

        return ret
    }
}

export class EventHub {
    private channelMap: Map<string, EventChannel> = new Map()

    public createChannel(name: string): boolean {
        if (!name) {
            return false
        } else if (this.channelMap.has(name)) {
            return false
        } else {
            this.channelMap.set(name, new EventChannel())
            return true
        }
    }

    public removeChannel(name: string): boolean {
        return this.channelMap.delete(name)
    }

    public send(channel: string, message: string, value: any): number | undefined {
        return this.channelMap.get(channel)?.send(message, value)
    }

    public subscribe(channel: string, callback: CallBackType): Subscription | undefined {
        return this.channelMap.get(channel)?.subscribe(callback)
    }
}
