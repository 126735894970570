import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Col, Input, Row, Space } from 'antd'
import React from 'react'
import styled from 'styled-components'
import config from '../../../../package.json'
import { IconButton, IconSecondaryButton, PrimaryButton, SecondaryButton } from '../../../components/common/Button'
import { VFlexContainer } from '../../../components/common/Container'
import { XItemConfig, XItemContext } from '../../../components/common/Item'
import { GetSelectFilter, XSelect } from '../../../components/common/Select'
import { HAutoSpacer, HSpacer, VSpacer } from '../../../components/common/Space'
import { StyledPagination, XTable, useDataSource } from '../../../components/common/Table'
import { Text, TextWithEllipsis } from '../../../components/common/Text'
import { PopupWindow } from '../../../components/common/Window'
import { InputLabelContainer } from '../../../components/input/container'
import { StyledInput } from '../../../components/input/string'
import { App, AppTableCH } from '../../../utils/app'
import { MakeWhere } from '../../../utils/database'
import { useValidateMap } from '../../../utils/hook'
import { validateNotEmpty, validatePhone, validateUsername } from '../../../utils/validate'

const StyledMenuContainer = styled(Row)`
    transition: ${config.app.transitionAll};
    color: ${config.app.fontColorNormal};

    :hover {
        background: rgba(26, 152, 185, 0.2);
    }

    &.mdp-menu-button-selected {
        background: rgba(26, 152, 185, 1) !important;
        color: rgba(255, 255, 255, 1) !important;
    }
`

const StyledMenuIcon = styled.i`
    // transition: all 200ms ease-in;
    font-size: 16px;
    background-image: -webkit-linear-gradient(-90deg, #1592c0 0%, #39b399 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.mdp-menu-icon-selected {
        font-size: 16px;
        background-image: -webkit-linear-gradient(-90deg, #ffffff 0%, #ffffff 100%) !important;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

const MenuButton: React.FC<{
    icon: string
    text: string
    selected: boolean
    onClick: () => void
}> = ({ icon, text, selected, onClick }) => {
    const kind = selected ? 'mdp-menu-icon-selected mdp-menu-icon' : 'mdp-menu-icon'

    return (
        <StyledMenuContainer
            align="middle"
            className={selected ? 'mdp-menu-button mdp-menu-button-selected' : 'mdp-menu-button'}
            onClick={onClick}
            style={{
                height: 40,
                paddingLeft: 16,
            }}
        >
            <StyledMenuIcon className={`iconfont ${icon} ${kind}`} style={{ width: 18 }}></StyledMenuIcon>
            <TextWithEllipsis width={180} style={{ marginLeft: 6 }}>
                {text}
            </TextWithEllipsis>
        </StyledMenuContainer>
    )
}

export const NewCompanyPopupButton: React.FC<{
    button: React.ReactNode
    onChange?: () => void
}> = ({ button, onChange }) => {
    const data = useValidateMap(new Map([['name', validateNotEmpty]]), new Map([['name', '']]))

    const clear = () => {
        data.setValue('@all', '@init')
    }

    const getContent = (confirmLoading: boolean) => {
        return (
            <>
                <Row justify={'center'} style={{ height: 13 }} />

                <Row justify={'center'} style={{ marginTop: 6, marginLeft: 16, marginRight: 16 }}>
                    <InputLabelContainer
                        width={480}
                        label={'公司名称'}
                        labelWidth={115}
                        labelHeight={32}
                        element={
                            <StyledInput
                                style={{ width: 280 }}
                                placeholder={'请输入'}
                                value={data.valueMap.get('name')}
                                disabled={confirmLoading}
                                allowClear={true}
                                onChange={(v) => {
                                    data.setValue('name', v.target.value)
                                }}
                            />
                        }
                        error={data.errorMap.get('name')}
                    />
                </Row>

                <VSpacer height={16} />
            </>
        )
    }

    return (
        <PopupWindow
            title={'创建公司'}
            size={'middle'}
            checked={data.checked}
            button={button}
            getContent={getContent}
            onSubmit={async () => {
                await App.Api.createCompany(data.valueMap.get('name'), '创建公司成功', '创建公司失败')
                onChange && onChange()
                return true
            }}
            onOpenChange={(v) => {
                if (!v) {
                    clear()
                }
            }}
        />
    )
}

export const ProjectRoleSelect: React.FC<{
    embedded?: boolean
    style?: React.CSSProperties
    placeholder?: string
    initTemplate?: any
    value?: string
    disabled?: boolean
    bordered?: boolean
    onChange: (v: any | undefined) => void
}> = ({ embedded, style, placeholder, initTemplate, value, disabled, bordered, onChange }) => {
    const onLoad = React.useCallback(async () => {
        return [
            { id: 'company', name: '公司管理' },
            { id: 'project', name: '项目管理' },
            { id: 'recorder', name: '现场记录' },
            { id: 'director', name: '导演' },
        ]
    }, [])

    // console.log(value)

    // const selects = JSON.parse(value || '[]')

    return (
        <XSelect
            embedded={embedded}
            mode={'multiple'}
            style={style}
            onLoad={onLoad}
            value={value}
            placeholder={placeholder}
            initData={[initTemplate]}
            disabled={disabled}
            bordered={bordered}
            itemRender={(v) => {
                return v.name
            }}
            onChange={onChange}
            onFilter={GetSelectFilter('name', 'name')}
        />
    )
}

const NewProjectPopupButton: React.FC<{
    companyId: string
    button: React.ReactNode
    onChange?: () => void
}> = ({ companyId, button, onChange }) => {
    const data = useValidateMap(
        new Map([
            ['name', validateNotEmpty],
            ['label', validateNotEmpty],
        ]),
        new Map([
            ['name', ''],
            ['label', ''],
        ])
    )

    const clear = () => {
        data.setValue('@all', '@init')
    }

    const getContent = (confirmLoading: boolean) => {
        return (
            <>
                <Row justify={'center'} style={{ height: 13 }} />

                <Row justify={'center'} style={{ marginTop: 6, marginLeft: 16, marginRight: 16 }}>
                    <InputLabelContainer
                        width={480}
                        label={'项目名称'}
                        labelWidth={115}
                        labelHeight={32}
                        element={
                            <StyledInput
                                style={{ width: 280 }}
                                placeholder={'请输入'}
                                value={data.valueMap.get('label')}
                                disabled={confirmLoading}
                                allowClear={true}
                                onChange={(v) => {
                                    data.setValue('label', v.target.value)
                                }}
                            />
                        }
                        error={data.errorMap.get('label')}
                    />
                </Row>

                <Row justify={'center'} style={{ marginTop: 6, marginLeft: 16, marginRight: 16 }}>
                    <InputLabelContainer
                        width={480}
                        label={'项目简称'}
                        labelWidth={115}
                        labelHeight={32}
                        element={
                            <StyledInput
                                style={{ width: 280 }}
                                placeholder={'请输入'}
                                value={data.valueMap.get('name')}
                                disabled={confirmLoading}
                                allowClear={true}
                                onChange={(v) => {
                                    data.setValue('name', v.target.value)
                                }}
                            />
                        }
                        error={data.errorMap.get('name')}
                    />
                </Row>

                <VSpacer height={16} />
            </>
        )
    }

    return (
        <PopupWindow
            title={'创建项目'}
            size={'middle'}
            checked={data.checked}
            button={button}
            getContent={getContent}
            onSubmit={async () => {
                await App.Api.createCompanyProject(
                    companyId,
                    data.valueMap.get('name'),
                    data.valueMap.get('label'),
                    '创建项目成功',
                    '创建项目失败'
                )
                onChange && onChange()
                return true
            }}
            onOpenChange={(v) => {
                if (!v) {
                    clear()
                }
            }}
        />
    )
}

const NewProjectRolePopupButton: React.FC<{
    projectId: string
    button: React.ReactNode
    onChange?: () => void
}> = ({ projectId, button, onChange }) => {
    const data = useValidateMap(
        new Map([
            ['role', validateNotEmpty],
            ['username', validateUsername],
            ['realname', validateUsername],
            ['phone', validatePhone],
        ]),
        new Map([
            ['role', ''],
            ['username', ''],
            ['realname', ''],
            ['phone', ''],
        ])
    )

    const clear = () => {
        data.setValue('@all', '@init')
    }

    const getContent = (confirmLoading: boolean) => {
        return (
            <>
                <Row justify={'center'} style={{ height: 13 }} />

                <Row justify={'center'} style={{ marginTop: 6, marginLeft: 16, marginRight: 16 }}>
                    <InputLabelContainer
                        width={480}
                        label={'权限'}
                        labelWidth={115}
                        labelHeight={32}
                        element={
                            <ProjectRoleSelect
                                style={{ width: 280 }}
                                placeholder={'请选择'}
                                value={data.valueMap.get('role') ? JSON.parse(data.valueMap.get('role')) : []}
                                onChange={(v) => {
                                    data.setValue('role', JSON.stringify(v))
                                }}
                            />
                        }
                        error={data.errorMap.get('role')}
                    />
                </Row>

                <Row justify={'center'} style={{ marginTop: 6, marginLeft: 16, marginRight: 16 }}>
                    <InputLabelContainer
                        width={480}
                        label={'用户名'}
                        labelWidth={115}
                        labelHeight={32}
                        element={
                            <StyledInput
                                style={{ width: 280 }}
                                placeholder={'请输入'}
                                value={data.valueMap.get('username')}
                                disabled={confirmLoading}
                                allowClear={true}
                                onChange={(v) => {
                                    data.setValue('username', v.target.value)
                                }}
                            />
                        }
                        error={data.errorMap.get('username')}
                    />
                </Row>

                <Row justify={'center'} style={{ marginTop: 6, marginLeft: 16, marginRight: 16 }}>
                    <InputLabelContainer
                        width={480}
                        label={'姓名'}
                        labelWidth={115}
                        labelHeight={32}
                        element={
                            <StyledInput
                                style={{ width: 280 }}
                                placeholder={'请输入'}
                                value={data.valueMap.get('realname')}
                                disabled={confirmLoading}
                                allowClear={true}
                                onChange={(v) => {
                                    data.setValue('realname', v.target.value)
                                }}
                            />
                        }
                        error={data.errorMap.get('realname')}
                    />
                </Row>

                <Row justify={'center'} style={{ marginTop: 6, marginLeft: 16, marginRight: 16 }}>
                    <InputLabelContainer
                        width={480}
                        label={'手机号'}
                        labelWidth={115}
                        labelHeight={32}
                        element={
                            <StyledInput
                                style={{ width: 280 }}
                                placeholder={'请输入'}
                                value={data.valueMap.get('phone')}
                                disabled={confirmLoading}
                                allowClear={true}
                                onChange={(v) => {
                                    data.setValue('phone', v.target.value)
                                }}
                            />
                        }
                        error={data.errorMap.get('phone')}
                    />
                </Row>

                <VSpacer height={16} />
            </>
        )
    }

    return (
        <PopupWindow
            title={'创建用户'}
            size={'middle'}
            checked={data.checked}
            button={button}
            getContent={getContent}
            onSubmit={async () => {
                await App.Api.createCompanyProjectUser(
                    projectId,
                    data.valueMap.get('role'),
                    data.valueMap.get('username'),
                    data.valueMap.get('realname'),
                    data.valueMap.get('phone'),
                    '创建用户成功',
                    '创建用户失败'
                )
                onChange && onChange()
                return true
            }}
            onOpenChange={(v) => {
                if (!v) {
                    clear()
                }
            }}
        />
    )
}

const CompanySelect: React.FC<{ companies: any[]; currentId?: string; onChange?: (companyId: string) => void }> = ({
    companies,
    currentId,
    onChange,
}) => {
    const [search, setSearch] = React.useState<string>('')

    const datas = search ? companies.filter((v) => v.name.includes(search)) : companies

    return (
        <Col
            style={{ overflowY: 'auto', overflowX: 'hidden', height: '100%', background: config.app.bgColorComponent }}
        >
            <Col style={{ width: 240 }}>
                <Row style={{ height: 40 }}>
                    <Input
                        addonBefore={<SearchOutlined />}
                        placeholder="请输入公司名称"
                        onChange={(v) => {
                            setSearch(v.target.value)
                        }}
                    />
                </Row>

                {datas.map((v) => {
                    return (
                        <MenuButton
                            key={v.id}
                            icon={'icon-renwu'}
                            text={v.name}
                            selected={currentId === v.id}
                            onClick={() => {
                                if (currentId !== v.id && onChange) {
                                    onChange(v.id)
                                }
                            }}
                        />
                    )
                })}

                <VSpacer height={1} width={240} top={8} bottom={16} color={config.app.dividerColor} />

                <Row justify={'center'} style={{ width: '100%' }}>
                    <NewCompanyPopupButton
                        button={<SecondaryButton icon={<PlusOutlined />}>创建公司</SecondaryButton>}
                        onChange={() => {
                            onChange && onChange(currentId || '')
                        }}
                    />
                </Row>

                <VSpacer height={16} />
            </Col>
        </Col>
    )
}

const ProjectItem: React.FC<{
    project: any
}> = ({ project }) => {
    const columnConfigs: Array<XItemConfig> = [
        {
            kind: 'string',
            key: '_index',
            service: 'company_project_user',
            column: {
                title: '序号',
                width: 60,
            },
        },
        {
            kind: 'string',
            key: 'device',
            service: 'company_project_user',
            column: {
                title: '设备序列号',
                width: 120,
            },
            getParams: (ctx: XItemContext) => {
                return {
                    changeValue: ctx.data?.device.substring(0, 8) + '...',
                }
            },
        },
        {
            kind: 'string',
            key: 'username',
            service: 'company_project_user',
            column: {
                title: '用户名',
                width: 100,
            },
            editor: {
                title: '修改用户名',
                width: 252,
                canEdit: () => {
                    return true
                },
                canMultiEdit: false,
            },
        },
        {
            kind: 'string',
            key: 'realname',
            service: 'company_project_user',
            column: {
                title: '姓名',
                width: 100,
            },
            editor: {
                title: '修改姓名',
                width: 252,
                canEdit: () => {
                    return true
                },
                canMultiEdit: false,
            },
        },
        {
            kind: 'string',
            key: 'phone',
            service: 'company_project_user',
            column: {
                title: '手机号',
                width: 140,
            },
            editor: {
                title: '修改手机号',
                width: 252,
                validate: (ctx: XItemContext, v) => {
                    return validatePhone(v)
                },
                canEdit: () => {
                    return true
                },
                canMultiEdit: false,
            },
        },
        {
            kind: 'role',
            key: 'role',
            service: 'company_project_user',
            column: {
                title: '权限',
                width: 240,
            },
            editor: {
                title: '修改权限',
                width: 252,
                canEdit: () => {
                    return true
                },
                validate: (ctx: XItemContext, v) => {
                    if (v && v !== '[]') {
                        console.log('AASDFASDF-a', typeof v)
                        return null
                    }
                    return new Error('不能为空')
                },
                canMultiEdit: false,
            },
        },
        {
            kind: 'company-project-link',
            key: 'link',
            service: 'company_project_user',
            column: {
                title: '操作',
                width: 120,
            },
        },
    ]

    return (
        <Col style={{ background: config.app.bgColorComponent }}>
            <Row align={'middle'} style={{ height: 50 }}>
                <HSpacer width={16} />
                <Row style={{ width: 4, height: 14, borderRadius: 1, backgroundColor: '#2EA9A5' }}></Row>
                <HSpacer width={8} />
                <Text size="Big" color="Strong">
                    {project?.label}
                </Text>
                <HAutoSpacer />

                <NewProjectRolePopupButton
                    projectId={project.id}
                    button={<IconButton type={'text'} icon={'icon-xinjian1'} />}
                    onChange={() => {
                        App.EventHub.send(AppTableCH, 'FlushIndex', 'table.dynamic.' + project.id)
                    }}
                ></NewProjectRolePopupButton>

                <HSpacer width={8} />
            </Row>

            <Row style={{ height: 340 }}>
                <XTable
                    service={'company_project_user'}
                    uuid={'table.dynamic.' + project.id}
                    pageSize={99999999}
                    hideBar={true}
                    columnConfigs={columnConfigs}
                    initSearch={{
                        ...MakeWhere('project', '=', true, project.id),
                        ...MakeWhere('c_active', '=', true, true),
                    }}
                    initOrderBy={[{ key: 'create_time', order: 'ascend' }]}
                    tableBG="transparent"
                />
            </Row>
        </Col>
    )
}

const ProjectList: React.FC<{
    uuid: string
    companyId: string
    initSearch?: any
    initOrderBy?: Array<{ key: string; order: 'ascend' | 'descend' | undefined }>
    getIndexes?: (search?: any, orderBy?: Array<string>) => Promise<Array<{ id: string }>>
}> = ({ uuid, companyId, initSearch, initOrderBy, getIndexes }) => {
    const dataMgr = useDataSource('company_project', 20, initSearch, initOrderBy, getIndexes)
    const content = (
        <>
            {dataMgr.dataSource.map((v: any) => {
                return <ProjectItem key={v.id} project={v} />
            })}
        </>
    )

    React.useEffect(() => {
        let running = true

        const listener = App.EventHub.subscribe(AppTableCH, (msg, value) => {
            switch (msg) {
                case 'FlushIndex':
                    if (running && value === uuid) {
                        dataMgr.flushIndex()
                    }
                    break
                case 'FlushPage':
                    if (running && value === uuid) {
                        dataMgr.flushPage()
                    }
                    break
            }
        })

        return () => {
            listener?.unsubscribe()
            running = false
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid])

    return (
        <VFlexContainer>
            <Row align={'middle'} style={{ height: 60 }}>
                <NewProjectPopupButton
                    companyId={companyId}
                    button={<PrimaryButton icon={<PlusOutlined />}>新增项目</PrimaryButton>}
                    onChange={() => {
                        dataMgr.flushIndex()
                    }}
                />

                <HAutoSpacer />
                <StyledPagination
                    size={'small'}
                    onChange={(page) => {
                        dataMgr.setPageIndex(page)
                    }}
                    total={dataMgr.indexes.length}
                    showTotal={(total, range) => (
                        <span
                            style={{
                                color: config.app.fontColorWeak,
                                userSelect: 'none',
                            }}
                        >{`第${range[0]}-${range[1]}项  共${total}项`}</span>
                    )}
                    current={dataMgr.pageIndex}
                    pageSize={20}
                    showSizeChanger={false}
                />
                <Space size={'small'} style={{ marginLeft: 8 }}>
                    <IconSecondaryButton
                        size={'small'}
                        kind={'table-reload'}
                        disabled={dataMgr.loading}
                        onClick={() => {
                            dataMgr.flushIndex()
                        }}
                    />
                </Space>
            </Row>

            <VFlexContainer style={{ flex: 1, overflowY: 'hidden', borderRadius: 2 }}>
                <Col style={{ height: '100%', width: '100%', overflowY: 'auto' }}>{content}</Col>
            </VFlexContainer>
        </VFlexContainer>
    )
}

export const CompanyModule = () => {
    const [flushSeed, setFlushSeed] = React.useState<number>(0)
    const [companies, setCompanies] = React.useState<any[]>([])
    const [currentId, setCurrentId] = React.useState<string>('')

    React.useEffect(() => {
        App.Api.list('company', ['id', 'name'], {
            ...MakeWhere('admins', '%', true, App.User.id),
            ...MakeWhere('c_active', '=', true, true),
        })
            .then((v) => {
                setCompanies(v)
                if (!currentId) {
                    setCurrentId(v[0]?.id || '')
                }
            })
            .catch((e) => {
                console.error(e)
                setCompanies([])
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [App.User.id, flushSeed])

    const projectTable = (
        <ProjectList
            uuid={'table.hub.company-project'}
            companyId={currentId}
            initSearch={{
                ...MakeWhere('c_active', '=', true, true),
                ...MakeWhere('company_id', '=', true, currentId),
            }}
            initOrderBy={[{ key: 'name', order: 'ascend' }]}
        />
    )

    return (
        <VFlexContainer
            style={{
                marginLeft: 16,
                marginRight: 16,
                marginBottom: 16,
                borderRadius: 2,
                overflow: 'hidden',
                boxSizing: 'border-box',
            }}
        >
            <Row style={{ width: '100%', height: '100%' }}>
                <CompanySelect
                    companies={companies}
                    currentId={currentId}
                    onChange={(id) => {
                        setFlushSeed(flushSeed + 1)
                        if (id !== currentId) {
                            setCurrentId(id)
                        }
                    }}
                />

                <VSpacer width={16} />

                <VFlexContainer style={{ flex: 1 }}>{projectTable}</VFlexContainer>
            </Row>
        </VFlexContainer>
    )
}
