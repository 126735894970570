export function MakeQueryKey(columnName: string, op: string, and: boolean): string {
    if (and) {
        return op + ':&&:' + columnName
    } else {
        return op + ':||:' + columnName
    }
}

export function MakeWhere(columnName: string, op: string, and: boolean, value: any) {
    let where: any = {}
    where[MakeQueryKey(columnName, op, and)] = value
    return where
}
