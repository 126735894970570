
import React from 'react'
import { Badge, Dropdown, Row, Spin } from 'antd'
import styled from 'styled-components'
import packageConfig from '../../../package.json'
import { HAutoSpacer, VSpacer } from './Space'
import { Text } from './Text'
import { IconSecondaryButton } from './Button'
import { useValidateMap } from '../../utils/hook'
import { App, AppPopupChannel } from '../../utils/app'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { showError, showSuccess } from '../../utils/message'
import { getUUID } from '../../utils/uuid'
import { ItemManager } from '../plugins'

export interface XSelection {
    isSelected: (columnKey: string, recordID: string) => boolean
    getSelectSum: (columnKey: string) => number
    getSelectedIds: (columnKey: string) => Array<string>
    addRecords: (columnKey: string, recordIDs: string[]) => void
    toggle: (columnKey: string, recordID: string) => void
    resetColumn: (columnKey: string) => void
    clearAll: () => void
}

function deepCloneSelectionMap(v: Map<string, Map<string, boolean>>): Map<string, Map<string, boolean>> {
    const ret = new Map<string, Map<string, boolean>>()
    v.forEach((recordMap, key) => {
        const newRecordMap = new Map<string, boolean>()
        recordMap.forEach((value, recordID) => {
            newRecordMap.set(recordID, value)
        })
        ret.set(key, newRecordMap)
    })
    return ret
}

export function useSelection(): XSelection {
    const [keyMap, setKeyMap] = React.useState(new Map<string, Map<string, boolean>>())

    return {
        isSelected: (columnKey: string, recordID: string) => {
            return keyMap.get(columnKey)?.get(recordID) === true
        },
        getSelectSum: (columnKey: string) => {
            let ret = 0
            keyMap.get(columnKey)?.forEach((v) => {
                if (v) {
                    ret++
                }
            })
            return ret
        },
        getSelectedIds: (columnKey: string) => {
            let ret = new Array<string>()
            keyMap.get(columnKey)?.forEach((v, k) => {
                if (v) {
                    ret.push(k)
                }
            })
            return ret
        },
        addRecords: (columnKey: string, recordIDs: string[]) => {
            setKeyMap((v) => {
                const newMap = deepCloneSelectionMap(v)
                let column = newMap.get(columnKey)
                if (!column) {
                    column = new Map<string, boolean>()
                }
                recordIDs.forEach((id) => {
                    column && column.set(id, true)
                })
                newMap.set(columnKey, column)
                return newMap
            })
        },
        toggle: (columnKey: string, recordID: string) => {
            setKeyMap((v) => {
                const newMap = deepCloneSelectionMap(v)

                if (newMap.get(columnKey)?.get(recordID) === true) {
                    newMap.get(columnKey)?.set(recordID, false)
                } else {
                    let column = newMap.get(columnKey)
                    if (!column) {
                        column = new Map<string, boolean>()
                    }
                    newMap.set(columnKey, column.set(recordID, true))
                }

                return newMap
            })
        },
        resetColumn: (columnKey: string) => {
            setKeyMap((v) => {
                const newMap = deepCloneSelectionMap(v)
                newMap.set(columnKey, new Map<string, boolean>())
                return newMap
            })
        },
        clearAll: () => {
            setKeyMap(new Map())
        },
    }
}

export interface XItemContext {
    config: XItemConfig
    idArray?: Array<string>
    data?: any
    selection?: XSelection
    search?: any
    onChange: (adFlag: boolean) => void
}

export interface XRender {
    renderItem: (ctx: XItemContext, open: boolean) => React.ReactNode
    renderEditor?: (
        ctx: XItemContext,
        value: any,
        setValue: (v: any) => void,
        error: Error | null,
        disabled: boolean,
        open: boolean,
        setOpen: (v: boolean) => void
    ) => React.ReactNode
}

export interface XItemConfig {
    kind: string
    service: string
    key: string
    getParams?: (ctx: XItemContext) => any
    placeholder?: string

    column?: {
        title?: string
        width: number
        canSort?: boolean
    }

    editor?: {
        title?: string
        width?: number
        onSubmit?: (ctx: XItemContext, idArray: Array<string>, action: string, data: any) => Promise<void>
        addOrDelWhenSubmit?: boolean
        validate?: (ctx: XItemContext, v: any) => Error | null
        canEdit?: ((ctx: XItemContext) => boolean) | boolean
        canMultiEdit?: ((ctx: XItemContext) => boolean) | boolean
        customEditor?: boolean
    }
}

export function extendsConfig(config: XItemConfig, v: any): XItemConfig {
    let ret: XItemConfig = {
        kind: v.kind || config.kind,
        service: v.service || config.service,
        key: v.key || config.key,
        getParams: v.getParams || config.getParams,
        placeholder: v.placeholder || config.placeholder,
    }

    if (config.column || v.column) {
        ret.column = Object.assign({}, config.column, v.column)
    }

    if (config.editor || v.editor) {
        ret.editor = Object.assign({}, config.editor, v.editor)
    }

    return ret
}

export function getEditorAuthConfig(config: XItemConfig, ctx: XItemContext): [boolean, boolean] {
    let canEdit: boolean
    let canMultiEdit: boolean

    switch (typeof config.editor?.canEdit) {
        case 'boolean':
            canEdit = config.editor?.canEdit
            break
        case 'function':
            canEdit = config.editor?.canEdit(ctx)
            break
        default:
            canEdit = false
            break
    }

    switch (typeof config.editor?.canMultiEdit) {
        case 'boolean':
            canMultiEdit = config.editor?.canMultiEdit
            break
        case 'function':
            canMultiEdit = config.editor?.canMultiEdit(ctx)
            break
        default:
            canMultiEdit = false
            break
    }

    return [canEdit, canMultiEdit]
}

const DropdownContainer = styled.div`
    & .ant-dropdown {
        min-width: 0 !important;
        z-index: 1 !important;
    }
`

async function updateAttr(ctx: XItemContext, idArray: Array<string>, action: string, data: any) {
    return await App.Api.update(ctx.config.service, idArray, ctx.config.key, data)
}

const XItemInnerPopup: React.FC<{
    ctx: XItemContext
    plugin?: XRender
    popupWidth: number
    setOpen: (v: boolean) => void
    open: boolean
}> = ({ ctx, plugin, popupWidth, setOpen, open }) => {
    let columnKey = ctx.config.key
    let initData = new Map<string, any>()
    let validateMap = new Map<string, (v: any) => Error | null>()
    if (columnKey) {
        validateMap.set(columnKey, (v) => {
            return ctx.config.editor?.validate ? ctx.config.editor?.validate(ctx, v) : null
        })
    }
    if (ctx.data && columnKey) {
        initData.set(columnKey, ctx.data[columnKey])
    }
    const editData = useValidateMap(validateMap, initData)
    const [confirmLoading, setConfirmLoading] = React.useState(false)

    const setValue = React.useCallback(
        (v: any) => {
            if (columnKey) {
                editData.setValue(columnKey, v)
            }
        },
        [columnKey, editData]
    )

    const hasChanged = React.useCallback(() => {
        if (columnKey && ctx.data) {
            return ctx.data[columnKey] !== editData.valueMap.get(columnKey)
        } else {
            return true
        }
    }, [ctx, columnKey, editData])

    const content =
        plugin && plugin.renderEditor && columnKey ? (
            plugin.renderEditor(
                ctx,
                editData.valueMap.get(columnKey),
                setValue,
                editData.errorMap.get(columnKey) || null,
                confirmLoading,
                open,
                setOpen
            )
        ) : (
            <span>{ctx.config.kind} renderPopup error</span>
        )

    return ctx.config.editor?.customEditor ? (
        <div
            style={{
                width: popupWidth,
                backgroundColor: packageConfig.app.bgColorPopup,
                borderRadius: 2,
                boxShadow: packageConfig.app.boxShadowPopup,
            }}
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            {content}
        </div>
    ) : (
        <div
            style={{
                width: popupWidth,
                padding: '12px 16px 16px 16px',
                marginBottom: 16,
                backgroundColor: packageConfig.app.bgColorPopup,
                borderRadius: 2,
                boxShadow: packageConfig.app.boxShadowPopup,
            }}
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <Row align={'middle'} style={{ height: 24 }}>
                <Text size={'Normal'} color={'Strong'} style={{ height: 20, lineHeight: '20px' }}>
                    {ctx.config.editor?.title}
                </Text>
                <HAutoSpacer />
                {confirmLoading ? (
                    <Row align={'middle'} justify={'center'} style={{ width: 24, height: 24 }}>
                        <Spin
                            indicator={
                                <Loading3QuartersOutlined
                                    style={{
                                        fontSize: 14,
                                        color: packageConfig.app.fontColorWeaker,
                                    }}
                                    spin
                                />
                            }
                        />
                    </Row>
                ) : (
                    <IconSecondaryButton
                        disabled={!editData.checked || !hasChanged()}
                        size={'small'}
                        kind={'check'}
                        onClick={() => {
                            if (!confirmLoading) {
                                const idArray = ctx.idArray || [ctx.data?.id]
                                const fnSubmit = ctx.config.editor?.onSubmit || updateAttr

                                setConfirmLoading(true)
                                fnSubmit(ctx, idArray, 'submit', editData.valueMap.get(columnKey))
                                    .then(() => {
                                        setOpen(false)
                                        ctx.onChange && ctx.onChange(ctx.config.editor?.addOrDelWhenSubmit || false)
                                        showSuccess('更新数据成功').finally()
                                    })
                                    .catch((e) => {
                                        showError('更新数据失败，请刷新页面后重试').finally()
                                        throw e
                                    })
                                    .finally(() => {
                                        setConfirmLoading(false)
                                    })
                            }
                        }}
                    />
                )}
            </Row>

            <VSpacer top={12} />

            {content}
        </div>
    )
}

const XItemInner: React.FC<{
    plugin?: XRender
    style?: React.CSSProperties
    width?: number
    bordered?: boolean
    selection?: XSelection
    placement?: 'bottomLeft' | 'bottomCenter' | 'bottomRight'
    editAreaBG?: string
    config: XItemConfig
    authMode?: 'pass' | 'none' | 'config'
    idArray?: Array<string>
    data?: any
    search?: any
    onChange: (adFlag: boolean) => void
}> = ({
    plugin,
    style,
    width,
    bordered,
    editAreaBG,
    selection,
    placement,
    config,
    authMode,
    idArray,
    data,
    search,
    onChange,
}) => {
    const [uuid] = React.useState(getUUID())
    const [open, _setOpen] = React.useState(false)

    React.useEffect(() => {
        let running = true

        const listener = App.EventHub.subscribe(AppPopupChannel, (msg, value) => {
            switch (msg) {
                case 'PopupOpen':
                    if (running && value !== uuid) {
                        _setOpen(false)
                    }
            }
        })

        return () => {
            listener?.unsubscribe()
            running = false
        }
    }, [uuid])

    const setOpen = (v: boolean) => {
        if (v) {
            App.EventHub.send(AppPopupChannel, 'PopupOpen', uuid)
        }

        _setOpen(v)
    }

    const ctx = {
        config: config,
        data: data,
        idArray: idArray,
        search: search,
        onChange: onChange,
        selection: selection,
    }

    const itemWidth = width || (config.column?.width ? config.column?.width - 16 : 200)
    const popupWidth = config.editor?.width || itemWidth

    const [cfgCanEdit, cfgCanMultiEdit] = getEditorAuthConfig(config, ctx)

    const canEdit = authMode === 'pass' ? true : authMode === 'none' ? false : cfgCanEdit
    const canMultiEdit = canEdit && cfgCanMultiEdit
    const multiSelected = selection?.isSelected(config.key, (data as any).id) === true
    const editorWidth = config.editor?.width || 0
    const itemShow = plugin?.renderItem(ctx, open)

    const itemNode = (
        <Row
            align={'middle'}
            style={{
                width: itemWidth,
                minHeight: 32,
                flexWrap: 'nowrap',
                paddingLeft: canEdit ? 8 : 0,
                paddingRight: canEdit ? 8 : 0,
                borderRadius: canEdit ? 2 : 0,
                backgroundColor: canEdit ? editAreaBG || packageConfig.app.editItemBG : 'transparent',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: multiSelected
                    ? packageConfig.app.editItemBorderPrimary
                    : bordered && canEdit
                    ? packageConfig.app.editItemBorderSecondary
                    : 'transparent',
                ...style,
            }}
            onClick={(e) => {
                if (canEdit) {
                    e.stopPropagation()
                }

                if (e.altKey && config.key && canMultiEdit) {
                    selection?.toggle(config.key, (data as any).id)
                }

                if (!e.altKey && canEdit && !open) {
                    setOpen(true)
                }
            }}
        >
            {plugin ? (
                itemShow || itemShow === 0 ? (
                    itemShow
                ) : (
                    <span style={{ opacity: 0.3 }}>{ctx.config.placeholder || ''}</span>
                )
            ) : (
                <span>unknown kind {config.kind}</span>
            )}
        </Row>
    )

    return canEdit ? (
        <DropdownContainer>
            <Dropdown
                align={{
                    offset: [0, 4],
                    overflow: {
                        adjustX: false,
                        adjustY: false,
                    },
                }}
                dropdownRender={() => {
                    return (
                        <XItemInnerPopup
                            key={uuid}
                            ctx={ctx}
                            plugin={plugin}
                            popupWidth={popupWidth}
                            setOpen={setOpen}
                            open={open}
                        />
                    )
                }}
                trigger={['click']}
                open={open}
                getPopupContainer={(trigger: any) => trigger.parentElement}
                placement={placement || (editorWidth > (width || 0) ? 'bottomRight' : 'bottomLeft')}
                destroyPopupOnHide={true}
                onOpenChange={(v) => {
                    if (!v) {
                        setOpen(false)
                    }
                }}
            >
                {itemNode}
            </Dropdown>
        </DropdownContainer>
    ) : (
        itemNode
    )
}

export const XItem: React.FC<{
    style?: React.CSSProperties
    width?: number
    bordered?: boolean
    editAreaBG?: string
    selection?: XSelection
    placement?: 'bottomLeft' | 'bottomCenter' | 'bottomRight'
    config: XItemConfig
    idArray?: Array<string>
    data?: any
    search?: any
    disableEdit?: boolean
    onChange: (adFlag: boolean) => void
}> = ({
    style,
    width,
    bordered,
    editAreaBG,
    selection,
    placement,
    config,
    idArray,
    data,
    search,
    disableEdit,
    onChange,
}) => {
    return (
        <XItemInner
            plugin={ItemManager.getPlugin(config.kind)}
            style={style}
            width={width}
            editAreaBG={editAreaBG}
            bordered={bordered}
            selection={selection}
            placement={placement}
            config={config}
            authMode={disableEdit ? 'none' : 'config'}
            idArray={idArray}
            data={data}
            search={search}
            onChange={onChange}
        />
    )
}

export const StyledBadge = styled(Badge)`
    & sup {
        padding: 0 3px 0 3px !important;
    }
`

export const XItemGroupEditButton: React.FC<{
    selection?: XSelection
    placement?: 'bottomLeft' | 'bottomCenter' | 'bottomRight'
    config: XItemConfig
    search?: any
    onChange: (adFlag: boolean) => void
}> = ({ selection, placement, config, search, onChange }) => {
    const plugin = ItemManager.getPlugin(config.kind)

    return (
        <XItemInner
            plugin={{
                renderItem: (ctx: XItemContext, popped) => (
                    <StyledBadge count={selection?.getSelectSum(config.key)} size={'small'} offset={[0, 0]}>
                        <IconSecondaryButton kind={'edit'} size={'small'} popped={popped} />
                    </StyledBadge>
                ),
                renderEditor: plugin?.renderEditor,
            }}
            style={{
                minHeight: 24,
                width: 24,
                height: 24,
                paddingLeft: 0,
                paddingRight: 0,
                borderWidth: 0,
                backgroundColor: 'transparent',
            }}
            authMode={'pass'}
            placement={placement}
            bordered={false}
            selection={selection}
            config={extendsConfig(config, { editor: { canEdit: false, canMultiEdit: false } })}
            idArray={selection?.getSelectedIds(config.key)}
            data={{}}
            search={search}
            onChange={onChange}
        />
    )
}
