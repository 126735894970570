import styled from 'styled-components'
import React from 'react'
import { Input, Modal, Row } from 'antd'
import { IconSecondaryButton, PrimaryButton, SecondaryButton } from './Button'
import { HAutoSpacer, HSpacer, VSpacer } from './Space'
import { Text } from './Text'
import config from '../../../package.json'
import { getUUID } from '../../utils/uuid'
import { App, AppPopupChannel } from '../../utils/app'
import { showError } from '../../utils/message'
import { sha256 } from '../../utils/crypto'

const BGDiv = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: linear-gradient(0deg, #1c2329 0%, #1e2023 100%);
`

export const CenterWindow: React.FC<{
    width: number
    height: number
    children?: React.ReactNode
}> = ({ width, height, children }) => {
    return (
        <div
            style={{
                position: 'relative',
                width: width,
                height: height,
                borderRadius: 2,
                overflow: 'clip',
                border: '2px solid rgba(26,152,185, 0.100)',
            }}
        >
            <BGDiv />
            <div
                style={{
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                }}
            >
                {children}
            </div>
        </div>
    )
}

export const PopupWindow: React.FC<{
    title: string
    size: 'middle' | 'small'
    checked: boolean
    button: React.ReactNode
    getContainer?: any
    getContent: (confirmLoading: boolean) => React.ReactNode
    onSubmit: () => Promise<boolean>
    onOpenChange?: (v: boolean) => void
    confirmButtonText?: string
    cancelButtonText?: string
}> = ({
    title,
    size,
    checked,
    button,
    getContainer,
    getContent,
    onSubmit,
    onOpenChange,
    confirmButtonText,
    cancelButtonText,
}) => {
    const [uuid] = React.useState(getUUID())
    const [open, _setOpen] = React.useState(false)
    const [confirmLoading, setConfirmLoading] = React.useState(false)

    const setOpen = (v: boolean) => {
        if (v !== open) {
            if (v) {
                App.EventHub.send(AppPopupChannel, 'PopupOpen', uuid)
            }

            _setOpen(v)
            onOpenChange && onOpenChange(v)
        }
    }

    return (
        <div>
            <div
                onClick={(e) => {
                    e.stopPropagation()
                    if (!open) {
                        setOpen(true)
                    }
                }}
            >
                {button}
            </div>

            <Modal
                open={open}
                closable={false}
                width={size === 'small' ? 312 : 480}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    if (open) {
                        setOpen(false)
                    }
                }}
                getContainer={getContainer}
                bodyStyle={{ padding: 0 }}
                destroyOnClose={true}
                footer={null}
                centered
            >
                <div>
                    <VSpacer height={size === 'small' ? 14 : 20} />
                    <Row
                        align={'middle'}
                        style={{
                            marginLeft: 16,
                            marginRight: 16,
                            height: size === 'small' ? 20 : 22,
                        }}
                    >
                        <Text
                            size={size === 'small' ? 'Normal' : 'Big'}
                            color={'Strong'}
                            style={{ lineHeight: size === 'small' ? '20px' : '22px' }}
                        >
                            {title}
                        </Text>
                        <HAutoSpacer />
                        <IconSecondaryButton
                            size={'small'}
                            kind={'close'}
                            disabled={confirmLoading}
                            onClick={() => {
                                setOpen(false)
                            }}
                        />
                    </Row>

                    {getContent(confirmLoading)}

                    {size === 'middle' ? <HSpacer color={config.app.dividerColor} height={1} /> : null}

                    <Row style={{ padding: 16 }}>
                        <HAutoSpacer />
                        <SecondaryButton
                            size={size}
                            style={{ width: size === 'middle' ? 60 : 48 }}
                            disabled={confirmLoading}
                            onClick={() => {
                                setOpen(false)
                            }}
                        >
                            {cancelButtonText || '取消'}
                        </SecondaryButton>
                        <HSpacer width={8} />
                        <PrimaryButton
                            loading={confirmLoading}
                            disabled={!checked}
                            style={{ width: size === 'middle' ? 60 : 48 }}
                            type={'primary'}
                            size={size}
                            onClick={() => {
                                if (!confirmLoading) {
                                    setConfirmLoading(true)
                                    onSubmit().finally(() => {
                                        setConfirmLoading(false)
                                        setOpen(false)
                                    })
                                }
                            }}
                        >
                            {confirmLoading ? '' : confirmButtonText || '确定'}
                        </PrimaryButton>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export const PopupConfirmWindow: React.FC<{
    content: string
    level: 'warn' | 'error'
    button: React.ReactNode
    needPassword?: boolean
    getContainer?: any
    onOpenChange: (confirm: boolean) => void
    disabled?: boolean
}> = ({ content, level, needPassword, button, getContainer, onOpenChange, disabled }) => {
    const [uuid] = React.useState(getUUID())
    const [open, _setOpen] = React.useState(false)
    const [confirmLoading, setConfirmLoading] = React.useState(false)
    const [password, setPassword] = React.useState('')

    const setOpen = (v: boolean) => {
        if (v !== open) {
            if (v) {
                App.EventHub.send(AppPopupChannel, 'PopupOpen', uuid)
            }

            _setOpen(v)
        }
    }

    return (
        <div>
            <div
                onClick={(e) => {
                    e.stopPropagation()
                    if (!open && !disabled) {
                        setOpen(true)
                    }
                }}
            >
                {button}
            </div>

            <Modal
                open={open}
                closable={false}
                width={312}
                confirmLoading={confirmLoading}
                getContainer={getContainer}
                bodyStyle={{ padding: 0 }}
                destroyOnClose={true}
                footer={null}
                centered
            >
                <div>
                    <VSpacer height={14} />
                    <Row style={{ marginLeft: 16, marginRight: 16 }}>
                        <span>
                            <i
                                className={level === 'warn' ? 'iconfont icon-tixing' : 'iconfont icon-shibai'}
                                style={{
                                    fontSize: 14,
                                    color: level === 'warn' ? '#F6AC00' : '#BE404A',
                                    marginRight: 6,
                                }}
                            />
                            {content}
                        </span>
                    </Row>

                    {needPassword ? (
                        <Input.Password
                            style={{ marginLeft: 16, marginRight: 16, marginTop: 8, width: 280 }}
                            placeholder={'请输入登录密码来确认您的行为'}
                            value={password}
                            allowClear={true}
                            onChange={(evt) => {
                                setPassword(evt.target.value)
                            }}
                        />
                    ) : null}

                    <Row style={{ padding: 16 }}>
                        <HAutoSpacer />
                        <SecondaryButton
                            size={'small'}
                            style={{ width: 48 }}
                            disabled={confirmLoading}
                            onClick={() => {
                                setPassword('')
                                setOpen(false)
                                onOpenChange(false)
                            }}
                        >
                            {'取消'}
                        </SecondaryButton>
                        <HSpacer width={8} />
                        <PrimaryButton
                            loading={confirmLoading}
                            style={{ width: 48 }}
                            type={'primary'}
                            size={'small'}
                            onClick={async () => {
                                if (!confirmLoading) {
                                    setConfirmLoading(true)

                                    try {
                                        if (needPassword) {
                                            const result = await App.Api.checkPassword(sha256(password))

                                            if (result) {
                                                setOpen(false)
                                                onOpenChange(true)
                                            } else {
                                                showError('密码无效').finally()
                                            }
                                        } else {
                                            setOpen(false)
                                            onOpenChange(true)
                                        }
                                    } finally {
                                        setPassword('')
                                        setConfirmLoading(false)
                                    }
                                }
                            }}
                        >
                            {'确定'}
                        </PrimaryButton>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}
