import React from 'react'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Row, Spin, Typography } from 'antd'

const { Text } = Typography
export const Loading: React.FC<{
    size: 'small' | 'normal' | 'big'
    text: string
}> = ({ size, text }) => {
    const iconSize = { small: 16, normal: 24, big: 32 }[size]
    const textSize = { small: 11, normal: 16, big: 20 }[size]

    return (
        <Row align="middle" justify="center">
            <Spin
                indicator={
                    <Loading3QuartersOutlined
                        style={{ fontSize: iconSize }}
                        spin
                    />
                }
            />

            <Text
                disabled={true}
                style={{ fontSize: textSize, marginLeft: textSize }}
            >
                {text}
            </Text>
        </Row>
    )
}
