export function validatePhone(v: string | null): Error | null {
    if (v != null && /^1\d{10}$/.test(v)) {
        return null
    } else {
        return new Error('手机号由11位数字组成')
    }
}

export function validateSmsCode(v: string | null): Error | null {
    if (v != null && /^\d{6}$/.test(v)) {
        return null
    } else {
        return new Error('验证码由六位数字组成')
    }
}

export function validateUsername(v: string | null): Error | null {
    if (v != null && v?.length > 0) {
        return null
    } else {
        return new Error('用户名不能为空')
    }
}

export function validatePassword(v: string | null): Error | null {
    if (v != null && v?.length >= 8) {
        return null
    } else {
        return new Error('密码至少由8位字符组成')
    }
}

export function validateLicense(v: string | null): Error | null {
    if (v != null && v?.length > 0) {
        return null
    } else {
        return new Error('请输入授权码')
    }
}

export function validateAlwaysOK(_: any): Error | null {
    return null
}

export function validateNotEmpty(v: string | null): Error | null {
    if (v != null && v?.length > 0) {
        return null
    } else {
        return new Error('不能为空')
    }
}

export function validateTrue(v: string | null): Error | null {
    if (v  === "true") {
        return null
    } else {
        return new Error('不是true')
    }
}

export function validateProjectName(v: string | null): Error | null {
    if (v != null && /^[A-Za-z0-9]{3,6}$/.test(v)) {
        return null
    } else {
        return new Error('3到6位数字和字母组成')
    }
}

export function validateShotName(v: string | null): Error | null {
    if (v != null && /^[0-9]{2,6}$/.test(v)) {
        return null
    } else {
        return new Error('2到6个数字')
    }
}

export function makeValidateFunc(config: any): (v: string | null) => Error | null {
    return function (v: string | null): Error | null {
        if (!config.rule) {
            return null
        } else {
            const re = new RegExp(config.rule)

            if (v != null && re.test(v)) {
                return null
            } else {
                return new Error(config.tips || '')
            }
        }
    }
}
