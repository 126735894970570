import React from 'react'
import styled from 'styled-components'

export const FloatContainer: React.FC<{
    style?: React.CSSProperties
    children?: React.ReactNode
}> = ({ style, children }) => {
    return <div style={{ position: 'relative', width: '100%', height: '100%', ...style }}>{children}</div>
}

export const FloatBox: React.FC<{
    style?: React.CSSProperties
    top?: number
    bottom?: number
    left?: number
    right?: number
    width?: number
    height?: number
    zIndex?: number
    children?: React.ReactNode
}> = ({ style, top, bottom, left, right, width, height, children, zIndex }) => {
    return (
        <div
            style={{
                position: 'absolute',
                top: top,
                bottom: bottom,
                left: left,
                right: right,
                width: width,
                height: height,
                zIndex: zIndex,
                ...style,
            }}
        >
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>{children}</div>
        </div>
    )
}
//
// export const VFlexContainer: React.FC<{
//     style?: React.CSSProperties
//     elements: Array<{ height?: number; minHeight?: number; node: React.ReactNode }>
// }> = ({ style, elements }) => {
//     const [elemRef, { height }] = useElementSize()
//     const numberOfAuto = elements.reduce((sum, v) => (v.height ? sum : sum + 1), 0)
//     const fixedHeight = elements.reduce((sum, v) => (v.height ? sum + v.height : sum), 0)
//     const scrollHeight = elements.reduce((sum, v) => sum + (v.height || v.minHeight || 0), 0)
//     const autoHeight = Math.floor(Math.max(0, (height - fixedHeight) / numberOfAuto))
//
//     return (
//         <div
//             ref={elemRef}
//             style={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 width: `calc(100% - ${style?.marginLeft || 0}px  - ${style?.marginRight || 0}px)`,
//                 height: `calc(100% - ${style?.marginTop || 0}px  - ${style?.marginBottom || 0}px)`,
//                 ...style,
//             }}
//         >
//             <div style={{ overflowY: scrollHeight > height ? 'auto' : 'hidden' }}>
//                 {elements.map((v, idx) => {
//                     return (
//                         <div key={idx} style={{ height: v.height || Math.max(v.minHeight || 0, autoHeight) }}>
//                             {v.node}
//                         </div>
//                     )
//                 })}
//             </div>
//         </div>
//     )
// }

export const VFlexContainer: React.FC<{
    style?: React.CSSProperties
    children: React.ReactNode
}> = ({ style, children }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: `calc(100% - ${style?.marginLeft || 0}px  - ${style?.marginRight || 0}px)`,
                height: `calc(100% - ${style?.marginTop || 0}px  - ${style?.marginBottom || 0}px)`,
                ...style,
            }}
        >
            {children}
        </div>
    )
}

const StyledPopupDiv = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    & .ant-modal-wrap {
        position: absolute !important;
    }

    & .ant-modal-mask {
        position: absolute !important;
    }
`

export const PopupModalContainer: React.FC<{
    popupRef: any
    style?: React.CSSProperties
    children: React.ReactNode
}> = ({ popupRef, style, children }) => {
    return (
        <StyledPopupDiv ref={popupRef} style={style}>
            {children}
        </StyledPopupDiv>
    )
}
