import { SHA256 } from 'crypto-js'

export function sha256(v: string): string {
    return SHA256(v).toString()
}

export function base64ToBlob(b64String: string, mimeType: string = ''): Blob {
    const byteCharacters = atob(b64String)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: mimeType })
}
