import React, { useState, useEffect, useRef, useCallback } from 'react'
import { LocalStorage } from './storage'
import { timeNowMS } from './time'
import { rangeNumber } from './math'
import { App } from './app'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootRouterAppHubLogin } from '../index'
import { getNavigatePath } from './location'
import { getSeed } from './uuid'

interface Size {
    width: number
    height: number
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [(node: T | null) => void, Size] {
    const [ref, setRef] = useState<T | null>(null)
    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0,
    })

    React.useEffect(() => {
        let width = 0
        let height = 0

        const timer = setInterval(() => {
            if (ref) {
                if (ref.offsetWidth !== width || ref.offsetHeight !== height) {
                    width = ref.offsetWidth
                    height = ref.offsetHeight
                    setSize({ width: width, height: height })
                }
            }
        }, 100)

        return () => {
            clearInterval(timer)
        }
    }, [ref])

    return [setRef, size]
}

export function useSafeCallBack() {
    const sequence = useRef(getSeed())
    const mounted = useRef(false)
    const callRef = useRef((fn: (isSafe: () => boolean) => void) => {
        let thisSequence = getSeed()
        sequence.current = thisSequence
        fn(() => {
            return mounted.current && sequence.current === thisSequence
        })
    })

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    return useCallback(
        (fn: (isSafe: () => boolean) => void) => {
            if (callRef.current) {
                callRef.current(fn)
            }
        },
        [callRef]
    )
}

export function useAutoLoad() {
    const safeCallBack = useSafeCallBack()
    const location = useLocation()
    const navigate = useNavigate()
    const [status, setStatus] = useState('loading')
    const backHRef = location.state?.backHRef || getNavigatePath()

    useEffect(() => {
        safeCallBack((isSafe) => {
            App.load(
                () => {
                    navigate(RootRouterAppHubLogin, {
                        replace: true,
                        state: { backHRef: backHRef },
                    })
                },
                false
            )
                .then(() => {
                    isSafe() && setStatus('loaded')
                })
                .catch((e) => {
                    isSafe() && setStatus('error')
                    throw e
                })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return status
}

export function useStorageSmsTimer(interval: number): [number, () => void] {
    const [remains, setRemains] = useState(() => {
        const storageTime = (LocalStorage.read('sms.sendTime') as number) || 0
        return rangeNumber(Math.round(storageTime + interval - timeNowMS() / 1000), 0, interval)
    })

    useEffect(() => {
        const handler = setTimeout(() => {
            if (remains > 0) {
                setRemains((v) => v - 1)
            } else {
                setRemains(0)
            }
        }, 1000)

        return () => {
            clearTimeout(handler)
        }
    }, [remains])

    return [
        remains,
        () => {
            LocalStorage.write('sms.sendTime', Math.round(timeNowMS() / 1000))
            setRemains(Math.round(interval))
        },
    ]
}

export interface ValidateData {
    valueMap: Map<string, any>
    errorMap: Map<string, Error | null>
    setValue: (key: string, value: any) => void
    checked: boolean
}

export function useValidateMap(
    validateMap: Map<string, (v: any) => Error | null>,
    initValue?: Map<string, any>
): ValidateData {
    const fnInitErrorMap = () => {
        let ret = new Map<string, Error | null>()

        validateMap.forEach((validateFn, key) => {
            const val = initValue?.get(key)
            if (!!val) {
                ret.set(key, validateFn ? validateFn(val) : null)
            }
        })

        return ret
    }

    const [valueMap, setValueMap] = useState(initValue ? initValue : new Map<string, any>())
    const [checked, setChecked] = useState(Array.from(validateMap.keys()).length <= 0)
    const [errorMap, setErrorMap] = useState(fnInitErrorMap)

    return {
        valueMap: valueMap,
        errorMap: errorMap,
        setValue: (key: string, value: any) => {
            if (key === '@all' && value === '@init') {
                setValueMap(initValue ? initValue : new Map<string, any>())
                setErrorMap(fnInitErrorMap())
                setChecked(Array.from(validateMap.keys()).length <= 0)
            } else {
                let innerCheck = true
                const validateFn = validateMap.get(key)

                setValueMap((oldValueMap) => {
                    const newValueMap = new Map(oldValueMap)
                    newValueMap.set(key, value)
                    return newValueMap
                })

                setErrorMap((oldErrorMap) => {
                    const newErrorMap = new Map(oldErrorMap)
                    newErrorMap.set(key, validateFn ? validateFn(value) : null)

                    validateMap.forEach((_, key) => {
                        if (newErrorMap.get(key) !== null) {
                            innerCheck = false
                        }
                    })

                    setChecked(innerCheck)

                    return newErrorMap
                })
            }
        },
        checked: checked,
    }
}
