import React from 'react'
import bgImage from '../assets/common/bg.png'
import { Page } from '../components/common/Page'
import { LocalStorage } from '../utils/storage'
import { App } from '../utils/app'
import { useNavigate } from 'react-router-dom'
import { RootRouterAppHubLogin } from '../index'
import { Loading } from '../components/common/Loading'
import { Text } from '../components/common/Text'
import { useSafeCallBack } from '../utils/hook'

export const LogoutPage: React.FC = () => {
    const safeCallBack = useSafeCallBack()
    const navigate = useNavigate()

    React.useEffect(() => {
        LocalStorage.write('user.login.auto', 'no')
        LocalStorage.write('user.login.security', '')

        safeCallBack((isSafe) => {
            App.Api.logout().finally(() => {
                if (isSafe()) {
                    App.reset()
                    navigate(RootRouterAppHubLogin)
                }
            })
        })
    }, [navigate, safeCallBack])

    return (
        <Page
            align="middle"
            justify="center"
            style={{
                background: `url(${bgImage}) no-repeat center center fixed`,
                backgroundSize: 'cover',
            }}
        >
            <Loading size="big" text="" />
            <Text color={'Weaker'}>注销中 ...</Text>
        </Page>
    )
}
