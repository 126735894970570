import { v4 as uuidv4 } from 'uuid'

export function getUUID(): string {
    return uuidv4()
}

export const getSeed = (() => {
    let seed = 0
    return () => {
        return seed++
    }
})()
