export class LocalStorage {
    public static read(key: string): any {
        try {
            let jsonStr = window.localStorage.getItem(key)

            if (jsonStr === null) {
                return null
            }

            return JSON.parse(jsonStr)
        } catch {
            return null
        }
    }

    public static write(key: string, value: any) {
        window.localStorage.setItem(key, JSON.stringify(value))
    }

    public static remove(key: string) {
        window.localStorage.removeItem(key)
    }

    public static has(key: string): boolean {
        return window.localStorage.getItem(key) !== null
    }
}
