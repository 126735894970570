import { DatePicker, Image, Input, InputNumber, Rate, Row, Typography } from 'antd'
import moment from 'moment'
import config from '../../../package.json'
import invalidImage from '../../assets/common/invalid.png'
import { ProjectRoleSelect } from '../../routes/main/company/CompanyModule'
import { App } from '../../utils/app'
import { MakeQueryKey } from '../../utils/database'
import { utcMSToDate } from '../../utils/time'
import { renderIf } from '../../utils/ui'
import { IconButton } from '../common/Button'
import { XImageUpload } from '../common/Image'
import { XItemContext } from '../common/Item'
import { XDateEmbeddedPicker } from '../common/Picker'
import { SearchManager, XSearchContext } from '../common/Search'
import { BoolSelect, GetSelectFilter, XSelect } from '../common/Select'
import { HSpacer } from '../common/Space'
import { ErrorText, Text, TextWithEllipsis } from '../common/Text'
import { ItemManager } from './index'

export function initialize() {
    ItemManager.addPlugin('date', {
        renderItem: (ctx: XItemContext) => {
            if (ctx.data && ctx.config.key) {
                const v = ctx.data[ctx.config.key]
                return v > 0 ? moment(utcMSToDate(v as number)).format('YYYY-MM-DD') : ''
            } else {
                return ''
            }
        },
        renderEditor: (
            ctx: XItemContext,
            value: any,
            setValue: (v: any) => void,
            error: Error | null,
            disabled: boolean,
            _: boolean
        ) => {
            return (
                <>
                    <Row>
                        <XDateEmbeddedPicker
                            value={value}
                            onChange={(v) => {
                                setValue(v)
                            }}
                        />
                    </Row>
                    <ErrorText error={error} />
                </>
            )
        },
    })

    ItemManager.addPlugin('number', {
        renderItem: (ctx: XItemContext) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}

            if (params.changeValue !== undefined) {
                return params.changeValue.toString()
            } else if (ctx.data && ctx.config.key) {
                return ctx.data[ctx.config.key]
            } else {
                return ''
            }
        },
        renderEditor: (
            ctx: XItemContext,
            value: any,
            setValue: (v: any) => void,
            error: Error | null,
            disabled: boolean,
            _: boolean
        ) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}

            return (
                <>
                    <Row>
                        <InputNumber
                            // ref={(v) => {
                            //     if (open) {
                            //         const valueLen = (value + '').length
                            //         v?.select()
                            //         v?.setSelectionRange(valueLen, valueLen)
                            //     }
                            // }}
                            style={{ width: '100%' }}
                            min={params.min}
                            max={params.max}
                            placeholder={params.placeholder || ''}
                            value={value}
                            disabled={disabled}
                            onChange={(v) => {
                                setValue(v)
                            }}
                        />
                    </Row>
                    <ErrorText error={error} />
                </>
            )
        },
    })

    ItemManager.addPlugin('rate', {
        renderItem: (ctx: XItemContext) => {
            if (ctx.data && ctx.data.id && ctx.config.key) {
                const v = ctx.data[ctx.config.key]
                return v === 0 || v > 0 ? (
                    <Rate
                        allowHalf
                        value={v / 2}
                        style={{ width: 150, transform: 'scale(0.75)', margin: -18 }}
                        disabled={true}
                    />
                ) : (
                    ''
                )
            } else {
                return ''
            }
        },
        renderEditor: (
            ctx: XItemContext,
            value: any,
            setValue: (v: any) => void,
            error: Error | null,
            disabled: boolean,
            _: boolean
        ) => {
            return (
                <>
                    <Rate
                        allowHalf
                        value={value / 2}
                        style={{ width: 150, transform: 'scale(0.75)', margin: -18 }}
                        onChange={(v) => {
                            setValue(Math.floor(v * 2))
                        }}
                    />
                </>
            )
        },
    })

    ItemManager.addPlugin('text', {
        renderItem: (ctx: XItemContext) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}

            if (ctx.data && ctx.config.key) {
                const value = params.changeValue || ctx.data[ctx.config.key]

                return (
                    <Typography.Paragraph
                        style={{
                            marginBottom: 0,
                            width: '100%',
                            minHeight: '22px',
                            wordBreak: 'break-all',
                            ...params?.style,
                        }}
                        ellipsis={{
                            rows: params?.rows || 1,
                            expandable: true,
                            onExpand: function (e) {
                                e.preventDefault()
                                e.stopPropagation()
                            },
                        }}
                    >
                        {value}
                    </Typography.Paragraph>
                )
            } else {
                return ''
            }
        },
        renderEditor: (
            ctx: XItemContext,
            value: any,
            setValue: (v: any) => void,
            error: Error | null,
            disabled: boolean,
            _: boolean
        ) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}

            return (
                <>
                    <Input.TextArea
                        // ref={(v) => {
                        //     if (open) {
                        //         const valueLen = (value + '').length
                        //         v?.select()
                        //         v?.setSelectionRange(valueLen, valueLen)
                        //     }
                        // }}
                        placeholder={params.placeholder || ''}
                        value={value}
                        disabled={disabled}
                        style={{ width: '100%', height: '120px', ...params?.style }}
                        allowClear={true}
                        onChange={(evt) => {
                            setValue(evt.target.value)
                        }}
                    />

                    <ErrorText error={error} />
                </>
            )
        },
    })

    ItemManager.addPlugin('string', {
        renderItem: (ctx: XItemContext) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}

            if (params.changeValue !== undefined) {
                return <span style={{ width: '100%', wordBreak: 'break-all' }}>{params.changeValue.toString()}</span>
            } else if (ctx.data && ctx.config.key) {
                return <span style={{ width: '100%', wordBreak: 'break-all' }}>{ctx.data[ctx.config.key]}</span>
            } else {
                return ''
            }
        },
        renderEditor: (
            ctx: XItemContext,
            value: any,
            setValue: (v: any) => void,
            error: Error | null,
            disabled: boolean,
            _: boolean
        ) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}

            return (
                <>
                    <Input
                        // ref={(v) => {
                        //     if (open) {
                        //         const valueLen = (value + '').length
                        //         v?.select()
                        //         v?.setSelectionRange(valueLen, valueLen)
                        //     }
                        // }}
                        placeholder={params.placeholder || ''}
                        value={value}
                        disabled={disabled}
                        allowClear={true}
                        onChange={(evt) => {
                            setValue(evt.target.value)
                        }}
                    />

                    <ErrorText error={error} />
                </>
            )
        },
    })

    ItemManager.addPlugin('bool', {
        renderItem: (ctx: XItemContext) => {
            if (ctx.data && ctx.config.key) {
                const v = ctx.data[ctx.config.key]
                if (v === true) {
                    return '是'
                } else if (v === false) {
                    return '否'
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        renderEditor: (
            ctx: XItemContext,
            value: any,
            setValue: (v: any) => void,
            error: Error | null,
            disabled: boolean,
            _: boolean
        ) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}

            return (
                <>
                    <BoolSelect
                        embedded={true}
                        value={value}
                        placeholder={params.placeholder}
                        disabled={disabled}
                        allowClear={false}
                        onChange={(v: any) => {
                            setValue(v)
                        }}
                    />

                    <ErrorText error={error} />
                </>
            )
        },
    })

    ItemManager.addPlugin('image', {
        renderItem: (ctx: XItemContext) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}

            if (ctx.data && ctx.config.key) {
                const value = ctx.data[ctx.config.key]
                return (
                    <Row style={{ ...params?.style, position: 'relative' }}>
                        <Image
                            style={{
                                width: 240,
                                height: 135,
                                userSelect: 'none',
                                ...params?.style,
                            }}
                            src={App.Api.fileURL(value) || invalidImage}
                            preview={false}
                        />

                        {renderIf(
                            params.important,
                            <Row
                                style={{
                                    position: 'absolute',
                                    width: 16,
                                    height: 16,
                                    left: 5,
                                    borderBottomLeftRadius: 2,
                                    borderBottomRightRadius: 2,
                                    background: '#BE404A',
                                }}
                                align={'middle'}
                                justify={'center'}
                            >
                                <i className={`iconfont icon-yangban`} style={{ fontSize: 10 }}></i>
                            </Row>
                        )}
                    </Row>
                )
            } else {
                return null
            }
        },
        renderEditor: (ctx: XItemContext, value: any, setValue: (v: any) => void, error: Error | null) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}

            return (
                <>
                    <XImageUpload
                        value={value}
                        style={{
                            width: 240,
                            height: 135,
                            userSelect: 'none',
                            ...params?.style,
                        }}
                        uploadBucket={params?.bucketId}
                        onChange={function (v: string): void {
                            setValue(v)
                        }}
                    />

                    <ErrorText error={error} />
                </>
            )
        },
    })

    ItemManager.addPlugin('object-attr', {
        renderItem: (ctx: XItemContext) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}
            const attr = params.attr || 'name'
            if (ctx.data && ctx.config.key) {
                const obj = ctx.data[ctx.config.key]
                if (obj && obj[attr]) {
                    return obj[attr]
                }
            }

            return ''
        },
    })

    SearchManager.addPlugin('search', (ctx: XSearchContext, search: any) => {
        const params = ctx.config.getParams ? ctx.config.getParams(search) : {}
        const value = search[params.keys[0]]

        return !value ? (
            <Input.Search
                placeholder={params.placeholder}
                style={{ width: '100%', minWidth: 0 }}
                value={value}
                onSearch={(v: any) => {
                    const keys: string[] = params.keys
                    if (keys && keys.length > 0) {
                        ctx.onSearchChange(keys, !v || v.trim() === '' ? undefined : v)
                    }
                }}
            />
        ) : (
            <Row
                align={'middle'}
                justify={'center'}
                style={{
                    width: '100%',
                    minWidth: 0,
                    flexDirection: 'row',
                    border: '#97979750 1px solid',
                    borderRadius: 2,
                }}
            >
                <Row
                    style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        height: 32,
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    <Row align={'middle'} style={{ position: 'absolute', top: 0, left: 8, right: 8, bottom: 0 }}>
                        <TextWithEllipsis width={'100%'} style={{ color: config.app.primaryColor }}>
                            {value}
                        </TextWithEllipsis>
                    </Row>
                </Row>

                <HSpacer width={1} height={32} color={'#97979750'} />

                <Row style={{ width: 32, flexGrow: 0, flexShrink: 0 }}>
                    <IconButton
                        type={'icon'}
                        style={{ background: 'rgb(67,67,67, 0.2)' }}
                        icon={'icon-xingzhuangjiehe'}
                        onClick={() => {
                            const keys: string[] = params.keys
                            ctx.onSearchChange(keys, undefined)
                        }}
                    />
                </Row>
            </Row>
        )
    })

    SearchManager.addPlugin('bool', (ctx: XSearchContext, search: any) => {
        const params = ctx.config.getParams ? ctx.config.getParams(search) : {}
        const key = MakeQueryKey(ctx.config.key, '=', true)
        return (
            <BoolSelect
                embedded={false}
                style={{ width: '100%', minWidth: 0 }}
                value={search[key]}
                allowClear={true}
                placeholder={params.placeholder}
                onChange={(v: any) => {
                    ctx.onSearchChange([key], v === true || v === false ? v : undefined)
                }}
            />
        )
    })

    SearchManager.addPlugin('select', (ctx: XSearchContext, search: any) => {
        const params = ctx.config.getParams ? ctx.config.getParams(search) : {}
        const key = MakeQueryKey(ctx.config.key, '#', true)
        return (
            <XSelect
                embedded={false}
                style={{ width: '100%', minWidth: 0 }}
                mode={'multiple'}
                value={search[key]}
                placeholder={params.placeholder}
                onChange={(v: any) => {
                    ctx.onSearchChange([key], v.length > 0 ? v : undefined)
                }}
                onLoad={async () => {
                    return params.items
                }}
                itemRender={(v) => {
                    return (
                        <Row align={'middle'}>
                            <span> {v.name}</span>
                        </Row>
                    )
                }}
                onFilter={GetSelectFilter('name', 'name')}
            />
        )
    })

    SearchManager.addPlugin('-date-range', (ctx: XSearchContext, search: any) => {
        const params = ctx.config.getParams ? ctx.config.getParams(search) : {}
        return (
            <Row align={'middle'} style={{ width: '100%', minWidth: 0 }}>
                <DatePicker
                    style={{ flex: 1, minWidth: 0 }}
                    format={'YYYY-MM-DD'}
                    onChange={(v) => {
                        if (v) {
                            const data = v.toDate()
                            data.setHours(0, 0, 0, 0)
                            ctx.onSearchChange([params.leftKey], data.getTime())
                        } else {
                            ctx.onSearchChange([params.leftKey], undefined)
                        }
                    }}
                    placement={'bottomLeft'}
                />
                <Row justify={'center'} style={{ width: 20 }}>
                    <Text>-</Text>
                </Row>
                <DatePicker
                    style={{ flex: 1, minWidth: 0 }}
                    format={'YYYY-MM-DD'}
                    onChange={(v) => {
                        if (v) {
                            const data = v.toDate()
                            data.setHours(0, 0, 0, 0)
                            ctx.onSearchChange([params.rightKey], data.getTime())
                        } else {
                            ctx.onSearchChange([params.rightKey], undefined)
                        }
                    }}
                    placement={'bottomLeft'}
                />
            </Row>
        )
    })

    ItemManager.addPlugin('role', {
        renderItem: (ctx: XItemContext) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}
            const value = ctx.data[ctx.config.key]

            const list = JSON.parse(value || '[]')
            let showValue = ''
            let first = true

            // 遍历list，拼接字符串
            list.forEach((item: any) => {
                if (!first) {
                    showValue += '、'
                }

                first = false

                switch (item) {
                    case 'director':
                        showValue += '导演'
                        break
                    case 'recorder':
                        showValue += '现场记录'
                        break
                    case 'project':
                        showValue += '项目管理'
                        break
                    case 'company':
                        showValue += '公司管理'
                        break
                }
            })

            if (ctx.data && ctx.config.key) {
                return (
                    <span
                        style={{ width: '100%', wordBreak: 'break-all', fontSize: 12, color: 'rgba(54,177,155,1.000)' }}
                    >
                        {showValue}
                    </span>
                )
            } else {
                return (
                    <span style={{ width: '100%', wordBreak: 'break-all', color: 'rgba(255,255,255,0.25)' }}>
                        {params.placeholder || ''}
                    </span>
                )
            }
        },
        renderEditor: (ctx: XItemContext, value: any, setValue: (v: any) => void, error: Error | null) => {
            const params = ctx.config.getParams ? ctx.config.getParams(ctx) : {}
            const selects = JSON.parse(value || '[]')

            return (
                <>
                    <ProjectRoleSelect
                        embedded={true}
                        style={{ width: 280 }}
                        placeholder={params.placeholder || ''}
                        value={selects}
                        onChange={(v) => {
                            setValue(JSON.stringify(v || []))
                        }}
                    />

                    <ErrorText error={error} />
                </>
            )
        },
    })
}
