import React, { useState } from 'react'
import { Row } from 'antd'
import config from '../../../package.json'
import { HSpacer } from '../common/Space'
import { renderIf } from '../../utils/ui'

export const DrawerRowContainer: React.FC<{
    style?: React.CSSProperties
    width: number
    height: number | 'unset'
    label?: string
    labelWidth: number
    left: React.ReactNode
    delimiter?: React.ReactNode
    right?: React.ReactNode
}> = ({ style, width, height, label, labelWidth, left, delimiter, right }) => {
    return (
        <Row style={{ width: width, ...style }}>
            <Row align="middle" style={{ width: labelWidth, height: height }} dir={'rtl'}>
                {label}
            </Row>

            <HSpacer width={8} />

            <Row align="middle" style={{ height: height, color: config.app.fontColorStrong }}>
                {left}
                {delimiter}
                {right}
            </Row>
        </Row>
    )
}

export const InputLabelContainer: React.FC<{
    width: number
    label: string
    labelWidth: number
    labelHeight: number
    simple?: boolean
    labelDirection?: 'left' | 'right'
    hideError?: boolean
    element: React.ReactNode
    error?: Error | null
}> = ({ width, label, simple, labelWidth, labelHeight, labelDirection, hideError, element, error }) => {
    return (
        <>
            <Row style={{ width: width }}>
                {renderIf(
                    !simple,
                    <>
                        <Row
                            align="middle"
                            style={{ width: labelWidth, height: labelHeight }}
                            dir={labelDirection === 'left' ? 'ltr' : 'rtl'}
                        >
                            <div dir={'ltr'}>{label}</div>
                        </Row>
                        <HSpacer width={8} />
                    </>
                )}

                <Row
                    style={{
                        color: config.app.fontColorStrong,
                    }}
                >
                    {element}
                </Row>
            </Row>

            {hideError ? null : (
                <Row
                    style={{
                        marginLeft: simple ? 0 : labelWidth + 8,
                        width: simple ? width : width - 8 - labelWidth,
                        height: 16,
                        fontSize: config.app.fontSizeSmall,
                        color: config.app.statusColorError,
                        overflow: 'clip',
                    }}
                >
                    {error ? error.message : ''}
                </Row>
            )}
        </>
    )
}

export const InputRectContainer: React.FC<{
    style?: React.CSSProperties
    label: string
    width: number
    disabled?: boolean
    element: React.ReactNode
    tail?: React.ReactNode
    error?: Error | null
}> = ({ style, label, width, element, tail, error, disabled }) => {
    const [focus, setFocus] = useState(false)
    const [mouseOver, setMouseOver] = useState(false)

    return (
        <>
            <Row
                align="middle"
                style={{
                    width: width,
                    height: 40,
                    borderRadius: 2,
                    overflow: 'clip',
                    border: `1px solid ${
                        focus || mouseOver ? config.app.inputBorderColorActive : config.app.inputBorderColor
                    }`,
                    transition: config.app.transitionAll,
                    ...style,
                }}
                onFocus={() => {
                    !disabled && setFocus(true)
                }}
                onBlur={() => {
                    setFocus(false)
                }}
                onMouseOver={() => {
                    !disabled && setMouseOver(true)
                }}
                onMouseOut={() => {
                    setMouseOver(false)
                }}
            >
                <Row
                    align="middle"
                    style={{
                        width: 79,
                        paddingLeft: 12,
                        color: disabled ? config.app.fontColorWeaker : config.app.fontColorNormal,
                        transition: config.app.transitionAll,
                    }}
                >
                    {label}
                </Row>

                <Row
                    style={{
                        width: 1,
                        height: 14,
                        backgroundColor: config.app.inputBorderColor,
                    }}
                />

                <Row
                    style={{
                        width: width - 82 - (tail ? 99 : 0),
                        color: config.app.fontColorStrong,
                    }}
                >
                    {element}
                </Row>

                {tail ? (
                    <Row
                        style={{
                            width: 1,
                            height: '100%',
                            backgroundColor: config.app.inputBorderColor,
                        }}
                    />
                ) : null}

                {tail ? <Row style={{ width: 98 }}>{tail}</Row> : null}
            </Row>

            <Row
                style={{
                    width: width,
                    height: 16,
                    fontSize: config.app.fontSizeSmall,
                    color: config.app.statusColorError,
                    overflow: 'clip',
                }}
            >
                {error ? error.message : ''}
            </Row>
        </>
    )
}
