import { Col, Row } from 'antd'
import React from 'react'
import { IconSecondaryButton } from '../../../components/common/Button'
import { PopupModalContainer, VFlexContainer } from '../../../components/common/Container'
import { HAutoSpacer } from '../../../components/common/Space'
import { XTabs } from '../../../components/common/Tabs'
import { Text } from '../../../components/common/Text'
import { App, AppPopupChannel } from '../../../utils/app'
import { CompanyProjectInfoTab } from './CompanyProjectInfoTab'

const ProjectDetailTabs: React.FC<{
    data?: any
    popupContainerRef: any
}> = ({ data, popupContainerRef }) => {
    const [activeKey, setActiveKey] = React.useState('info')

    const items = [
        {
            label: '基本信息',
            key: 'info',
        },
        {
            label: '人员配置',
            key: 'team',
        },
    ]

    let content: React.ReactNode = null

    switch (activeKey) {
        case 'info':
            content = <CompanyProjectInfoTab projectID={data?.id} />
            break
        // case 'team':
        //     content = <ProjectTeamTab projectID={data?.id} />
        //     break
        default:
            break
    }

    return (
        <VFlexContainer style={{ width: '100%' }}>
            <Col style={{ minHeight: 40, maxHeight: 40, flex: 0, marginLeft: 16, marginRight: 16 }}>
                <XTabs
                    size={'small'}
                    style={{ width: '100%', height: 48 }}
                    items={items}
                    activeKey={activeKey}
                    onChange={setActiveKey}
                    tabBarBackground={'transparent'}
                />
            </Col>

            <VFlexContainer style={{ flex: 1, overflowY: 'auto' }}>{content}</VFlexContainer>
        </VFlexContainer>
    )
}

export const CompanyProjectDrawer: React.FC<{ id: string; style?: React.CSSProperties }> = ({ id, style }) => {
    const popupRef = React.useRef(null)
    const [data, setData] = React.useState(null as any)

    React.useEffect(() => {
        setData(null)

        App.Api.details('company_project', [id])
            .then((v) => {
                if (v && v.length === 1) {
                    setData(v[0])
                } else {
                    setData(null)
                }
            })
            .catch(() => {
                setData(null)
            })
    }, [id])

    return (
        <VFlexContainer style={{ ...style, marginTop: 136 }}>
            <PopupModalContainer popupRef={popupRef}>
                <VFlexContainer style={{ height: '100%' }}>
                    <Row
                        align={'middle'}
                        style={{ minHeight: 60, maxHeight: 60, flex: 0, marginLeft: 16, marginRight: 16 }}
                    >
                        <Text size={'Bigger'} color={'Stronger'}>
                            {data?.name}
                        </Text>
                        <HAutoSpacer />
                        <IconSecondaryButton
                            size={'large'}
                            kind={'close'}
                            onClick={() => {
                                App.EventHub.send(AppPopupChannel, 'PopupClose', '')
                            }}
                        />
                    </Row>

                    <Col style={{ flex: 1, width: '100%' }}>
                        <ProjectDetailTabs data={data} popupContainerRef={popupRef} />
                    </Col>
                </VFlexContainer>
            </PopupModalContainer>
        </VFlexContainer>
    )
}
