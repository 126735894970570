import { Image, Input, Row } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { RootRouterAppHubLogin } from '../../..'
import config from '../../../../package.json'
import invalidImage from '../../../assets/common/user.png'
import { IconButton } from '../../../components/common/Button'
import { VFlexContainer } from '../../../components/common/Container'
import { XImageUpload } from '../../../components/common/Image'
import { XItem, XItemConfig } from '../../../components/common/Item'
import { VSpacer } from '../../../components/common/Space'
import { PopupWindow } from '../../../components/common/Window'
import { DrawerRowContainer, InputLabelContainer } from '../../../components/input/container'
import { App } from '../../../utils/app'
import { sha256 } from '../../../utils/crypto'
import { useValidateMap } from '../../../utils/hook'
import { validateNotEmpty, validatePassword, validateTrue } from '../../../utils/validate'

export const StyledInput = styled(Input.Password)`
    color: inherit;
    background-color: inherit;
    &:hover {
        color: inherit;
        border-color: ${config.app.primaryColor} !important;
        outline: 0;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    &.ant-input-affix-wrapper-focused {
        color: inherit;
        border-color: ${config.app.primaryColor} !important;
        outline: 0;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
`

export const UserColumnRealName: XItemConfig = {
    kind: 'string',
    key: 'realname',
    service: 'user',
    column: {
        title: '姓名',
        width: 150,
    },
    editor: {
        title: '修改姓名',
        width: 300,
        canEdit: (ctx) => {
            return true
        },
        canMultiEdit: false,
    },
}

export const UserColumnPhone: XItemConfig = {
    kind: 'string',
    key: 'phone',
    service: 'user',
    column: {
        title: '手机号',
        width: 150,
    },
}

export const UserColumnCreateTime: XItemConfig = {
    kind: 'date',
    key: 'create_time',
    service: 'user',
    column: {
        title: '注册时间',
        width: 150,
    },
}

export const UserColumnEmail: XItemConfig = {
    kind: 'string',
    key: 'email',
    service: 'user',
    column: {
        title: '邮箱',
        width: 150,
    },
    editor: {
        title: '修改邮箱',
        width: 300,
        canEdit: (ctx) => {
            return true
        },
        canMultiEdit: false,
    },
}

export const UserColumnGender: XItemConfig = {
    kind: 'string',
    key: 'gender',
    service: 'user',
    column: {
        title: '性别',
        width: 150,
    },
    getParams: (ctx) => {
        return { changeValue: ctx.data?.gender === true ? '男' : '女' }
    },
}

const ItemWidth = 400
const LabelWidth = 100

const ChangePasswordButton: React.FC<{
    button: React.ReactNode
    onChange?: () => void
}> = ({ button, onChange }) => {
    const data = useValidateMap(
        new Map([
            ['oldPassword', validateNotEmpty],
            ['passwordOne', validatePassword],
            ['passwordTwo', validatePassword],
            ['checked', validateTrue],
        ]),
        new Map([
            ['oldPassword', ''],
            ['passwordOne', ''],
            ['passwordTwo', ''],
            ['checked', ''],
        ])
    )

    const getContent = (confirmLoading: boolean) => {
        return (
            <>
                <Row justify={'center'} style={{ height: 13 }} />

                <Row justify={'center'} style={{ marginTop: 6, marginLeft: 16, marginRight: 16 }}>
                    <InputLabelContainer
                        width={480}
                        label={'旧密码'}
                        labelWidth={115}
                        labelHeight={32}
                        element={
                            <StyledInput
                                style={{ width: 280 }}
                                placeholder={'请输入'}
                                value={data.valueMap.get('oldPassword')}
                                disabled={confirmLoading}
                                allowClear={true}
                                onChange={(v) => {
                                    data.setValue('oldPassword', v.target.value)
                                }}
                            />
                        }
                        error={data.errorMap.get('oldPassword')}
                    />
                </Row>

                <Row justify={'center'} style={{ marginTop: 6, marginLeft: 16, marginRight: 16 }}>
                    <InputLabelContainer
                        width={480}
                        label={'新密码'}
                        labelWidth={115}
                        labelHeight={32}
                        element={
                            <StyledInput
                                style={{ width: 280 }}
                                placeholder={'请输入'}
                                value={data.valueMap.get('passwordOne')}
                                disabled={confirmLoading}
                                allowClear={true}
                                onChange={(v) => {
                                    data.setValue('passwordOne', v.target.value)

                                    if (data.valueMap.get('passwordTwo') === v.target.value) {
                                        data.setValue('checked', 'true')
                                    } else {
                                        data.setValue('checked', '')
                                    }
                                }}
                            />
                        }
                        error={data.errorMap.get('passwordOne')}
                    />
                </Row>

                <Row justify={'center'} style={{ marginTop: 6, marginLeft: 16, marginRight: 16 }}>
                    <InputLabelContainer
                        width={480}
                        label={'确认密码'}
                        labelWidth={115}
                        labelHeight={32}
                        element={
                            <StyledInput
                                style={{ width: 280 }}
                                placeholder={'请输入'}
                                value={data.valueMap.get('passwordTwo')}
                                disabled={confirmLoading}
                                allowClear={true}
                                onChange={(v) => {
                                    data.setValue('passwordTwo', v.target.value)

                                    if (data.valueMap.get('passwordOne') === v.target.value) {
                                        data.setValue('checked', 'true')
                                    } else {
                                        data.setValue('checked', '')
                                    }
                                }}
                            />
                        }
                        error={
                            data.valueMap.get('passwordOne') !== data.valueMap.get('passwordTwo')
                                ? new Error('密码不一致')
                                : data.errorMap.get('passwordTwo')
                        }
                    />
                </Row>

                <VSpacer height={16} />
            </>
        )
    }

    return (
        <PopupWindow
            title={'修改密码'}
            size={'middle'}
            checked={data.checked}
            button={button}
            getContent={getContent}
            onSubmit={async () => {
                const oldPassword = sha256(data.valueMap.get('oldPassword'))
                const passwordOne = sha256(data.valueMap.get('passwordOne'))
                await App.Api.changePassword(oldPassword, passwordOne)
                onChange && onChange()
                return true
            }}
            onOpenChange={(v) => {
                if (!v) {
                    data.setValue('@all', '@init')
                }
            }}
        />
    )
}

export const ProfileModule = () => {
    const navigate = useNavigate()
    const [user, setUser] = React.useState<any>(App.User)
    const [mouseOver, setMouseOver] = React.useState(false)

    const onChange = async () => {
        await App.load(() => {}, true)
        setUser(App.User)
    }

    return (
        <VFlexContainer
            style={{
                marginLeft: 16,
                marginRight: 16,
                marginBottom: 16,
                borderRadius: 2,
                overflow: 'hidden',
                boxSizing: 'border-box',
                background: config.app.bgColorComponent,
            }}
        >
            <VSpacer height={36} />
            <Row justify={'center'} style={{ width: '100%' }}>
                <Row
                    style={{ width: 100, height: 100 }}
                    onMouseOver={() => {
                        setMouseOver(true)
                    }}
                    onMouseOut={() => {
                        setMouseOver(false)
                    }}
                >
                    <XImageUpload
                        aspect={1}
                        button={
                            <Row
                                style={{
                                    width: 100,
                                    height: 100,
                                    position: 'relative',
                                }}
                            >
                                <Image
                                    width={100}
                                    height={100}
                                    preview={false}
                                    style={{
                                        borderRadius: 100,
                                        opacity: mouseOver ? 0.3 : 1,
                                        transition: config.app.transitionAll,
                                    }}
                                    src={user.avatar ? App.Api.fileURL(user.avatar) : invalidImage}
                                />

                                <Row
                                    justify={'center'}
                                    align={'middle'}
                                    style={{
                                        width: 100,
                                        height: 100,
                                        opacity: mouseOver ? 1 : 0,
                                        transition: config.app.transitionAll,
                                        position: 'absolute',
                                    }}
                                >
                                    <i className={`iconfont icon-bianji1`} style={{ fontSize: 28, color: '#ffffff' }} />
                                </Row>
                            </Row>
                        }
                        uploadBucket={'system'}
                        onChange={async (v) => {
                            await App.Api.update('user', [App.User.id], 'avatar', v)
                            await App.load(() => {}, true)
                            setUser(App.User)
                            setMouseOver(false)
                        }}
                    />
                </Row>

                <Row justify={'center'} style={{ width: '100%', height: 48 }}>
                    <DrawerRowContainer
                        label={UserColumnPhone.column?.title}
                        width={ItemWidth}
                        height={68}
                        labelWidth={LabelWidth}
                        left={
                            <XItem
                                style={{ paddingLeft: 8, paddingRight: 8 }}
                                width={ItemWidth - LabelWidth - 8}
                                bordered={false}
                                editAreaBG={'#00000030'}
                                config={UserColumnPhone}
                                data={user}
                                onChange={onChange}
                            />
                        }
                    />
                </Row>

                <Row justify={'center'} style={{ width: '100%', height: 48 }}>
                    <DrawerRowContainer
                        label={UserColumnGender.column?.title}
                        width={ItemWidth}
                        height={68}
                        labelWidth={LabelWidth}
                        left={
                            <XItem
                                style={{ paddingLeft: 8, paddingRight: 8 }}
                                width={ItemWidth - LabelWidth - 8}
                                bordered={false}
                                editAreaBG={'#00000030'}
                                config={UserColumnGender}
                                data={user}
                                onChange={onChange}
                            />
                        }
                    />
                </Row>

                <Row justify={'center'} style={{ width: '100%', height: 48 }}>
                    <DrawerRowContainer
                        label={UserColumnEmail.column?.title}
                        width={ItemWidth}
                        height={68}
                        labelWidth={LabelWidth}
                        left={
                            <XItem
                                style={{ paddingLeft: 8, paddingRight: 8 }}
                                width={ItemWidth - LabelWidth - 8}
                                bordered={false}
                                editAreaBG={'#00000030'}
                                config={UserColumnEmail}
                                data={user}
                                onChange={onChange}
                            />
                        }
                    />
                </Row>

                <Row justify={'center'} style={{ width: '100%', height: 48 }}>
                    <DrawerRowContainer
                        label={UserColumnRealName.column?.title}
                        width={ItemWidth}
                        height={68}
                        labelWidth={LabelWidth}
                        left={
                            <XItem
                                style={{ paddingLeft: 8, paddingRight: 8 }}
                                width={ItemWidth - LabelWidth - 8}
                                bordered={false}
                                editAreaBG={'#00000030'}
                                config={UserColumnRealName}
                                data={user}
                                onChange={onChange}
                            />
                        }
                    />
                </Row>

                <Row justify={'center'} style={{ width: '100%', height: 48 }}>
                    <DrawerRowContainer
                        label={UserColumnCreateTime.column?.title}
                        width={ItemWidth}
                        height={68}
                        labelWidth={LabelWidth}
                        left={
                            <XItem
                                style={{ paddingLeft: 8, paddingRight: 8 }}
                                width={ItemWidth - LabelWidth - 8}
                                bordered={false}
                                editAreaBG={'#00000030'}
                                config={UserColumnCreateTime}
                                data={user}
                                onChange={onChange}
                            />
                        }
                    />
                </Row>

                <Row justify={'center'} style={{ width: '100%', height: 48 }}>
                    <DrawerRowContainer
                        label={''}
                        width={ItemWidth}
                        height={68}
                        labelWidth={LabelWidth}
                        left={
                            <ChangePasswordButton
                                button={<IconButton type={'text'} label="修改密码" />}
                                onChange={() => {
                                    navigate(RootRouterAppHubLogin, {
                                        replace: true,
                                    })
                                }}
                            />
                        }
                    />
                </Row>
            </Row>
        </VFlexContainer>
    )
}
