import React from 'react'
import { useNavigate } from 'react-router-dom'

export const AutoLink: React.FC<{
    path: string
}> = ({ path }) => {
    const navigate = useNavigate()

    React.useEffect(() => {
        navigate(path, { replace: true })
    }, [navigate, path])

    return <></>
}
