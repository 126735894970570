export function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export function timeNowMS(): number {
    return new Date().getTime()
}

export function utcMSToDate(timeMS: number): Date {
    let date = new Date()
    date.setTime(timeMS)
    return date
}
