import { XItemConfig, XItemContext } from '../../../components/common/Item'
import { App } from '../../../utils/app'

export const ProjectStatusColorMap = {
    working: ['#1A98B9', '制作中'],
    finished: ['#36B19B', '已完成'],
    waiting: ['#CE7D0D', '待开始'],
    paused: ['#BE404A', '已暂停'],
} as any

export const ProjectKindNameMap = {
    film: '电影',
    drama: '电视剧',
    'web-film': '网络大电影',
    'ani-film': '动画电影',
    advertise: '广告',
    short: '段视频',
} as any

export const ProjectColumnLogo: XItemConfig = {
    kind: 'image',
    key: 'logo',
    service: 'company_project',
    column: {
        title: '缩略图',
        width: 150,
    },
    editor: {
        title: '修改缩略图',
        width: 252,
        canEdit: (ctx) => {
            return ctx.data?.s_admin && App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: false,
    },
    getParams: (ctx: XItemContext) => {
        return { style: { width: 120, height: 68 }, bucketId: ctx.data?.id }
    },
}

export const ProjectColumnKind: XItemConfig = {
    kind: 'string-array',
    key: 'kind',
    service: 'company_project',
    column: {
        title: '项目类型',
        width: 150,
    },
    editor: {
        title: '修改项目类型',
        width: 252,
        canEdit: (ctx) => {
            return ctx.data?.s_admin && App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: false,
    },
    getParams: () => ({
        placeholder: '未设置',
        items: App.System.a_cfg_vfx_kind || [],
    }),
}

export const ProjectColumnLabel: XItemConfig = {
    kind: 'string',
    key: 'label',
    service: 'company_project',
    placeholder: '未设置',
    column: {
        title: '项目全称',
        width: 150,
    },
    editor: {
        title: '修改项目全称',
        width: 252,
        canEdit: (ctx) => {
            return ctx.data?.s_admin && App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: true,
    },
}

export const ProjectColumnFrameRate: XItemConfig = {
    kind: 'string-array',
    key: 'frame_rate',
    service: 'company_project',
    placeholder: '未设置',
    column: {
        title: '项目帧速率',
        width: 150,
    },
    editor: {
        title: '修改项目帧速率',
        width: 252,
        canEdit: (ctx) => {
            return ctx.data?.s_admin && App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: false,
    },
    getParams: () => ({
        placeholder: '未设置',
        items: App.System.a_cfg_vfx_frame_rate || [],
    }),
}

export const ProjectColumnResolution: XItemConfig = {
    kind: 'string-array',
    key: 'resolution',
    service: 'company_project',
    placeholder: '未设置',
    column: {
        title: '项目分辨率',
        width: 150,
    },
    editor: {
        title: '修改项目分辨率',
        width: 252,
        canEdit: (ctx) => {
            return ctx.data?.s_admin && App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: false,
    },
    getParams: () => ({
        placeholder: '未设置',
        items: App.System.a_cfg_vfx_resolution || [],
    }),
}

export const ProjectColumnAspectRatio: XItemConfig = {
    kind: 'string-array',
    key: 'aspect_ratio',
    service: 'company_project',
    placeholder: '未设置',
    column: {
        title: '项目画幅比',
        width: 150,
    },
    editor: {
        title: '修改项目画幅比',
        width: 252,
        canEdit: (ctx) => {
            return ctx.data?.s_admin && App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: false,
    },
    getParams: () => ({
        placeholder: '未设置',
        items: App.System.a_cfg_vfx_aspect_ratio || [],
    }),
}

export const ProjectColumnHandleFrameStart: XItemConfig = {
    kind: 'number',
    key: 'handle_frame_start',
    service: 'company_project',
    placeholder: '未设置',
    column: {
        title: '起始手柄帧',
        width: 150,
    },
    editor: {
        title: '修改起始手柄帧',
        width: 252,
        validate: (ctx: XItemContext, v) => {
            if (v === null) {
                return null
            } else if (v < 0) {
                return new Error('起始手柄帧必须大于等于0')
            } else {
                return null
            }
        },
        canEdit: (ctx) => {
            return ctx.data?.s_admin && App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: false,
    },
    getParams: (_: XItemContext) => {
        return { min: 0, max: 999999 }
    },
}

export const ProjectColumnHandleFrameEnd: XItemConfig = {
    kind: 'number',
    key: 'handle_frame_end',
    service: 'company_project',
    placeholder: '未设置',
    column: {
        title: '结束手柄帧',
        width: 150,
    },
    editor: {
        title: '修改结束手柄帧',
        width: 252,
        validate: (ctx: XItemContext, v) => {
            if (v === null) {
                return null
            } else if (v < 0) {
                return new Error('结束手柄帧必须大于等于0')
            } else {
                return null
            }
        },
        canEdit: (ctx) => {
            return ctx.data?.s_admin && App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: false,
    },
    getParams: (_: XItemContext) => {
        return { min: 0, max: 999999 }
    },
}

export const ProjectColumnDirector: XItemConfig = {
    kind: 'string',
    key: 'director',
    service: 'company_project',
    placeholder: '未设置',
    column: {
        title: '导演',
        width: 150,
    },
    editor: {
        title: '修改导演',
        width: 252,
        canEdit: (ctx) => {
            return ctx.data?.s_admin && App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: true,
    },
}

export const ProjectColumnProducer: XItemConfig = {
    kind: 'string',
    key: 'producer',
    service: 'company_project',
    placeholder: '未设置',
    column: {
        title: '制片人',
        width: 150,
    },
    editor: {
        title: '修改制片人',
        width: 252,
        canEdit: (ctx) => {
            return ctx.data?.s_admin && App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: true,
    },
}

export const ProjectColumnStartTime: XItemConfig = {
    kind: 'date',
    key: 'start_time',
    service: 'company_project',
    placeholder: '未设置',
    column: {
        title: '开始时间',
        width: 150,
    },
    editor: {
        title: '修改开始时间',
        width: 320,
        canEdit: (ctx) => {
            return App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: true,
    },
}

export const ProjectColumnEndTime: XItemConfig = {
    kind: 'date',
    key: 'end_time',
    service: 'company_project',
    placeholder: '未设置',
    column: {
        title: '结束时间',
        width: 150,
    },
    editor: {
        title: '修改结束时间',
        width: 320,
        canEdit: (ctx) => {
            return App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: true,
    },
}

export const ProjectColumnStatus: XItemConfig = {
    kind: '-project-status',
    key: 'status',
    service: 'company_project',
    column: {
        title: '项目状态',
        width: 150,
    },
    editor: {
        title: '修改项目状态',
        width: 252,
        addOrDelWhenSubmit: true,
        canEdit: (ctx) => {
            return App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: true,
    },
    getParams: () => {
        return { nameMap: ProjectStatusColorMap }
    },
}

export const ProjectColumnARuleShotDailiesVideo: XItemConfig = {
    kind: 'text',
    key: 'a_rule_shot_dailies_video',
    service: 'company_project',
    placeholder: '未限制',
    column: {
        title: '镜头视频命名',
        width: 150,
    },
    editor: {
        title: '修改镜头视频命名',
        width: 252,
        canEdit: (ctx) => {
            return App.authAttr('project', ctx.config.key, ctx.data)
        },
        canMultiEdit: true,
    },
    getParams: () => {
        return {
            rows: 1,
            // eslint-disable-next-line no-template-curly-in-string
            info: '样例：${ProjectName}_${ShotCode}_${Team}_${Worker}_${ShotName}_${Version}.${VideoExt}',
        }
    },
}
