import { XRender } from '../common/Item'
import * as common from './common'
import * as company from './company'

export class ItemManager {
    private static pluginMap: Map<string, XRender> = new Map()

    public static addPlugin(kind: string, plugin: XRender) {
        if (ItemManager.pluginMap.has(kind)) {
            throw new Error(`plugin ${kind} has already defined`)
        }

        ItemManager.pluginMap.set(kind, plugin)
    }

    public static getPlugin(kind: string): XRender | undefined {
        return ItemManager.pluginMap.get(kind)
    }
}

common.initialize()
company.initialize()
