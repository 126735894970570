import { LoadingOutlined } from '@ant-design/icons'
import { Checkbox, Dropdown, Pagination, Row, Space, Spin, Table } from 'antd'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { default as config, default as packageConfig } from '../../../package.json'
import { App, AppPopupChannel, AppTableCH } from '../../utils/app'
import { useElementSize, useSafeCallBack } from '../../utils/hook'
import { rangeNumber } from '../../utils/math'
import { showError } from '../../utils/message'
import { delay, timeNowMS } from '../../utils/time'
import { renderIf } from '../../utils/ui'
import { getSeed, getUUID } from '../../utils/uuid'
import { IconPopupButton, IconSecondaryButton, PrimaryButton, SecondaryButton, TextButton } from './Button'
import { VFlexContainer } from './Container'
import { XItem, XItemConfig, XItemGroupEditButton, getEditorAuthConfig, useSelection } from './Item'
import { XSearchConfig, XSearchPanel } from './Search'
import { HAutoSpacer, HSpacer } from './Space'
import { Text } from './Text'

const StyleTitleContainer = styled(Row)`
    height: 40px;
    user-select: none;
    color: ${packageConfig.app.fontColorNormal};
    transition: ${packageConfig.app.transitionAll};
    &:hover {
        color: ${packageConfig.app.fontColorStrong};
    }
`

export const StyleTableContainer = styled.div`
    flex-grow: 1;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    & .ant-table {
        background-color: transparent;
    }
    & .ant-table-header {
        overflow: visible !important;
    }
    & .ant-table-cell-row-hover {
        background-color: ${packageConfig.app.tableHoverBG} !important;
    }
    & .ant-table-thead > tr > th {
        padding: 0 !important;
        background-color: transparent !important;
        border-bottom-color: ${packageConfig.app.tableDividerColor};
    }
    & .ant-table-tbody > tr > td {
        background-color: transparent;
        border-bottom-color: ${packageConfig.app.tableDividerColor};
    }
    & .ant-table-body {
        height: 8000000px;
    }
    & .ant-table-column-sorter {
        margin: 8px;
    }
    & .ant-table-empty .ant-table-placeholder .ant-table-cell {
        background-color: transparent;
        border: 0;
    }
    & .ant-table-container::before {
        display: none;
    }
    & .ant-table-container::after {
        display: none;
    }
    & .ant-table-cell::before {
        display: none;
    }
`

const XTableView: React.FC<{
    style?: React.CSSProperties
    width: number
    height: number
    columnConfigs: Array<XItemConfig>
    dataSource: Array<any>
    loading: boolean
    search?: any
    onChange: (adFlag: boolean) => void
    onSort: (key: string, order: 'ascend' | 'descend' | undefined) => void
    onRow?: (data: any) => void
    backgroundColor?: string
}> = ({
    style,
    width,
    height,
    columnConfigs,
    dataSource,
    loading,
    search,
    onChange,
    onSort,
    onRow,
    backgroundColor,
}) => {
    const selection = useSelection()

    const totalWidth = columnConfigs.reduce((sum, v) => sum + (v.column?.width || 0), 0)

    const getColumns = () => {
        let ret: Array<any> = []
        let currentStart = 0

        for (let i = 0; i < columnConfigs.length; i++) {
            const config = columnConfigs[i]
            if (config.column?.width) {
                const popupWidth = config.editor?.width || config.column?.width - 16
                const popupRightRemains = totalWidth - currentStart - 8
                const placement = popupRightRemains > popupWidth ? 'bottomLeft' : 'bottomRight'
                ret.push({
                    key: config.key,
                    dataIndex: config.key,
                    title: (
                        <StyleTitleContainer
                            align={'middle'}
                            onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                            }}
                            onDoubleClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()

                                const idArray: Array<string> = []
                                dataSource.forEach((v) => {
                                    const [cfgCanEdit, cfgCanMultiEdit] = getEditorAuthConfig(config, {
                                        config: config,
                                        data: v,
                                        search: search,
                                        onChange: onChange,
                                        selection: selection,
                                    })

                                    const canMultiEdit = cfgCanEdit && cfgCanMultiEdit

                                    if (canMultiEdit) {
                                        idArray.push(v.id)
                                    }
                                })
                                selection.addRecords(config.key, idArray)
                            }}
                        >
                            <HSpacer left={8} />
                            {selection.getSelectSum(config.key) > 0 ? (
                                <>
                                    <XItemGroupEditButton
                                        selection={selection}
                                        config={config}
                                        search={search}
                                        onChange={(adFlag) => {
                                            selection.resetColumn(config.key)
                                            onChange(adFlag)
                                        }}
                                        placement={placement}
                                    />
                                    <HSpacer left={4} />
                                    <IconSecondaryButton
                                        size={'small'}
                                        kind={'close'}
                                        onClick={() => {
                                            selection.resetColumn(config.key)
                                        }}
                                    />
                                </>
                            ) : (
                                <Text style={{ color: 'inherit' }}>{config.column?.title}</Text>
                            )}
                        </StyleTitleContainer>
                    ),
                    width: config.column?.width,
                    sorter: config.column?.canSort ? () => undefined : undefined,
                    render: (_: any, data: any) => {
                        return (
                            <XItem
                                selection={selection}
                                config={config}
                                data={data}
                                search={search}
                                onChange={onChange}
                                placement={placement}
                            />
                        )
                    },
                })

                currentStart += config.column?.width
            }
        }

        return ret
    }

    return (
        <StyleTableContainer
            style={{
                backgroundColor: backgroundColor,
                width: width,
                height: height,
                overflowX: totalWidth > width ? 'scroll' : 'hidden',
                overflowY: 'hidden',
                ...style,
            }}
        >
            <div style={{ width: totalWidth > width ? totalWidth + 8 : width }}>
                <Table
                    scroll={{ y: height - 40 }}
                    size={'small'}
                    showSorterTooltip={false}
                    loading={loading}
                    columns={getColumns() as any}
                    pagination={false}
                    dataSource={dataSource}
                    onChange={(_, __, sorter: any, extra) => {
                        if (extra.action === 'sort') {
                            onSort(sorter.columnKey, sorter.order)
                        }
                    }}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                onRow && onRow(record)
                            },
                        }
                    }}
                />
            </div>
        </StyleTableContainer>
    )
}

export function useDataSource(
    service: string,
    pageSize: number,
    initSearch?: any,
    initOrderBy?: Array<{ key: string; order: 'ascend' | 'descend' | undefined }>,
    getIndexes?: (search?: any, orderBy?: Array<string>) => Promise<Array<{ id: string }>>,
    getDetails?: (idArray: Array<string>) => Promise<Array<{ id: string }>>
) {
    const safeCallGetIndexes = useSafeCallBack()
    const safeCallGetDetails = useSafeCallBack()
    const indexLoadingRef = React.useRef(false)
    const pageLoadingRef = React.useRef(false)
    const [loading, setLoading] = React.useState(false)
    const [search, setSearch] = React.useState({})
    const [orderBy, setOrderBy] = React.useState(
        new Map<string, { order: 'ascend' | 'descend' | undefined; time: number }>()
    )
    const [indexSeed, setIndexSeed] = React.useState(getSeed())
    const [pageSeed, setPageSeed] = React.useState(getSeed())
    const [pageIndex, setPageIndex] = React.useState(1)
    const [indexes, setIndexes] = React.useState<Array<{ id: string }>>([])
    const [dataSource, setDataSource] = React.useState<Array<{ id: string }>>([])

    React.useEffect(() => {
        console.log('useDataSource index load')
        const setIndexLoading = (v: boolean) => {
            if (v !== indexLoadingRef.current) {
                indexLoadingRef.current = v
            }
            setLoading(indexLoadingRef.current || pageLoadingRef.current)
        }

        setIndexLoading(true)
        safeCallGetIndexes((isSafe) => {
            const fnGetIndexes = getIndexes
                ? getIndexes
                : (search: any, orderBy?: Array<string>) => {
                      return App.Api.list(service, ['id'], search, orderBy)
                  }

            let orderByList = new Array<{
                key: string
                order: 'ascend' | 'descend' | undefined
                time: number
            }>()

            orderBy.forEach((item, key) => {
                orderByList.push({ key: key, order: item.order, time: item.time })
            })

            initOrderBy?.forEach((v) => {
                orderByList.push({ key: v.key, order: v.order, time: 0 })
            })

            fnGetIndexes(
                Object.assign({}, search, initSearch),
                orderByList
                    .filter((v) => !!v.order)
                    .sort((a, b) => b.time - a.time)
                    .map((v) => {
                        if (v.order === 'ascend') {
                            return v.key + ' ASC'
                        } else {
                            return v.key + ' DESC'
                        }
                    })
            )
                .then((v) => {
                    if (isSafe()) {
                        setIndexes(v)

                        const maxPageIndex = Math.ceil(v.length / pageSize)
                        if (pageIndex > maxPageIndex) {
                            setPageIndex(maxPageIndex)
                        }

                        if (v.length > 0 && pageIndex === 0) {
                            setPageIndex(1)
                        }
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
                .finally(() => {
                    delay(10).finally(() => {
                        isSafe() && setIndexLoading(false)
                    })
                })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getIndexes, service, initSearch, search, initOrderBy, orderBy, indexSeed, safeCallGetIndexes])

    React.useEffect(() => {
        // console.log('useDataSource detail load')
        const setPageLoading = (v: boolean) => {
            if (v !== pageLoadingRef.current) {
                pageLoadingRef.current = v
            }
            setLoading(indexLoadingRef.current || pageLoadingRef.current)
        }

        setPageLoading(true)
        safeCallGetDetails((isSafe) => {
            const fnGetDetails = getDetails
                ? getDetails
                : async (idArray: Array<string>) => {
                      return App.Api.details(service, idArray)
                  }

            const start = rangeNumber((pageIndex - 1) * pageSize, 0, indexes.length)
            const end = rangeNumber(pageIndex * pageSize, 0, indexes.length)

            const queryIndexes = indexes.slice(start, end).map((v) => v.id)
            fnGetDetails(queryIndexes)
                .then((list) => {
                    let sortList = []
                    for (let i = 0; i < queryIndexes.length; i++) {
                        const index = list.findIndex((v) => v.id === queryIndexes[i])
                        if (index >= 0) {
                            sortList.push(list[index])
                        }
                    }

                    isSafe() &&
                        setDataSource(
                            sortList.map((v, idx) => {
                                return Object.assign({}, v, {
                                    key: v.id,
                                    _index: (pageIndex - 1) * pageSize + 1 + idx,
                                })
                            })
                        )
                })
                .finally(() => {
                    isSafe() && setPageLoading(false)
                })
        })
    }, [service, getDetails, pageSeed, pageSize, pageIndex, indexes, safeCallGetDetails])

    return {
        dataSource: dataSource,
        loading: loading,
        indexes: indexes,
        search: search,
        setSearch: setSearch,
        setOrderBy: (attr: string, order: 'ascend' | 'descend' | undefined) => {
            const newOrderBy = new Map<string, { order: 'ascend' | 'descend' | undefined; time: number }>()
            orderBy.forEach((v, k) => {
                newOrderBy.set(k, v)
            })
            newOrderBy.set(attr, { order: order, time: timeNowMS() })
            setOrderBy(newOrderBy)
        },
        pageIndex: pageIndex,
        setPageIndex: setPageIndex,
        flushIndex: () => {
            setIndexSeed(getSeed())
        },
        flushPage: () => {
            setPageSeed(getSeed())
        },
    }
}

export const StyledPagination = styled(Pagination)`
    & .ant-pagination-item:hover {
        color: rgba(255, 255, 255, 0.85) !important;
    }
    & .ant-pagination-item > a {
        transition: ${config.app.transitionAll};
    }
    & .ant-pagination-item > a:hover {
        color: rgba(255, 255, 255, 0.85) !important;
    }
    & .ant-pagination-item-active {
        border-color: rgba(255, 255, 255, 0.6);
    }
    & .ant-pagination-item-active a {
        color: rgba(255, 255, 255, 0.85);
    }
    & .ant-pagination-item-link {
        transition: ${config.app.transitionAll};
    }
    & .ant-pagination-item-link:hover {
        color: rgba(255, 255, 255, 0.85);
    }
    & .ant-pagination-disabled .ant-pagination-item-link {
        color: rgba(255, 255, 255, 0.25);
    }
    & .ant-pagination-disabled .ant-pagination-item-link:hover {
        color: rgba(255, 255, 255, 0.25);
    }
`

const StyledDropdownDiv = styled.div`
    position: relative;
    & .ant-dropdown-menu {
        user-select: none;
        border-radius: 2px;
        box-shadow: ${config.app.boxShadowComponent};
    }
    & .ant-dropdown-menu-item:hover {
        background-color: ${config.app.dropdownItemActiveBG};
    }
`

const TableSettingButton: React.FC<{
    style?: CSSProperties
    poppedHeight: number
    columnKeys: Array<{ key: string; selected: boolean }>
    data: Array<{ key: string; value: string; selected: boolean }>
    setData: (data: Array<{ key: string; value: string; selected: boolean }>) => void
    onSubmit?: (keys: Array<{ key: string; selected: boolean }>) => Promise<void>
    onRestore: () => void
}> = ({ style, poppedHeight, columnKeys, data, setData, onSubmit, onRestore }) => {
    const [open, setOpen] = React.useState(false)
    const [uuid] = React.useState(getUUID())
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        let running = true

        const listener = App.EventHub.subscribe(AppPopupChannel, (msg, value) => {
            switch (msg) {
                case 'PopupOpen':
                    if (running && value !== uuid) {
                        setOpen(false)
                    }
            }
        })

        return () => {
            listener?.unsubscribe()
            running = false
        }
    }, [uuid])

    const onKeyUp = (key: string) => {
        const idx = data.findIndex((v) => v.key === key)
        if (idx > 0) {
            let newData = new Array<{ key: string; value: string; selected: boolean }>()

            for (let i = 0; i < idx - 1; i++) {
                const item = data[i]
                newData.push({ key: item.key, value: item.value, selected: item.selected })
            }

            newData.push({ key: data[idx].key, value: data[idx].value, selected: data[idx].selected })
            newData.push({ key: data[idx - 1].key, value: data[idx - 1].value, selected: data[idx - 1].selected })

            for (let i = idx + 1; i < data.length; i++) {
                const item = data[i]
                newData.push({ key: item.key, value: item.value, selected: item.selected })
            }

            setData(newData)
        }
    }

    const onKeyDown = (key: string) => {
        const idx = data.findIndex((v) => v.key === key)
        if (idx >= 0 && idx < data.length - 1) {
            let newData = new Array<{ key: string; value: string; selected: boolean }>()

            for (let i = 0; i < idx; i++) {
                const item = data[i]
                newData.push({ key: item.key, value: item.value, selected: item.selected })
            }

            newData.push({ key: data[idx + 1].key, value: data[idx + 1].value, selected: data[idx + 1].selected })
            newData.push({ key: data[idx].key, value: data[idx].value, selected: data[idx].selected })

            for (let i = idx + 2; i < data.length; i++) {
                const item = data[i]
                newData.push({ key: item.key, value: item.value, selected: item.selected })
            }

            setData(newData)
        }
    }

    const onKeyChange = (key: string) => {
        let newData = new Array<{ key: string; value: string; selected: boolean }>()

        for (let i = 0; i < data.length; i++) {
            const item = data[i]

            if (item.key === key) {
                newData.push({ key: item.key, value: item.value, selected: !item.selected })
            } else {
                newData.push({ key: item.key, value: item.value, selected: item.selected })
            }
        }

        setData(newData)
    }

    const onSelectAll = () => {
        let newData = new Array<{ key: string; value: string; selected: boolean }>()

        for (let i = 0; i < data.length; i++) {
            const item = data[i]
            newData.push({ key: item.key, value: item.value, selected: true })
        }

        setData(newData)
    }

    const currentKeys = data.map((v) => ({ key: v.key, selected: v.selected }))

    return (
        <StyledDropdownDiv style={style}>
            <Dropdown
                dropdownRender={() => {
                    return (
                        <div
                            style={{
                                width: 200,
                                height: Math.min(poppedHeight, 51 + 57 + 32 * data.length),
                                backgroundColor: config.app.bgColorPopCard,
                                boxShadow: config.app.boxShadowComponent,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div
                                style={{
                                    minHeight: 51,
                                    maxHeight: 51,
                                    width: '100%',
                                    padding: '18px 16px 9px 16px',
                                }}
                            >
                                <Row align={'middle'}>
                                    <Text size={'Big'} color={'Strong'}>
                                        表格设置
                                    </Text>
                                    <HAutoSpacer />
                                    <TextButton
                                        size={'small'}
                                        onClick={() => {
                                            onSelectAll()
                                        }}
                                        disabled={loading || data.filter((v) => v.selected).length === data.length}
                                    >
                                        全选
                                    </TextButton>
                                </Row>
                            </div>

                            <div style={{ flex: 1, overflowY: 'scroll' }}>
                                {data.map((v, idx) => {
                                    return (
                                        <Row
                                            key={v.key}
                                            align={'middle'}
                                            style={{ marginLeft: 16, marginRight: 8, height: 32 }}
                                        >
                                            <Checkbox
                                                disabled={loading}
                                                onChange={() => {
                                                    onKeyChange(v.key)
                                                }}
                                                checked={v.selected}
                                            >
                                                {v.value}
                                            </Checkbox>

                                            <HAutoSpacer />
                                            <IconSecondaryButton
                                                kind={'sort-up'}
                                                size={'small'}
                                                disabled={idx === 0}
                                                onClick={() => {
                                                    onKeyUp(v.key)
                                                }}
                                            />
                                            <IconSecondaryButton
                                                kind={'sort-down'}
                                                size={'small'}
                                                disabled={idx === data.length - 1}
                                                onClick={() => {
                                                    onKeyDown(v.key)
                                                }}
                                            />
                                        </Row>
                                    )
                                })}
                            </div>

                            <div style={{ minHeight: 57, maxHeight: 57 }}>
                                <HSpacer color={config.app.dividerColor} height={1} />
                                <Row style={{ padding: 16 }}>
                                    <HAutoSpacer />
                                    <SecondaryButton
                                        size={'small'}
                                        style={{ width: 48 }}
                                        disabled={loading || JSON.stringify(columnKeys) === JSON.stringify(currentKeys)}
                                        onClick={() => {
                                            onRestore()
                                        }}
                                    >
                                        还原
                                    </SecondaryButton>
                                    <HSpacer width={8} />
                                    <PrimaryButton
                                        style={{ width: 48 }}
                                        type={'primary'}
                                        size={'small'}
                                        disabled={loading || JSON.stringify(columnKeys) === JSON.stringify(currentKeys)}
                                        loading={loading}
                                        onClick={() => {
                                            if (!loading && onSubmit) {
                                                setLoading(true)
                                                onSubmit(currentKeys)
                                                    .then(() => {
                                                        setOpen(false)
                                                    })
                                                    .catch((e) => {
                                                        showError('保存列信息失败 ' + e.message).finally()
                                                    })
                                                    .finally(() => {
                                                        setLoading(false)
                                                    })
                                            }
                                        }}
                                    >
                                        {loading ? '' : '提交'}
                                    </PrimaryButton>
                                </Row>
                            </div>
                        </div>
                    )
                }}
                trigger={['click']}
                open={open}
                placement={'bottomRight'}
                onOpenChange={(v) => {
                    if (v) {
                        App.EventHub.send(AppPopupChannel, 'PopupOpen', uuid)
                    }
                    setOpen(v)
                }}
                getPopupContainer={(trigger: any) => trigger.parentElement}
            >
                <IconPopupButton icon={'icon-shezhi1'} popped={open} fontSize={14} width={24} height={24} />
            </Dropdown>
        </StyledDropdownDiv>
    )
}

const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />

const TableStatButton: React.FC<{
    search: any
    numOfItems: number
    onLoad: (search: any) => Promise<Array<{ value: number; percentage?: number; name: string }>>
}> = ({ numOfItems, onLoad, search }) => {
    const [open, setOpen] = React.useState(false)
    const [uuid] = React.useState(getUUID())
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState<Array<{ value: number; percentage?: number; name: string }>>([])

    React.useEffect(() => {
        let running = true

        const listener = App.EventHub.subscribe(AppPopupChannel, (msg, value) => {
            switch (msg) {
                case 'PopupOpen':
                    if (running && value !== uuid) {
                        setOpen(false)
                    }
            }
        })

        return () => {
            listener?.unsubscribe()
            running = false
        }
    }, [uuid])

    return (
        <StyledDropdownDiv>
            <Dropdown
                dropdownRender={() => {
                    return (
                        <Row
                            style={{
                                width: 140 * numOfItems,
                                height: 78,
                                backgroundColor: '#191B1E',
                                boxShadow: config.app.boxShadowComponent,
                            }}
                        >
                            {renderIf(
                                loading,
                                <Row style={{ width: '100%', height: '100%' }} align={'middle'} justify={'center'}>
                                    <Spin indicator={antIcon} />
                                    <HSpacer width={8} />
                                    <Text color={'Weak'}>数据统计中...</Text>
                                </Row>,
                                <Row style={{ width: '100%', height: '100%' }} align={'middle'} justify={'center'}>
                                    {data.map((v, idx) => {
                                        return (
                                            <Row key={idx} style={{ width: 140, height: 78 }} align={'middle'}>
                                                <HSpacer
                                                    color={config.app.dividerColor}
                                                    height={48}
                                                    width={1}
                                                    left={16}
                                                    right={16}
                                                />

                                                <Row style={{ height: 48, width: 107, top: -2 }}>
                                                    <Row style={{ width: '100%', height: 28 }} align={'middle'}>
                                                        <Text
                                                            size={'Bigger'}
                                                            color={'Strong'}
                                                            style={{ lineHeight: '28px' }}
                                                        >
                                                            {v.value}
                                                        </Text>

                                                        {renderIf(
                                                            v.percentage !== undefined,
                                                            <Row
                                                                align={'middle'}
                                                                justify={'center'}
                                                                style={{
                                                                    marginLeft: 8,
                                                                    width: 42,
                                                                    height: 22,
                                                                    borderRadius: 2,
                                                                    lineHeight: '22px',
                                                                    fontSize: 12,
                                                                    border: 'solid 1px rgba(255,255,255,0.2)',
                                                                }}
                                                            >
                                                                {v.percentage + '%'}
                                                            </Row>
                                                        )}
                                                    </Row>
                                                    <Row style={{ width: '100%', height: 20 }} align={'middle'}>
                                                        <Text size={'Small'} color={'Weak'}>
                                                            {v.name}
                                                        </Text>
                                                    </Row>
                                                </Row>
                                            </Row>
                                        )
                                    })}
                                </Row>
                            )}
                        </Row>
                    )
                }}
                trigger={['click']}
                open={open}
                placement={'bottomRight'}
                onOpenChange={(v) => {
                    if (v) {
                        App.EventHub.send(AppPopupChannel, 'PopupOpen', uuid)

                        if (!loading && onLoad) {
                            setLoading(true)
                            onLoad(search)
                                .then((v) => {
                                    setData(v)
                                })
                                .catch((e) => {
                                    showError('加载统计数据失败 ' + e.message).finally()
                                })
                                .finally(() => {
                                    setLoading(false)
                                })
                        }
                    }
                    setOpen(v)
                }}
                getPopupContainer={(trigger: any) => trigger.parentElement}
            >
                <IconPopupButton
                    icon={'icon-tongji'}
                    disabled={loading}
                    popped={open}
                    fontSize={14}
                    width={24}
                    height={24}
                />
            </Dropdown>
        </StyledDropdownDiv>
    )
}

export const XTable: React.FC<{
    service: string
    pageSize: number
    uuid: string
    itemMode?: boolean
    renderView?: (data: any) => React.ReactNode
    initSearch?: any
    defaultSearch?: any
    initOrderBy?: Array<{ key: string; order: 'ascend' | 'descend' | undefined }>
    columnConfigs: Array<XItemConfig>
    searchConfigs?: Array<XSearchConfig>
    searchClearSeed?: number
    onReload?: () => void
    columnKeys?: Array<{ key: string; selected: boolean }>
    onSaveColumnKeys?: (data: Array<{ key: string; selected: boolean }>) => Promise<void>
    onRow?: (data: any) => void
    bar?: React.ReactNode
    numOfStatItems?: number
    onStatLoad?: (search: any) => Promise<Array<{ value: number; percentage?: number; name: string }>>
    tableBG?: string
    hideBar?: boolean
}> = ({
    service,
    uuid,
    pageSize,
    itemMode,
    renderView,
    initSearch,
    defaultSearch,
    initOrderBy,
    columnConfigs,
    columnKeys,
    searchConfigs,
    searchClearSeed,
    onReload,
    onSaveColumnKeys,
    onRow,
    bar,
    numOfStatItems,
    onStatLoad,
    tableBG,
    hideBar,
}) => {
    const dataMgr = useDataSource(service, pageSize, initSearch, initOrderBy)
    const [customKeys, setCustomKeys] = React.useState(new Array<{ key: string; value: string; selected: boolean }>())
    const [elemRef, { width, height }] = useElementSize()

    const fnInitCustomKeys = React.useCallback(() => {
        const sortKeys = columnKeys?.map((v) => v.key) || []
        let ret = new Array<{ key: string; value: string; selected: boolean }>()
        for (let i = 0; i < columnConfigs.length; i++) {
            const config = columnConfigs[i]
            if (config.key && config.column && config.column.title) {
                ret.push({
                    key: config.key,
                    value: config.column.title,
                    selected: columnKeys?.find((v) => v.key === config.key)?.selected || false,
                })
            }
        }
        return ret.sort((a, b) => sortKeys.indexOf(a.key) - sortKeys.indexOf(b.key))
    }, [columnConfigs, columnKeys])

    React.useEffect(() => {
        setCustomKeys(fnInitCustomKeys())
    }, [fnInitCustomKeys])

    React.useEffect(() => {
        dataMgr.setSearch(defaultSearch || {})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSearch, searchConfigs, searchClearSeed])

    React.useEffect(() => {
        let running = true

        const listener = App.EventHub.subscribe(AppTableCH, (msg, value) => {
            switch (msg) {
                case 'FlushIndex':
                    if (running && value === uuid) {
                        dataMgr.flushIndex()
                        onReload && onReload()
                    }
                    break
                case 'FlushPage':
                    if (running && value === uuid) {
                        dataMgr.flushPage()
                        onReload && onReload()
                    }
                    break
            }
        })

        return () => {
            listener?.unsubscribe()
            running = false
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid])

    const filterKeys = customKeys.map((v) => (v.selected ? v.key : ''))

    const tableConfigs = columnKeys
        ? columnConfigs
              .filter((v) => filterKeys.indexOf(v.key) >= 0)
              .sort((a, b) => filterKeys.indexOf(a.key) - filterKeys.indexOf(b.key))
        : columnConfigs

    return (
        <VFlexContainer>
            {searchConfigs ? (
                <XSearchPanel
                    search={dataMgr.search}
                    onSearchChange={dataMgr.setSearch}
                    searchConfigs={searchConfigs}
                />
            ) : null}

            {hideBar ? null : (
                <Row align={'middle'} style={{ height: 60 }}>
                    {bar}
                    <HAutoSpacer />
                    {pageSize ? (
                        <StyledPagination
                            size={'small'}
                            onChange={(page) => {
                                dataMgr.setPageIndex(page)
                            }}
                            total={dataMgr.indexes.length}
                            showTotal={(total, range) => (
                                <span
                                    style={{
                                        color: config.app.fontColorWeak,
                                        userSelect: 'none',
                                    }}
                                >{`第${range[0]}-${range[1]}项  共${total}项`}</span>
                            )}
                            current={dataMgr.pageIndex}
                            pageSize={pageSize}
                            showSizeChanger={false}
                        />
                    ) : null}

                    <Space size={'small'} style={{ marginLeft: 8 }}>
                        <IconPopupButton
                            icon={'icon-shuaxin'}
                            fontSize={14}
                            width={24}
                            height={24}
                            disabled={dataMgr.loading}
                            onClick={() => {
                                dataMgr.flushIndex()
                                onReload && onReload()
                            }}
                        />
                        {columnKeys && onSaveColumnKeys ? (
                            <TableSettingButton
                                poppedHeight={height + 10}
                                data={customKeys}
                                columnKeys={columnKeys}
                                setData={setCustomKeys}
                                onRestore={() => {
                                    setCustomKeys(fnInitCustomKeys())
                                }}
                                onSubmit={onSaveColumnKeys}
                            />
                        ) : null}

                        {numOfStatItems && onStatLoad ? (
                            <TableStatButton
                                numOfItems={numOfStatItems}
                                onLoad={onStatLoad}
                                search={Object.assign({}, dataMgr.search, initSearch)}
                            />
                        ) : null}
                    </Space>
                </Row>
            )}

            <div
                ref={elemRef}
                style={{
                    flex: 1,
                    backgroundColor: itemMode && renderView ? 'transparent' : config.app.bgColorComponent,
                }}
            >
                {itemMode && renderView ? (
                    <div
                        style={{
                            position: 'absolute',
                            width: width,
                            height: height,
                            overflowX: 'hidden',
                            overflowY: 'auto',
                        }}
                    >
                        {renderView(dataMgr.dataSource)}
                    </div>
                ) : (
                    <XTableView
                        backgroundColor={tableBG}
                        style={{ position: 'absolute' }}
                        width={width}
                        height={height}
                        columnConfigs={tableConfigs}
                        dataSource={dataMgr.dataSource}
                        loading={dataMgr.loading}
                        search={Object.assign({}, dataMgr.search, initSearch)}
                        onChange={(adFlag: boolean) => {
                            if (adFlag) {
                                dataMgr.flushIndex()
                                onReload && onReload()
                            } else {
                                dataMgr.flushPage()
                                onReload && onReload()
                            }
                        }}
                        onSort={(key: string, order: 'ascend' | 'descend' | undefined) => {
                            dataMgr.setOrderBy(key, order)
                        }}
                        onRow={onRow}
                    />
                )}
            </div>
        </VFlexContainer>
    )
}
