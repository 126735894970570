import React from 'react'
import config from '../../../package.json'
import packageConfig from '../../../package.json'

type fontSizeKind = 'Biggest' | 'Bigger' | 'Big' | 'Normal' | 'Small' | 'Smaller'
type fontColorKind = 'Stronger' | 'Strong' | 'Normal' | 'Weak' | 'Weaker' | 'Primary'
type fontWeightKind = 'Bolder' | 'Bold' | 'Normal' | 'Lighter'

function getFontSize(size?: fontSizeKind): string {
    switch (size) {
        case 'Biggest':
            return config.app.fontSizeBiggest
        case 'Bigger':
            return config.app.fontSizeBigger
        case 'Big':
            return config.app.fontSizeBig
        case 'Normal':
            return config.app.fontSizeNormal
        case 'Small':
            return config.app.fontSizeSmall
        case 'Smaller':
            return config.app.fontSizeSmaller
        default:
            return config.app.fontSizeNormal
    }
}

function getFontColor(color?: fontColorKind): string {
    switch (color) {
        case 'Primary':
            return config.app.primaryColor
        case 'Stronger':
            return config.app.fontColorStronger
        case 'Strong':
            return config.app.fontColorStrong
        case 'Normal':
            return config.app.fontColorNormal
        case 'Weak':
            return config.app.fontColorWeak
        case 'Weaker':
            return config.app.fontColorWeaker
        default:
            return config.app.fontColorNormal
    }
}

function getFontWeight(weight?: fontWeightKind) {
    switch (weight) {
        case 'Bolder':
            return config.app.fontWeightBolder
        case 'Bold':
            return config.app.fontWeightBold
        case 'Normal':
            return config.app.fontWeightNormal
        case 'Lighter':
            return config.app.fontWeightLighter
        default:
            return config.app.fontWeightNormal
    }
}

export const Text: React.FC<{
    size?: fontSizeKind
    color?: fontColorKind
    weight?: fontWeightKind
    style?: React.CSSProperties
    dir?: 'rtl' | 'ltr'
    children?: React.ReactNode
}> = ({ size, color, weight, style, dir, children }) => {
    return (
        <div
            style={{
                display: 'inline-block',
                userSelect: 'none',
                wordBreak: 'break-all',
                fontSize: getFontSize(size),
                color: getFontColor(color),
                fontWeight: getFontWeight(weight),
                ...style,
            }}
            dir={dir || 'ltr'}
        >
            {children}
        </div>
    )
}

export const TextWithEllipsis: React.FC<{
    width?: number | string
    size?: fontSizeKind
    color?: fontColorKind
    weight?: fontWeightKind
    style?: React.CSSProperties
    dir?: 'rtl' | 'ltr'
    children?: React.ReactNode
}> = ({ width, size, color, weight, style, dir, children }) => {
    return (
        <Text
            style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: width,
                ...style,
            }}
            size={size}
            color={color}
            weight={weight}
            dir={dir}
            children={children}
        />
    )
}

export const ErrorText: React.FC<{ error: Error | null }> = ({ error }) => {
    return error ? (
        <Text
            style={{
                marginTop: 6,
                height: 14,
                lineHeight: '14px',
                width: '100%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                color: packageConfig.app.inputBorderErrorColor,
            }}
            size={'Small'}
        >
            {error?.message}
        </Text>
    ) : null
}
