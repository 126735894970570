import React from 'react'
import { Text } from './Text'
import { Row } from 'antd'

export const Logo: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
    return (
        <Row style={style}>
            <Text size="Biggest" color="Stronger" weight={'Bold'}>
                Mandrill
            </Text>
            <Text size="Biggest" color="Primary" weight={'Bold'} style={{ marginLeft: 8 }}>
                VFX
            </Text>
        </Row>
    )
}
