import React from 'react'
import { Row, Select } from 'antd'
import { Loading } from './Loading'
import styled from 'styled-components'
import config from '../../../package.json'
import { useSafeCallBack } from '../../utils/hook'

export function GetSelectFilter(
    groupColumn: string,
    itemColumn: string
): (input: string, v: { group: any; item: any }) => boolean {
    return (input: string, v: { group: any; item: any }) => {
        if (v.group && v.group[groupColumn] && v.group[groupColumn].toLowerCase().indexOf(input.toLowerCase()) >= 0) {
            return true
        } else {
            return !!(
                v.item &&
                v.item[itemColumn] &&
                v.item[itemColumn].toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        }
    }
}

function itemToNode(
    groupKey: string,
    columnId: string,
    itemRender: (v: any) => React.ReactNode,
    v?: any
): React.ReactNode {
    if (v) {
        return (
            <Select.Option key={groupKey + ':' + v[columnId]} value={v[columnId]}>
                {itemRender(v)}
            </Select.Option>
        )
    } else {
        return null
    }
}

function groupToNode(group: any, columnId: string, itemRender: (v: any) => React.ReactNode): React.ReactNode {
    return (
        <Select.OptGroup key={group.id} label={group.name}>
            {group.children.map((item: any) => itemToNode(group.id, columnId, itemRender, item))}
        </Select.OptGroup>
    )
}

export const StyledSelect = styled(Select)`
    & .ant-select-item-group {
        color: ${config.app.fontColorWeaker} !important;
    }
    & .ant-select-item-option {
        color: ${config.app.fontColorStrong} !important;
    }
    & .ant-select-item-option-active {
        color: ${config.app.fontColorStrong} !important;
        background-color: ${config.app.dropdownItemActiveBG} !important;
    }
    & .ant-select-item-option-selected {
        color: ${config.app.primaryColor} !important;
        background-color: transparent;
    }
`

export const XSelectNormal: React.FC<{
    size?: 'small' | 'middle'
    mode?: 'multiple' | 'tags'
    style?: React.CSSProperties
    initData?: any[]
    value?: any | any[]
    open?: boolean
    placeholder?: string
    disabled?: boolean
    bordered?: boolean
    columnId?: string
    allowClear?: boolean
    onFilter?: (input: string, v: { group: any; item: any }) => boolean
    itemRender: (v: any) => React.ReactNode
    onLoad?: () => Promise<any[]>
    getPopupContainer?: any
    onChange: (v: any | any[] | undefined) => void
}> = ({
    size,
    mode,
    style,
    allowClear,
    value,
    open,
    initData,
    placeholder,
    disabled,
    bordered,
    columnId,
    onFilter,
    itemRender,
    onLoad,
    getPopupContainer,
    onChange,
}) => {
    const safeCallBack = useSafeCallBack()
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState<any[]>(initData ? initData : [])
    const [openCount, setOpenCount] = React.useState(0)

    React.useEffect(() => {
        if ((openCount > 0 || open) && onLoad) {
            setData([])
            setLoading(true)

            safeCallBack((isSafe) => {
                onLoad()
                    .then((v) => {
                        isSafe() && setData(v)
                    })
                    .finally(() => {
                        isSafe() && setLoading(false)
                    })
            })
        }
    }, [onLoad, open, openCount, safeCallBack])

    const findItemById = (id: string) => {
        for (let i = 0; i < data.length; i++) {
            const v = data[i]

            if (v && v.children) {
                for (let j = 0; j < v.children.length; j++) {
                    if (v.children[j][columnId || 'id'] === id) {
                        return { group: v, item: v.children[j] }
                    }
                }
            } else {
                if (v[columnId || 'id'] === id) {
                    return { group: null, item: v }
                }
            }
        }

        return { group: null, item: null }
    }

    return (
        <StyledSelect
            size={size}
            mode={mode}
            showArrow={true}
            dropdownAlign={{
                offset: [0, 0],
                overflow: {
                    adjustX: false,
                    adjustY: false,
                },
            }}
            maxTagCount={'responsive'}
            allowClear={allowClear}
            showSearch={true}
            open={open}
            style={{ width: 200, ...style }}
            value={value}
            disabled={disabled}
            bordered={bordered}
            placeholder={placeholder}
            dropdownRender={(menu) => {
                if (loading) {
                    return (
                        <Row style={{ height: 80 }} align={'middle'} justify={'center'}>
                            <Loading size="small" text="加载中 ..." />
                        </Row>
                    )
                } else {
                    return menu
                }
            }}
            filterOption={(input, option) => {
                if (option && option.value && onFilter) {
                    return onFilter(input, findItemById(option.value as string))
                }
                return false
            }}
            onDropdownVisibleChange={(open) => {
                if (open) {
                    setOpenCount((v) => v + 1)
                }
            }}
            onChange={(v: any) => {
                onChange(v)
            }}
            getPopupContainer={(trigger: any) => {
                if (getPopupContainer) {
                    return getPopupContainer()
                } else {
                    return trigger.parentElement
                }
            }}
        >
            {data.map((v: any) => {
                if (v && v.children) {
                    return groupToNode(v, columnId || 'id', itemRender)
                } else {
                    return itemToNode('', columnId || 'id', itemRender, v)
                }
            })}
        </StyledSelect>
    )
}

const PopupContainerDiv = styled.div`
    &.popup-container {
        width: auto;
        display: inline-block;
    }

    &.popup-container > div {
        position: unset !important;
        top: unset !important;
        left: unset !important;
        width: unset !important;
    }

    & .ant-select-dropdown {
        position: static !important;
        overflow: hidden !important;
        box-shadow: none;
    }
`

const XEmbeddedSelect: React.FC<{
    size?: 'small' | 'middle'
    mode?: 'multiple' | 'tags'
    style?: React.CSSProperties
    allowClear?: boolean
    initData?: any[]
    value?: any | any[]
    placeholder?: string
    disabled?: boolean
    bordered?: boolean
    columnId?: string
    onFilter?: (input: string, v: { group: any; item: any }) => boolean
    itemRender: (v: any) => React.ReactNode
    onLoad?: () => Promise<any[]>
    onChange: (v: any | any[] | undefined) => void
}> = ({
    size,
    mode,
    style,
    allowClear,
    value,
    initData,
    placeholder,
    disabled,
    bordered,
    columnId,
    onFilter,
    itemRender,
    onLoad,
    onChange,
}) => {
    const [open, setOpen] = React.useState(false)
    const [containerElem, setContainerElem] = React.useState(null)

    React.useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 20)
    }, [])

    return (
        <Row>
            {containerElem ? (
                <XSelectNormal
                    size={size}
                    mode={mode}
                    style={style}
                    allowClear={allowClear}
                    value={value}
                    initData={initData}
                    placeholder={placeholder}
                    disabled={disabled}
                    bordered={bordered}
                    columnId={columnId}
                    onFilter={onFilter}
                    itemRender={itemRender}
                    onLoad={onLoad}
                    open={open}
                    onChange={onChange}
                    getPopupContainer={(_: any) => {
                        return containerElem
                    }}
                />
            ) : null}

            <PopupContainerDiv
                className={'popup-container'}
                ref={(ref) => {
                    setContainerElem(ref as any)
                }}
                style={{ position: 'relative' }}
            ></PopupContainerDiv>
        </Row>
    )
}

export const XSelect: React.FC<{
    size?: 'small' | 'middle'
    embedded?: boolean
    mode?: 'multiple' | 'tags'
    allowClear?: boolean
    style?: React.CSSProperties
    initData?: any[]
    value?: any | any[]
    placeholder?: string
    disabled?: boolean
    bordered?: boolean
    columnId?: string
    onFilter?: (input: string, v: { group: any; item: any }) => boolean
    itemRender: (v: any) => React.ReactNode
    onLoad?: () => Promise<any[]>
    onChange: (v: any | any[] | undefined) => void
}> = ({
    size,
    embedded,
    mode,
    allowClear,
    style,
    value,
    initData,
    placeholder,
    disabled,
    bordered,
    columnId,
    onFilter,
    itemRender,
    onLoad,
    onChange,
}) => {
    return embedded ? (
        <XEmbeddedSelect
            size={size}
            mode={mode}
            style={style}
            allowClear={allowClear}
            value={value}
            initData={initData}
            placeholder={placeholder}
            disabled={disabled}
            bordered={bordered}
            columnId={columnId}
            onFilter={onFilter}
            itemRender={itemRender}
            onLoad={onLoad}
            onChange={onChange}
        />
    ) : (
        <XSelectNormal
            size={size}
            mode={mode}
            style={style}
            allowClear={allowClear}
            value={value}
            initData={initData}
            placeholder={placeholder}
            disabled={disabled}
            bordered={bordered}
            columnId={columnId}
            onFilter={onFilter}
            itemRender={itemRender}
            onLoad={onLoad}
            getPopupContainer={() => {
                return document.body
            }}
            onChange={onChange}
        />
    )
}

export const StatusSelect: React.FC<{
    items: any[]
    embedded?: boolean
    style?: React.CSSProperties
    placeholder?: string
    value?: string
    mode?: 'multiple' | 'tags'
    disabled?: boolean
    bordered?: boolean
    onChange: (v: any | undefined) => void
}> = ({ items, mode, embedded, style, placeholder, value, disabled, bordered, onChange }) => {
    return (
        <XSelect
            embedded={embedded}
            style={style}
            onLoad={async () => {
                return items.map((v) => {
                    return { id: v[0], name: v[1], color: v[2] }
                })
            }}
            initData={items.map((v) => {
                return { id: v[0], name: v[1], color: v[2] }
            })}
            mode={mode}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            bordered={bordered}
            itemRender={(v) => {
                return (
                    <Row align={'middle'}>
                        {v.color ? (
                            <span
                                style={{ width: 8, height: 8, background: v.color, borderRadius: 4, marginRight: 6 }}
                            />
                        ) : null}
                        <span> {v.name}</span>
                    </Row>
                )
            }}
            onChange={(v) => {
                onChange(v)
            }}
            onFilter={GetSelectFilter('name', 'name')}
        />
    )
}

export const NotImplementSelect: React.FC<{
    embedded?: boolean
    style?: React.CSSProperties
    placeholder?: string
    value?: string
    mode?: 'multiple' | 'tags'
    disabled?: boolean
    bordered?: boolean
    onChange?: (v: any | undefined) => void
}> = ({ mode, embedded, style, placeholder, value, disabled, bordered, onChange }) => {
    return (
        <XSelect
            embedded={embedded}
            style={style}
            onLoad={async () => {
                return []
            }}
            mode={mode}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            bordered={bordered}
            itemRender={(v) => {
                return v.name
            }}
            onChange={(v) => {
                onChange && onChange(v)
            }}
            onFilter={GetSelectFilter('name', 'name')}
        />
    )
}

export const BoolSelect: React.FC<{
    embedded?: boolean
    style?: React.CSSProperties
    allowClear?: boolean
    placeholder?: string
    value?: string
    disabled?: boolean
    bordered?: boolean
    onChange: (v: any | undefined) => void
}> = ({ embedded, style, allowClear, placeholder, value, disabled, bordered, onChange }) => {
    return (
        <XSelect
            embedded={embedded}
            style={style}
            onLoad={async () => {
                return [
                    { id: true, name: '是' },
                    { id: false, name: '否' },
                ]
            }}
            value={value}
            allowClear={allowClear}
            placeholder={placeholder}
            disabled={disabled}
            bordered={bordered}
            itemRender={(v) => {
                return v.name
            }}
            onChange={(v) => {
                onChange(v)
            }}
            onFilter={GetSelectFilter('name', 'name')}
        />
    )
}
