import { message } from 'antd'

export async function showSuccess(v: any) {
    await message.success(v, 3)
}

export async function showError(v: any) {
    if (v instanceof Error) {
        let str = (v as Error).message
        if (str.trimEnd().endsWith('Failed to fetch')) {
            str = '网络故障，无法连接到服务器。'
        }
        await message.error(str, 3)
    } else if (v instanceof String) {
        await message.error(v, 3)
    } else {
        await message.error(v.toString(), 3)
    }
}
