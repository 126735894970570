import styled from 'styled-components'
import { Button, Col, Row } from 'antd'
import React from 'react'
import { useAutoLoad } from '../../utils/hook'
import { Loading } from './Loading'
import { ReloadOutlined } from '@ant-design/icons'
import bgImage from '../../assets/common/bg.png'
import { timeNowMS } from '../../utils/time'
import { Text } from './Text'

const StyledPage = styled(Row)`
    width: 100vw;
    height: 100vh;
    user-select: none;
`

export const Page: React.FC<{
    pageRef?: any
    style?: React.CSSProperties
    align?: 'top' | 'middle' | 'bottom' | 'stretch'
    justify?: 'center' | 'start' | 'end' | 'space-around' | 'space-between' | 'space-evenly'
    children?: React.ReactNode
}> = ({ pageRef, style, align, justify, children }) => {
    return (
        <StyledPage
            ref={pageRef}
            align={align}
            justify={justify}
            style={{
                ...style,
                width: '100vw',
                height: '100vh',
            }}
        >
            {children}
        </StyledPage>
    )
}

export const AutoPage: React.FC<{
    entry?: React.ReactNode
    imageBG?: boolean
    center?: boolean
}> = ({ entry, imageBG, center }) => {
    const status = useAutoLoad()
    const style = imageBG
        ? {
              background: `url(${bgImage}) no-repeat center center fixed`,
              backgroundSize: 'cover',
          }
        : {}

    if (status === 'loading') {
        return (
            <Page align="middle" justify="center" style={style}>
                <Loading size="big" text="" />
                <Text color={'Weaker'}>加载中 ...</Text>
            </Page>
        )
    } else if (status === 'loaded') {
        if (center) {
            return (
                <Page align="middle" justify="center" style={style}>
                    {entry}
                </Page>
            )
        } else {
            return (
                <Page key={timeNowMS()} style={style}>
                    {entry}
                </Page>
            )
        }
    } else if (status === 'error') {
        return (
            <Page align="middle" justify="center" style={style}>
                <Col>
                    <Row align="middle" justify="center">
                        加载失败，请刷新页面
                    </Row>
                    <Row align="middle" justify="center" style={{ marginTop: 20 }}>
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={() => {
                                window.location.reload()
                            }}
                        >
                            刷新
                        </Button>
                    </Row>
                </Col>
            </Page>
        )
    } else {
        return (
            <Page align="middle" justify="center" style={style}>
                <Row>error status ${status}</Row>
            </Page>
        )
    }
}
