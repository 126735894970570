import React from 'react'

export const HAutoSpacer: React.FC = () => {
    return <div style={{ flexGrow: 1 }} />
}

export const HSpacer: React.FC<{
    width?: number
    height?: number
    left?: number
    right?: number
    color?: string
}> = ({ width, height, left, right, color }) => {
    return (
        <div
            style={{
                width: width,
                height: height,
                marginLeft: left,
                marginRight: right,
                backgroundColor: color,
                flexShrink: 0,
                flexGrow: 0,
            }}
        />
    )
}

export const VSpacer: React.FC<{
    width?: number
    height?: number
    top?: number
    left?: number
    right?: number
    bottom?: number
    color?: string
}> = ({ width, height, left, right, top, bottom, color }) => {
    return (
        <div
            style={{
                width: width,
                height: height,
                marginTop: top,
                marginBottom: bottom,
                marginLeft: left,
                marginRight: right,
                backgroundColor: color,
                flexShrink: 0,
                flexGrow: 0,
            }}
        />
    )
}
