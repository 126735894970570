import React from 'react'
import { Button, Row, Tooltip } from 'antd'
import {
    DownOutlined,
    UpOutlined,
    LeftOutlined,
    RightOutlined,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    MinusOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    PlusOutlined,
    SearchOutlined,
    EditOutlined,
    DownloadOutlined,
    UploadOutlined,
    ExportOutlined,
    ImportOutlined,
    QuestionOutlined,
    BellOutlined,
    ReloadOutlined,
    FundOutlined,
    SettingOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'

import config from '../../../package.json'
import { FloatBox, FloatContainer } from './Container'
import { ButtonProps } from 'antd/lib/button/button'

export function getIcon(kind: string): React.ReactNode {
    switch (kind) {
        case 'down':
            return <DownOutlined />
        case 'up':
            return <UpOutlined />
        case 'left':
            return <LeftOutlined />
        case 'right':
            return <RightOutlined />
        case 'sort-down':
            return <ArrowDownOutlined />
        case 'sort-up':
            return <ArrowUpOutlined />
        case 'search':
            return <SearchOutlined />
        case 'delete':
            return <DeleteOutlined />
        case 'plus':
            return <PlusOutlined />
        case 'minus':
            return <MinusOutlined />
        case 'edit':
            return <EditOutlined />
        case 'close':
            return <CloseOutlined />
        case 'check':
            return <CheckOutlined />
        case 'pause':
            return <PauseCircleOutlined />
        case 'play':
            return <PlayCircleOutlined />
        case 'download':
            return <DownloadOutlined />
        case 'upload':
            return <UploadOutlined />
        case 'export':
            return <ExportOutlined />
        case 'import':
            return <ImportOutlined />
        case 'bell':
            return <BellOutlined />
        case 'expand':
            return <DownOutlined />
        case 'fold':
            return <UpOutlined />
        case 'fold-all':
            return <UpOutlined />
        case 'table-reload':
            return <ReloadOutlined />
        case 'table-status':
            return <FundOutlined />
        case 'table-setting':
            return <SettingOutlined />
        default:
            return <QuestionOutlined />
    }
}

const StyledIconOnly = styled(Button)`
    color: inherit;
    background-color: inherit;
    &:hover {
        color: inherit;
        background-color: inherit;
    }
    &:active {
        color: inherit;
        background-color: inherit;
    }
`

const StyledIconSecondary = styled(Button)`
    color: ${config.app.fontColorNormal};
    background-color: transparent;
    &:hover {
        color: ${config.app.fontColorNormal};
        background-color: rgba(255, 255, 255, 0.05);
    }
    &:active {
        color: ${config.app.fontColorNormal};
        background-color: transparent;
    }
    &.mdp-btn-disabled {
        color: ${config.app.fontColorWeaker};
        background-color: transparent;
    }
    &.mdp-btn-popped {
        color: ${config.app.primaryColor};
    }
`

const StyledIconMessage = styled(Button)`
    color: ${config.app.fontColorNormal};
    &:hover {
        color: ${config.app.primaryColor};
        background-color: rgba(255, 255, 255, 0.05);
    }
    &:active {
        color: ${config.app.primaryColor};
        background-color: transparent;
    }
    &.mdp-btn-popped {
        color: ${config.app.primaryColor};
    }
`

const getButton = function (
    kind: string,
    status?: string,
    size?: 'small' | 'middle' | 'large',
    theme?: string,
    onClick?: React.MouseEventHandler<HTMLElement>
): React.ReactNode {
    const props = {
        className: status ? `mdp-btn mdp-btn-${status}` : 'mdp-btn',
        icon: getIcon(kind),
        size: size,
        onClick: (e: any) => {
            onClick && onClick(e)
        },
        onMouseDown: (e: any) => {
            // prevent focus
            e.preventDefault()
            e.stopPropagation()
        },
    }

    switch (theme) {
        case 'icon-only':
            return <StyledIconOnly type="link" {...props} />
        case 'icon-message':
            return <StyledIconMessage type="link" {...props} />
        case 'icon-secondary':
            return <StyledIconSecondary type="link" {...props} />
        default:
            return <Button {...props} />
    }
}

export const IconOnlyButton: React.FC<{
    kind: string
    size?: 'small' | 'middle' | 'large'
    onClick?: React.MouseEventHandler<HTMLElement>
}> = ({ kind, size, onClick }) => {
    return <>{getButton(kind, '', size || 'middle', 'icon-only', onClick)}</>
}

export const IconSecondaryButton: React.FC<{
    kind: string
    size: 'small' | 'middle' | 'large'
    tooltip?: string
    disabled?: boolean
    popped?: boolean
    onClick?: React.MouseEventHandler<HTMLElement>
}> = ({ kind, size, tooltip, disabled, popped, onClick }) => {
    const button = getButton(
        kind,
        disabled ? 'disabled' : popped ? 'popped' : '',
        size,
        'icon-secondary',
        disabled ? undefined : onClick
    )
    return tooltip ? (
        <Tooltip title={tooltip} color={config.app.tooltipBG}>
            {button}
        </Tooltip>
    ) : (
        <>{button}</>
    )
}

// TODO: 小红点 Hover 问题，需要UI分别出两个图
export const IconMessageButton: React.FC<{
    onPopup: () => Promise<void>
    remains?: number
}> = ({ onPopup, remains }) => {
    const [popUp, setPopUp] = React.useState(false)
    const button = getButton('bell', popUp ? 'popped' : '', 'large', 'icon-message')

    return (
        <Tooltip title={''} color={config.app.tooltipBG}>
            <div
                style={{ width: 40, height: 40 }}
                onClick={() => {
                    if (!popUp) {
                        setPopUp(true)
                        onPopup().finally(() => {
                            setPopUp(false)
                        })
                    }
                }}
            >
                <FloatContainer>
                    <FloatBox>{button}</FloatBox>
                    <FloatBox>
                        {remains && remains > 0 ? (
                            <div
                                style={{
                                    width: 8,
                                    height: 8,
                                    top: 10,
                                    left: 20,
                                    borderRadius: 4,
                                    backgroundColor: '#E50013',
                                    position: 'absolute',
                                }}
                            />
                        ) : null}
                    </FloatBox>
                </FloatContainer>
            </div>
        </Tooltip>
    )
}

const StyledPrimaryButton = styled(Button)`
    color: ${config.app.fontColorStronger} !important;
    background-color: #1a98b9 !important;
    border: 0 !important;
    padding: 0 10px 0 10px;
    &:hover {
        color: ${config.app.fontColorStronger} !important;
        background-color: #26bae1 !important;
    }
    &:active {
        color: ${config.app.fontColorStronger} !important;
        background-color: #1a98b9 !important;
    }
    &.mdp-btn-disabled {
        color: ${config.app.fontColorWeak} !important;
        background-color: rgba(255, 255, 255, 0.05) !important;
    }
    &.ant-btn span[role='img'] {
        margin-right: 4px;
    }
    &.ant-btn span {
        margin-left: 0;
    }
`

export const PrimaryButton: React.FC<ButtonProps> = (props) => {
    return <StyledPrimaryButton className={props.disabled ? 'mdp-btn-disabled' : ''} {...props} />
}

export const StyledSecondaryButton = styled(Button)`
    color: ${config.app.fontColorNormal} !important;
    border-color: rgba(220, 222, 224, 0.25) !important;
    background-color: transparent !important;

    &:hover {
        color: #1a98b9 !important;
        border-color: #1a98b9 !important;
        background-color: transparent !important;
    }
    &:active {
        color: ${config.app.fontColorNormal} !important;
        border-color: rgba(220, 222, 224, 0.25) !important;
        background-color: transparent !important;
    }
    &.mdp-btn-disabled {
        color: ${config.app.fontColorWeaker} !important;
        border-color: rgba(255, 255, 255, 0.2) !important;
        background-color: transparent !important;
    }
`

export const SecondaryButton: React.FC<ButtonProps> = (props) => {
    return <StyledSecondaryButton className={props.disabled ? 'mdp-btn-disabled' : ''} {...props} />
}

export const StyledTextButton = styled(Button)`
    color: ${config.app.primaryColor} !important;
    background-color: transparent !important;

    &:hover {
        color: #26bae1 !important;
        background-color: rgba(255, 255, 255, 0.05) !important;
    }
    &:active {
        color: ${config.app.primaryColor} !important;
        background-color: transparent !important;
    }
    &.mdp-btn-disabled {
        color: ${config.app.fontColorWeaker} !important;
        background-color: transparent !important;
    }
`

export const TextButton: React.FC<ButtonProps> = (props) => {
    return <StyledTextButton className={props.disabled ? 'mdp-btn-disabled' : ''} {...props} type={'link'} />
}

//
// <StyledMenuContainer
//     align="middle"
//     className={selected ? 'mdp-menu-button mdp-menu-button-selected' : 'mdp-menu-button'}
//     onClick={onClick}
//     style={{
//         height: 40,
//         paddingLeft: 37,
//     }}
// >
//     <StyledMenuIcon className={`iconfont ${icon} ${kind}`} style={{ width: 18 }}></StyledMenuIcon>
//     <span style={{ marginLeft: 8 }}>{text}</span>
// </StyledMenuContainer>

// const StyledIconButton = styled.i`
//     // transition: all 200ms ease-in;
//     font-size: 16px;
//     background-image: -webkit-linear-gradient(-90deg, #1592c0 0%, #39b399 100%);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//
//     &.mdp-menu-icon-selected {
//         font-size: 16px;
//         background-image: -webkit-linear-gradient(-90deg, #ffffff 0%, #ffffff 100%) !important;
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//     }
// `

const StyledButtonIconPrimary = styled.div`
    border-color: transparent;
    color: #ffffff;
    background: #1a98b9;
    transition: ${config.app.transitionAll};

    &:hover {
        border-color: transparent;
        color: #ffffff;
        background: #26bae1;
    }

    &:active {
        border-color: transparent;
        color: #ffffff;
        background: #1a98b9;
    }

    &.highlight {
        border-color: transparent;
        color: #ffffff;
        background: #1a98b9;
    }

    &.disabled {
        border-color: transparent;
        color: rgba(255, 255, 255, 0.45);
        background: rgba(255, 255, 255, 0.05);
    }
`

const StyledButtonIconGhost = styled.div`
    border-color: ${(props) => props.color || '#1a98b9'};
    color: ${(props) => props.color || '#1a98b9'};
    background: transparent;
    transition: ${config.app.transitionAll};

    &:hover {
        border-color: ${(props) => props.color || '#26bae1'};
        color: ${(props) => props.color || '#26bae1'};
        background: ${(props) => (props.color ? props.color + '1A' : '#26bae11A')};
    }

    &:active {
        border-color: ${(props) => props.color || '#1a98b9'};
        color: ${(props) => props.color || '#1a98b9'};
        background: transparent;
    }

    &.highlight {
        border-color: ${(props) => props.color || '#1a98b9'};
        color: ${(props) => props.color || '#1a98b9'};
        background: transparent;
    }

    &.disabled {
        border-color: rgba(255, 255, 255, 0.25);
        color: rgba(255, 255, 255, 0.25);
        background: transparent;
    }
`

const StyledButtonIconSecondary = styled.div`
    border-color: transparent;
    color: rgba(255, 255, 255, 0.65);
    background: transparent;
    transition: ${config.app.transitionAll};

    &:hover {
        border-color: transparent;
        color: rgba(255, 255, 255, 0.65);
        background: rgba(255, 255, 255, 0.05);
    }

    &:active {
        border-color: transparent;
        color: rgba(255, 255, 255, 0.65);
        background: transparent;
    }

    &.highlight {
        border-color: transparent;
        color: #1a98b9;
        background: transparent;
    }

    &.disabled {
        border-color: transparent;
        color: rgba(255, 255, 255, 0.25);
        background: transparent;
    }
`

const StyledButtonText = styled.div`
    border-color: transparent;
    color: #1a98b9;
    background: transparent;
    transition: ${config.app.transitionAll};

    &:hover {
        border-color: transparent;
        color: #26bae1;
        background: rgba(255, 255, 255, 0.05);
    }

    &:active {
        border-color: transparent;
        color: #1a98b9;
        background: transparent;
    }

    &.highlight {
        border-color: transparent;
        color: #1a98b9;
        background: transparent;
    }

    &.disabled {
        border-color: transparent;
        color: rgba(255, 255, 255, 0.25);
        background: transparent;
    }
`

export const IconButton: React.FC<{
    style?: React.CSSProperties
    icon?: string
    extraIcon?: string
    label?: string
    disabled?: boolean
    highlight?: boolean
    color?: string
    size?: 'small' | 'middle' | 'large'
    type: 'primary' | 'ghost' | 'icon' | 'text'
    onClick?: (e: any) => void
    onMouseDown?: (e: any) => void
    onMouseDownCapture?: (e: any) => void
    onMouseUp?: (e: any) => void
    onMouseUpCapture?: (e: any) => void
}> = ({
    style,
    icon,
    label,
    extraIcon,
    color,
    size,
    disabled,
    highlight,
    type,
    onClick,
    onMouseDown,
    onMouseDownCapture,
    onMouseUp,
    onMouseUpCapture,
}) => {
    const innerStyle = {
        display: 'inline-block',
        borderWidth: 1,
        borderStyle: 'solid',
        lineHeight: '32px',
        paddingLeft: label ? 12 : 8,
        paddingRight: label ? 12 : 8,
        userSelect: 'none',
        borderRadius: 2,
        height: 32,
        textAlign: 'center',
    } as any

    if (size === 'small') {
        innerStyle['transform'] = 'scale(0.75)'
    } else if (size === 'large') {
        innerStyle['transform'] = 'scale(1.25)'
    }

    const content = (
        <>
            {icon ? <i className={`iconfont ${icon}`} style={{ fontSize: 14 }} /> : null}
            {icon && label ? <div style={{ display: 'inline-block', width: 6 }} /> : null}
            {label ? <span style={{ fontSize: 14, fontWeight: 500 }}>{label}</span> : null}
            {(icon || label) && extraIcon ? <div style={{ display: 'inline-block', width: 6 }} /> : null}
            {extraIcon ? <i className={`iconfont ${extraIcon}`} style={{ fontSize: 14 }} /> : null}
        </>
    )

    switch (type) {
        case 'primary':
            return (
                <StyledButtonIconPrimary
                    className={(disabled ? 'disabled ' : '') + (highlight ? 'highlight ' : '')}
                    style={{ ...innerStyle, ...style }}
                    onClick={(e) => {
                        if (!disabled) {
                            onClick && onClick(e)
                        }
                    }}
                    onMouseDown={onMouseDown}
                    onMouseDownCapture={onMouseDownCapture}
                    onMouseUp={onMouseUp}
                    onMouseUpCapture={onMouseUpCapture}
                >
                    {content}
                </StyledButtonIconPrimary>
            )
        case 'ghost':
            return (
                <StyledButtonIconGhost
                    className={(disabled ? 'disabled ' : '') + (highlight ? 'highlight ' : '')}
                    style={{ ...style, ...innerStyle }}
                    color={color}
                    onClick={(e) => {
                        if (!disabled) {
                            onClick && onClick(e)
                        }
                    }}
                    onMouseDown={onMouseDown}
                    onMouseDownCapture={onMouseDownCapture}
                    onMouseUp={onMouseUp}
                    onMouseUpCapture={onMouseUpCapture}
                >
                    {content}
                </StyledButtonIconGhost>
            )
        case 'icon':
            return (
                <StyledButtonIconSecondary
                    className={(disabled ? 'disabled ' : '') + (highlight ? 'highlight ' : '')}
                    style={{ ...style, ...innerStyle }}
                    onClick={(e) => {
                        if (!disabled) {
                            onClick && onClick(e)
                        }
                    }}
                    onMouseDown={onMouseDown}
                    onMouseDownCapture={onMouseDownCapture}
                    onMouseUp={onMouseUp}
                    onMouseUpCapture={onMouseUpCapture}
                >
                    {content}
                </StyledButtonIconSecondary>
            )
        case 'text':
            return (
                <StyledButtonText
                    className={(disabled ? 'disabled ' : '') + (highlight ? 'highlight ' : '')}
                    style={{ ...style, ...innerStyle }}
                    onClick={(e) => {
                        if (!disabled) {
                            onClick && onClick(e)
                        }
                    }}
                    onMouseDown={onMouseDown}
                    onMouseDownCapture={onMouseDownCapture}
                    onMouseUp={onMouseUp}
                    onMouseUpCapture={onMouseUpCapture}
                >
                    {content}
                </StyledButtonText>
            )
        default:
            return <div>error type {type}</div>
    }
}

export const IconSimpleButton: React.FC<{
    style?: React.CSSProperties
    icon?: string
    fontSize?: number
    disabled?: boolean
    highlight?: boolean
    color?: string
    size?: number
    onClick?: (e: any) => void
    onMouseDown?: (e: any) => void
    onMouseDownCapture?: (e: any) => void
    onMouseUp?: (e: any) => void
    onMouseUpCapture?: (e: any) => void
}> = ({
    style,
    icon,
    color,
    fontSize,
    size,
    disabled,
    highlight,
    onClick,
    onMouseDown,
    onMouseDownCapture,
    onMouseUp,
    onMouseUpCapture,
}) => {
    const innerStyle = {
        display: 'inline-block',
        borderWidth: 1,
        borderStyle: 'solid',
        userSelect: 'none',
        borderRadius: 2,
        height: size,
        width: size,
        lineHeight: size + 'px',
        textAlign: 'center',
    } as any

    return (
        <StyledButtonIconSecondary
            className={(disabled ? 'disabled ' : '') + (highlight ? 'highlight ' : '')}
            style={{ ...style, ...innerStyle }}
            onClick={(e) => {
                if (!disabled) {
                    onClick && onClick(e)
                }
            }}
            onMouseDown={onMouseDown}
            onMouseDownCapture={onMouseDownCapture}
            onMouseUp={onMouseUp}
            onMouseUpCapture={onMouseUpCapture}
        >
            {icon ? <i className={`iconfont ${icon}`} style={{ fontSize: fontSize || 14, color: color }} /> : null}
        </StyledButtonIconSecondary>
    )
}

const StyledButtonPopup = styled(Row)`
    border-radius: 2px;
    border-color: transparent;
    color: rgba(255, 255, 255, 0.65);
    background: transparent;
    transition: ${config.app.transitionAll};

    &:hover {
        border-color: transparent;
        color: #26bae1;
        background: rgba(255, 255, 255, 0.05);
    }

    &:active {
        border-color: transparent;
        color: #1a98b9;
        background: transparent;
    }

    &.disabled {
        border-color: transparent;
        color: rgba(255, 255, 255, 0.25);
        background: transparent;
    }
`

export const IconPopupButton: React.FC<{
    icon: string
    popped?: boolean
    width?: number
    height?: number
    disabled?: boolean
    fontSize?: number
    onClick?: React.MouseEventHandler<HTMLElement>
}> = ({ width, height, disabled, icon, fontSize, popped, onClick }) => {
    return (
        <StyledButtonPopup
            className={disabled ? 'disabled ' : ''}
            align={'middle'}
            justify={'center'}
            onClick={onClick}
            style={{ width: width, height: height }}
        >
            <i
                className={`iconfont ${icon}`}
                style={{ fontSize: fontSize || 14, color: popped ? '#1a98b9' : 'unset', margin: 'auto' }}
            />
        </StyledButtonPopup>
    )
}

export const StyledIButton = styled(Row)`
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
    transition: ${config.app.transitionAll};
`
