import config from '../../package.json'
import { Api } from './api'
import { EventHub } from './event-hub'
import { LocalStorage } from './storage'

const { protocol, hostname, port } = window.location
const servicePort = config.app.Debug ? config.app.DebugServicePort || port : port

// system channel name define
export const AppSystemCH = 'App.System'
export const AppUserCH = 'App.User'
export const AppTableCH = 'App.Table'
export const AppCurrentProjectCH = 'App.CurrentVFXProject'
export const AppPopupChannel = 'App.Popup'
// user roles
export const RoleCEOLeader = '#CEOLeader'
export const RoleCEOWorker = '#CEOWorker'
export const RoleCEOMember = '#CEOMember'
export const RoleHRLeader = '#HRLeader'
export const RoleHRWorker = '#HRWorker'
export const RoleHRMember = '#HRMember'
export const RoleFinancialLeader = '#FinancialLeader'
export const RoleFinancialWorker = '#FinancialWorker'
export const RoleFinancialMember = '#FinancialMember'
export const RoleVFXLeader = '#VFXLeader'
export const RoleVFXWorker = '#VFXWorker'
export const RoleVFXMember = '#VFXMember'
export const RoleVFXProjectLeader = '#VFXProjectLeader'
export const RoleVFXProjectWorker = '#VFXProjectWorker'
export const RoleVFXProjectMember = '#VFXProjectMember'
export const RoleVFXProjectDirectorLeader = '#VFXProjectDirectorLeader'
export const RoleVFXProjectDirectorWorker = '#VFXProjectDirectorWorker'
export const RoleVFXProjectDirectorMember = '#VFXProjectDirectorMember'
export const RoleVFXProjectCoordinatorLeader = '#VFXProjectCoordinatorLeader'
export const RoleVFXProjectCoordinatorWorker = '#VFXProjectCoordinatorWorker'
export const RoleVFXProjectCoordinatorMember = '#VFXProjectCoordinatorMember'
export const RoleVFXProjectProducerLeader = '#VFXProjectProducerLeader'
export const RoleVFXProjectProducerWorker = '#VFXProjectProducerWorker'
export const RoleVFXProjectProducerMember = '#VFXProjectProducerMember'
export const RoleVFXTaskLeader = '#VFXTaskLeader'
export const RoleVFXTaskWorker = '#VFXTaskWorker'
export const RoleVFXTaskMember = '#VFXTaskMember'
export const RoleVFXTaskOwner = '#VFXTaskOwner'
export const RoleMe = '#Me'
export const RoleEveryOne = '#EveryOne'
export const RoleAuthorizedUser = '#AuthorizedUser'

export const TeamKinds_All = ['ceo', 'vfx', 'hr', 'financial', 'worker', 'guest']
export const TeamKinds_ManageByCEO = ['vfx', 'hr', 'financial', 'worker', 'guest']
export const TeamKinds_ManageByHR = ['worker', 'guest']
export const TeamKinds_ManageByVFX = ['worker']

export class AuthItem {
    private id: string
    private readonly project: string
    private userId: string
    private task: string
    private readonly kind: string
    private readonly taskTeamId: string
    private readonly taskOwnerId: string

    constructor(data?: any) {
        this.id = data?.id || ''
        this.project = App.VFXProject.id || ''
        this.userId = App.User.id || ''
        this.task = data?.task?.id || data?.task || data?.id || ''
        this.kind = data?.kind || ''
        this.taskTeamId = data?.team?.id || data?.team || ''
        this.taskOwnerId = data?.worker?.id || data?.worker || ''
    }

    matchACLItem(acl: string): boolean {
        if (acl.indexOf(':kind=') >= 0) {
            if (!this.kind || !acl.indexOf(':kind=' + this.kind)) {
                return false
            }
        }

        const aclSA = acl.split(':')
        if (aclSA && aclSA.length > 0) {
            switch (aclSA[0]) {
                case RoleCEOLeader:
                    return App.isTeamLeader('ceo')
                case RoleCEOWorker:
                    return App.isTeamWorker('ceo')
                case RoleCEOMember:
                    return App.isTeamMember('ceo')
                case RoleHRLeader:
                    return App.isTeamLeader('hr')
                case RoleHRWorker:
                    return App.isTeamWorker('hr')
                case RoleHRMember:
                    return App.isTeamMember('hr')
                case RoleFinancialLeader:
                    return App.isTeamLeader('financial')
                case RoleFinancialWorker:
                    return App.isTeamWorker('financial')
                case RoleFinancialMember:
                    return App.isTeamMember('financial')
                case RoleVFXLeader:
                    return App.isTeamLeader('vfx')
                case RoleVFXWorker:
                    return App.isTeamWorker('vfx')
                case RoleVFXMember:
                    return App.isTeamMember('vfx')
                case RoleVFXProjectLeader:
                    return App.isTeamLeader(this.project)
                case RoleVFXProjectWorker:
                    return App.isTeamWorker(this.project)
                case RoleVFXProjectMember:
                    return App.isTeamMember(this.project)
                case RoleVFXProjectDirectorLeader:
                    return App.isTeamLeader('Director@' + this.project)
                case RoleVFXProjectDirectorWorker:
                    return App.isTeamWorker('Director@' + this.project)
                case RoleVFXProjectDirectorMember:
                    return App.isTeamMember('Director@' + this.project)
                case RoleVFXProjectCoordinatorLeader:
                    return App.isTeamLeader('Coordinator@' + this.project)
                case RoleVFXProjectCoordinatorWorker:
                    return App.isTeamWorker('Coordinator@' + this.project)
                case RoleVFXProjectCoordinatorMember:
                    return App.isTeamMember('Coordinator@' + this.project)
                case RoleVFXProjectProducerLeader:
                    return App.isTeamLeader('Producer@' + this.project)
                case RoleVFXProjectProducerWorker:
                    return App.isTeamWorker('Producer@' + this.project)
                case RoleVFXProjectProducerMember:
                    return App.isTeamMember('Producer@' + this.project)
                case RoleVFXTaskLeader:
                    return App.isTeamLeader(this.taskTeamId)
                case RoleVFXTaskWorker:
                    return this.taskOwnerId === App.User.id
                case RoleEveryOne:
                    return true
                case RoleAuthorizedUser:
                    return App.User.id.length > 0
                default:
                    return false
            }
        } else {
            return false
        }
    }

    matchACLArray(aclList: Array<string>): boolean {
        for (let i = 0; i < aclList.length; i++) {
            if (this.matchACLItem(aclList[i])) {
                return true
            }
        }

        return false
    }
}

export class App {
    public static Vars = {
        MainMenu: {
            scrollY: 0,
        },
    }
    public static System: any = {}
    public static User: any = {}
    public static VFXProject: any = {}
    public static Teams: any = {}
    public static Auths: any = {}

    public static EventHub = new EventHub()
    public static Api = new Api(
        `${protocol}//${hostname}:${servicePort}/hub_api`,
        `${protocol}//${hostname}:${servicePort}/hub_fs`
    )

    public static reset() {
        App.System = {}
        App.User = {}
        App.VFXProject = {}
        App.Teams = new Map()
        App.Api = new Api(
            `${protocol}//${hostname}:${servicePort}/hub_api`,
            `${protocol}//${hostname}:${servicePort}/hub_fs`
        )
    }

    public static async setCurrentProject(projectId: string) {
        LocalStorage.write('user.vfx.project', projectId)
        App.VFXProject = {}
        await App.load(() => {}, true)
    }

    public static getCurrentProjectTableLayoutConfig(key: string): any {
        try {
            return JSON.parse(App.VFXProject[key])
        } catch {
            return null
        }
    }

    public static async load(onNeedLogin: () => void, force: boolean) {
        // update user
        if (!App.User.id || force) {
            const security = App.User.security || (LocalStorage.read('user.login.security') as string)

            try {
                const user = await App.Api.loginBySecurity(security)

                if (!user) {
                    onNeedLogin()
                    return
                } else {
                    App.User = user
                }
            } catch {
                onNeedLogin()
                return
            }
        }
    }

    public static getMyDefaultSearchProjectGroup(): Array<any> | undefined {
        let teams = new Array<any>()
        Object.keys(App.Teams).forEach((k: any) => {
            const o = App.Teams[k]
            if (o.project === App.VFXProject.id && o.kind === 'vfx-worker') {
                teams.push({ id: o.id, name: o.name })
            }
        })

        if (teams.length > 0) {
            return teams
        } else {
            return undefined
        }
    }

    public static authAttr(service: string, attrName: string, data?: any): boolean {
        if (App.Auths[service] && App.Auths[service]['Attrs'] && App.Auths[service]['Attrs'][attrName]) {
            return new AuthItem(data).matchACLArray(App.Auths[service]['Attrs'][attrName])
        } else {
            return false
        }
    }

    public static authAttrOneOf(service: string, actionName: string, dataArray: any[]) {
        for (let i = 0; i < dataArray.length; i++) {
            if (App.authAttr(service, actionName, dataArray[i])) {
                return true
            }
        }

        return false
    }

    public static authAction(service: string, actionName: string, data?: any): boolean {
        if (App.Auths[service] && App.Auths[service]['Actions'] && App.Auths[service]['Actions'][actionName]) {
            return new AuthItem(data).matchACLArray(App.Auths[service]['Actions'][actionName])
        } else {
            return false
        }
    }

    public static authActionOneOf(service: string, actionName: string, dataArray: any[]) {
        for (let i = 0; i < dataArray.length; i++) {
            if (App.authAction(service, actionName, dataArray[i])) {
                return true
            }
        }

        return false
    }

    public static isTeamLeader(teamId: string): boolean {
        if (App.Teams[teamId] && App.Teams[teamId].leaders) {
            return App.Teams[teamId].leaders[App.User.id] || false
        } else {
            return false
        }
    }

    public static isTeamWorker(teamId: string): boolean {
        if (App.Teams[teamId] && App.Teams[teamId].workers) {
            return App.Teams[teamId].workers[App.User.id] || false
        } else {
            return false
        }
    }

    public static isTeamMember(teamId: string): boolean {
        return App.isTeamWorker(teamId) || App.isTeamLeader(teamId)
    }
}

App.EventHub.createChannel(AppSystemCH)
App.EventHub.createChannel(AppUserCH)
App.EventHub.createChannel(AppTableCH)
App.EventHub.createChannel(AppCurrentProjectCH)
App.EventHub.createChannel(AppPopupChannel)
