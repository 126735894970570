import React, { useState } from 'react'
import { Button, Input } from 'antd'
import { InputRectContainer } from './container'
import { validatePhone } from '../../utils/validate'
import { useStorageSmsTimer } from '../../utils/hook'
import config from '../../../package.json'
import { App } from '../../utils/app'
import styled from 'styled-components'

const SendButton = styled(Button)`
    &:before {
        background: transparent !important;
    }
`

export const CheckCodeRectInput: React.FC<{
    label: string
    phone: string | null
    value: string
    width: number
    placeholder: string
    autoFocus?: boolean
    onChange: (v: any) => void
    disabled?: boolean
    error?: Error | null
}> = ({
    label,
    phone,
    value,
    width,
    placeholder,
    autoFocus,
    onChange,
    disabled,
    error,
}) => {
    const [remains, resetRemains] = useStorageSmsTimer(
        config.app.Debug ? 3 : 60
    )
    const [loading, setLoading] = useState(false)
    const disabledInput = disabled || validatePhone(phone) !== null || loading

    let btnText: string
    if (loading) {
        btnText = '发送中'
    } else if (remains > 0) {
        btnText = `${remains}秒后重发`
    } else {
        btnText = '发送验证码'
    }

    return (
        <InputRectContainer
            label={label}
            width={width}
            disabled={disabledInput}
            element={
                <Input
                    placeholder={placeholder}
                    value={value}
                    bordered={false}
                    disabled={disabledInput}
                    autoFocus={autoFocus}
                    onChange={(evt) => {
                        onChange(evt.target.value)
                    }}
                />
            }
            tail={
                <SendButton
                    disabled={disabledInput || remains > 0}
                    type={'link'}
                    onClick={() => {
                        if (phone && !loading) {
                            setLoading(true)
                            App.Api.sendSmsCode(phone)
                                .then(() => {
                                    resetRemains()
                                })
                                .finally(() => {
                                    setLoading(false)
                                })
                        }
                    }}
                    loading={loading}
                    onFocus={(e) => {
                        e.stopPropagation()
                    }}
                    onBlur={(e) => {
                        e.stopPropagation()
                    }}
                >
                    {btnText}
                </SendButton>
            }
            error={error}
        />
    )
}
