import { Drawer, Row } from 'antd'
import React from 'react'
import { Outlet, Route, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import config from '../../../package.json'
import { IconButton } from '../../components/common/Button'
import { FloatBox, FloatContainer } from '../../components/common/Container'
import { Logo } from '../../components/common/Logo'
import { AutoPage } from '../../components/common/Page'
import { HAutoSpacer, HSpacer } from '../../components/common/Space'
import { Text } from '../../components/common/Text'
import { RootRouterAppHubLogout, RootRouterAppHubMain } from '../../index'
import { App, AppPopupChannel, AppSystemCH, AuthItem, RoleAuthorizedUser } from '../../utils/app'
import { renderIf } from '../../utils/ui'
import { CompanyModule } from './company/CompanyModule'
import { CompanyProjectDrawer } from './company/CompanyProjectDrawer'
import { ProfileModule } from './profile/ProfileModule'

const StyledDrawer = styled(Drawer)`
    & .ant-drawer-content-wrapper {
        background-color: transparent !important;
        box-shadow: none;
    }
    & .ant-drawer-content {
        background-color: transparent !important;
        box-shadow: none;
    }
`

const StyledMenuContainer = styled(Row)`
    transition: ${config.app.transitionAll};
    color: ${config.app.fontColorNormal};

    :hover {
        background: rgba(26, 152, 185, 0.2);
    }

    &.mdp-menu-button-selected {
        background: rgba(26, 152, 185, 1) !important;
        color: rgba(255, 255, 255, 1) !important;
    }
`

const StyledMenuIcon = styled.i`
    // transition: all 200ms ease-in;
    font-size: 16px;
    background-image: -webkit-linear-gradient(-90deg, #1592c0 0%, #39b399 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.mdp-menu-icon-selected {
        font-size: 16px;
        background-image: -webkit-linear-gradient(-90deg, #ffffff 0%, #ffffff 100%) !important;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

const MenuButton: React.FC<{
    icon: string
    text: string
    selected: boolean
    hint?: number
    onClick: () => void
}> = ({ icon, text, hint, selected, onClick }) => {
    const kind = selected ? 'mdp-menu-icon-selected mdp-menu-icon' : 'mdp-menu-icon'

    return (
        <StyledMenuContainer
            align="middle"
            className={selected ? 'mdp-menu-button mdp-menu-button-selected' : 'mdp-menu-button'}
            onClick={onClick}
            style={{
                height: 40,
                paddingLeft: 37,
            }}
        >
            <StyledMenuIcon className={`iconfont ${icon} ${kind}`} style={{ width: 18 }}></StyledMenuIcon>
            <span style={{ marginLeft: 8 }}>{text}</span>

            {renderIf(
                !!hint,
                <Row
                    align={'middle'}
                    justify={'center'}
                    style={{
                        marginLeft: 6,
                        background: '#BE404A',
                        minWidth: 24,
                        height: 16,
                        lineHeight: '16px',
                        borderRadius: 2,
                    }}
                >
                    <Text size={'Small'}> {hint}</Text>
                </Row>
            )}
        </StyledMenuContainer>
    )
}

const MenuItems = new Map<string, Array<{ key: string; title: string; icon: string; auth: Array<string> }>>([
    [
        '公司',
        [
            {
                key: 'my-company',
                title: '公司管理',
                icon: 'icon-renwu',
                auth: [RoleAuthorizedUser],
            },
        ],
    ],
    [
        '我的',
        [
            {
                key: 'my-profile',
                title: '账户设置',
                icon: 'icon-renwu',
                auth: [RoleAuthorizedUser],
            },
            {
                key: 'my-projects',
                title: '参与项目',
                icon: 'icon-xiezhu',
                auth: [RoleAuthorizedUser],
            },
        ],
    ],
])

export const MainMenu: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [keys, setKeys] = React.useState<string[]>(Array.from(MenuItems.keys()))

    React.useEffect(() => {
        if (location.pathname === RootRouterAppHubMain || location.pathname === RootRouterAppHubMain + '/') {
            // filter items by auth
            const items = MenuItems.get(keys[0])?.filter((v) => new AuthItem().matchACLArray(v.auth))
            if (items && items.length > 0) {
                navigate(RootRouterAppHubMain + '/' + items[0].key)
            }
        }
    }, [navigate, location, keys])

    React.useEffect(() => {
        const listener = App.EventHub.subscribe(AppSystemCH, (msg, value) => {
            switch (msg) {
                case 'FlushMainMenu':
                    // update
                    setKeys(Array.from(MenuItems.keys()))
                    break
            }
        })

        return () => {
            listener?.unsubscribe()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            ref={(v) => {
                v?.scrollTo(0, App.Vars.MainMenu.scrollY)
            }}
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: config.app.bgColorComponent,
                borderTopRightRadius: 2,
                borderBottomRightRadius: 2,
                boxShadow: config.app.boxShadowLeft,
                overflowY: 'auto',
            }}
            onScroll={(e) => {
                App.Vars.MainMenu.scrollY = (e.target as any).scrollTop
            }}
        >
            {keys.map((classTitle) => {
                // filter items by auth
                const items = MenuItems.get(classTitle)?.filter((v) => new AuthItem().matchACLArray(v.auth))

                return items && items.length > 0 ? (
                    <div key={classTitle}>
                        <Row align={'middle'} style={{ marginLeft: 20, height: 40 }}>
                            <Text color={'Weaker'}>{classTitle}</Text>
                        </Row>

                        {items.map((v) => {
                            const path = RootRouterAppHubMain + '/' + v.key
                            return (
                                <MenuButton
                                    key={v.key}
                                    icon={v.icon}
                                    text={v.title}
                                    hint={v.key === 'my-assist' ? App.User.a_unread_assist : undefined}
                                    selected={path === location.pathname}
                                    onClick={() => {
                                        if (path !== location.pathname) {
                                            navigate(path)
                                        }
                                    }}
                                />
                            )
                        })}
                    </div>
                ) : null
            })}
        </div>
    )
}

export const MainPageContext = React.createContext<{
    popupProjectSelector: (uuid: string) => void
}>({ popupProjectSelector: () => {} })

const MainPage = () => {
    const navigate = useNavigate()

    const [popupKind, setPopupKind] = React.useState('')
    const [drawerPopupId, setDrawerPopupId] = React.useState('')

    React.useEffect(() => {
        let running = true

        const listener = App.EventHub.subscribe(AppPopupChannel, (msg, value) => {
            if (running) {
                switch (msg) {
                    case 'PopupCompanyProject':
                        setPopupKind('compnay-project')
                        setDrawerPopupId(value)
                        break
                    case 'PopupClose':
                        setPopupKind('')
                        setDrawerPopupId('')
                        break
                }
            }
        })

        return () => {
            listener?.unsubscribe()
            running = false
        }
    }, [])

    const getDrawerContent = () => {
        const style = {
            backgroundColor: '#191B1E',
            overflow: 'hidden',
            marginLeft: 20,
            boxShadow: config.app.boxShadowDrawer,
        }
        switch (popupKind) {
            case 'compnay-project':
                return <CompanyProjectDrawer id={drawerPopupId} style={style} />
            default:
                return null
        }
    }

    const useMask = () => {
        switch (popupKind) {
            case 'compnay-project':
                return true
            default:
                return false
        }
    }

    return (
        <FloatContainer style={{ overflow: 'hidden' }}>
            {/*主体区域*/}
            <FloatBox top={72} bottom={0} left={0} right={0}>
                <Outlet />
            </FloatBox>

            {/*菜单区域*/}
            <FloatBox top={72} bottom={0} left={-160} width={160}>
                <MainMenu />
            </FloatBox>

            {/*头部区域*/}
            <FloatBox top={0} left={0} right={0} height={72}>
                <Row align="middle" style={{ width: '100%', height: '100%' }}>
                    <Logo style={{ marginLeft: 20 }} />

                    <HAutoSpacer />

                    <IconButton
                        type={'text'}
                        label={'退出登录'}
                        onClick={() => {
                            navigate(RootRouterAppHubLogout)
                        }}
                    />

                    <HSpacer width={16} />
                </Row>
            </FloatBox>

            {/*右侧弹窗*/}
            <FloatBox
                top={136}
                bottom={0}
                right={0}
                width={!!drawerPopupId && popupKind !== 'version' ? 410 : 0}
                style={{ zIndex: 2 }}
            >
                <StyledDrawer
                    placement="right"
                    closable={false}
                    open={!!drawerPopupId}
                    mask={useMask()}
                    getContainer={useMask() ? undefined : false}
                    width={!!drawerPopupId && popupKind !== 'version' ? 410 : 0}
                    bodyStyle={{
                        padding: 0,
                    }}
                >
                    {getDrawerContent()}
                </StyledDrawer>
            </FloatBox>
        </FloatContainer>
    )
}

export function getVFXMainRoute() {
    return (
        <Route path={RootRouterAppHubMain} element={<AutoPage entry={<MainPage />} />}>
            <Route path="my-profile" element={<ProfileModule />} />
            <Route path="my-company" element={<CompanyModule />} />
        </Route>
    )
}
