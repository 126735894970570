import { ConfigProvider } from 'antd'
import 'antd/dist/antd.dark.less'
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AutoPage } from './components/common/Page'
import './index.less'
import { AppSupportPage } from './routes/AppSupport'
import { BootLoader } from './routes/BootLoader'
import { LoginPage } from './routes/LoginPage'
import { LogoutPage } from './routes/LogoutPage'
import { RegisterPage } from './routes/RegisterPage'
import { getVFXMainRoute } from './routes/main'
moment.locale('zh-cn')

export const RootRouterAppHub = '/app/hub'
export const RootRouterAppSupport = '/app/hub/support'
export const RootRouterAppHubRegister = '/app/hub/register'
export const RootRouterAppHubLogin = '/app/hub/login'
export const RootRouterAppHubLogout = '/app/hub/logout'
export const RootRouterAppHubMain = '/app/hub/main'
export const RootRouterAppDirMainIndex = '/app/hub/main'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const RootContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <div style={{ width: '100%', height: '100%' }}>{children}</div>
}

root.render(
    <ConfigProvider autoInsertSpaceInButton={false} locale={zhCN}>
        <RootContainer>
            <BrowserRouter>
                <Routes>
                    <Route path={RootRouterAppHub} element={<AutoPage entry={<BootLoader />} imageBG={true} />} />
                    <Route path={RootRouterAppHubRegister} element={<RegisterPage />} />
                    <Route path={RootRouterAppHubLogin} element={<LoginPage />} />
                    <Route path={RootRouterAppHubLogout} element={<LogoutPage />} />
                    <Route path={RootRouterAppSupport} element={<AppSupportPage />} />
                    {getVFXMainRoute()}
                </Routes>
            </BrowserRouter>
        </RootContainer>
    </ConfigProvider>
)
