import React, { useState } from 'react'
import config from '../../package.json'
import { validateAlwaysOK, validatePassword, validatePhone, validateSmsCode } from '../utils/validate'
import { useValidateMap } from '../utils/hook'
import bgImage from '../assets/common/bg.png'
import logoImage from '../assets/common/logo.png'
import { Page } from '../components/common/Page'
import { CenterWindow } from '../components/common/Window'
import { Button, Image, Row, Switch } from 'antd'
import { StringRectInput } from '../components/input/string'
import { PasswordRectInput } from '../components/input/password'
import { LocalStorage } from '../utils/storage'
import { App } from '../utils/app'
import { sha256 } from '../utils/crypto'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootRouterAppDirMainIndex, RootRouterAppHubRegister } from '../index'
import { getNavigatePath } from '../utils/location'
import { CheckCodeRectInput } from '../components/input/checkcode'

const IsDebug = config.app.Debug && config.app.DebugLogin

export const LoginPage: React.FC = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const backHRef = location.state?.backHRef

    const data = useValidateMap(
        new Map([
            ['phone', validatePhone],
            ['password', validatePassword],
            ['auto', validateAlwaysOK],
            ['smsCode', validateSmsCode],
        ]),
        new Map([
            ['phone', IsDebug ? '11111111111' : ''],
            ['password', IsDebug ? 'Test1234' : ''],
            ['smsCode', IsDebug ? '329726' : ''],
            ['auto', LocalStorage.read('user.login.auto') as string],
        ])
    )

    return (
        <Page
            align="middle"
            justify="center"
            style={{
                background: `url(${bgImage}) no-repeat center center fixed`,
                backgroundSize: 'cover',
            }}
        >
            <CenterWindow width={364} height={470}>
                <Row align="middle" justify="center" style={{ height: 129 }}>
                    <Image preview={false} width={200} src={logoImage} />
                </Row>
                <Row justify={'center'} style={{ marginTop: 0 }}>
                    <StringRectInput
                        label={'手机号'}
                        width={300}
                        value={data.valueMap.get('phone')}
                        placeholder={'输入登陆手机号'}
                        allowClear={true}
                        onChange={(v) => {
                            data.setValue('phone', v)
                        }}
                        disabled={loading}
                        error={data.errorMap.get('phone')}
                    />
                </Row>
                <Row justify={'center'} style={{ marginTop: 6 }}>
                    <CheckCodeRectInput
                        label={'验证码'}
                        width={300}
                        phone={data.valueMap.get('phone')}
                        value={data.valueMap.get('smsCode')}
                        placeholder={'手机验证码'}
                        onChange={(v) => {
                            data.setValue('smsCode', v)
                        }}
                        disabled={loading}
                        error={data.errorMap.get('smsCode')}
                    />
                </Row>
                <Row justify={'center'} style={{ marginTop: 6 }}>
                    <PasswordRectInput
                        label={'密码'}
                        width={300}
                        value={data.valueMap.get('password')}
                        placeholder={'输入密码'}
                        onChange={(v) => {
                            data.setValue('password', v)
                        }}
                        disabled={loading}
                        error={data.errorMap.get('password')}
                    />
                </Row>

                <Row justify={'center'} style={{ marginTop: 6, height: 20 }}>
                    <Row style={{ width: 300 }}>
                        <Row
                            style={{
                                color: loading ? config.app.fontColorWeaker : config.app.fontColorNormal,
                                transition: config.app.transitionAll,
                            }}
                        >
                            自动登录
                        </Row>
                        <Row style={{ marginLeft: 8 }}>
                            <Switch
                                disabled={loading}
                                checkedChildren="开启"
                                unCheckedChildren="关闭"
                                defaultChecked={data.valueMap.get('auto') === 'yes'}
                                onChange={(auto) => {
                                    const value = auto ? 'yes' : 'no'
                                    data.setValue('auto', value)
                                    LocalStorage.write('user.login.auto', value)
                                    if (!auto) {
                                        LocalStorage.write('user.login.security', '')
                                    }
                                }}
                            />
                        </Row>
                    </Row>
                </Row>

                <Row justify={'center'} style={{ marginTop: 24 }}>
                    <Button
                        type={'primary'}
                        style={{ width: 300 }}
                        loading={loading}
                        disabled={IsDebug ? false : !data.checked}
                        onClick={() => {
                            if (!loading) {
                                setLoading(true)
                                App.Api.loginByPhone(
                                    data.valueMap.get('phone'),
                                    sha256(data.valueMap.get('password')),
                                    data.valueMap.get('smsCode')
                                )
                                    .then((user: any) => {
                                        App.User = user
                          
                                        if (data.valueMap.get('auto') === 'yes') {
                                            LocalStorage.write('user.login.security', user.security)
                                        }

                                        if (backHRef && backHRef !== getNavigatePath()) {
                                            navigate(backHRef, {
                                                replace: true,
                                            })
                                        } else {
                                            navigate(RootRouterAppDirMainIndex, {
                                                replace: true,
                                            })
                                        }
                                    })
                                    .finally(() => {
                                        setLoading(false)
                                    })
                            }
                        }}
                    >
                        {loading ? '登陆中 ...' : '登陆'}
                    </Button>
                </Row>

                <Row justify={'center'} style={{ marginTop: 16 }}>
                    <Button
                        type={'ghost'}
                        style={{ width: 300 }}
                        loading={loading}
                        onClick={() => {
                            navigate(RootRouterAppHubRegister, {
                                replace: true,
                            })
                        }}
                    >
                        注册新账号
                    </Button>
                </Row>
            </CenterWindow>
        </Page>
    )
}
