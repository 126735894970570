import React from 'react'
import { Upload, Button, Image, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import { App } from '../../utils/app'
import { StyledIButton } from './Button'

export const XImageUpload: React.FC<{
    value?: string
    button?: React.ReactNode
    style?: React.CSSProperties
    disableCrop?: boolean
    uploadBucket: string
    aspect?: number;
    onChange: (v: string) => void

}> = ({ value, style, button,aspect, disableCrop, uploadBucket, onChange }) => {
    const [loading, setLoading] = React.useState(false)

    const uploadView = (
        <Upload
            showUploadList={false}
            disabled={!uploadBucket}
            action={App.Api.uploadURL(uploadBucket)}
            onChange={(info: any) => {
                if (info && info.file) {
                    if (info.file.status === 'uploading') {
                        setLoading(true)
                    } else if (info.file.status === 'done') {
                        setLoading(false)
                        if (info.file.response && info.file.response.length === 1) {
                            onChange(info.file.response[0])
                        }
                    } else {
                        // ignore
                    }
                }
            }}
        >
            {value ? (
                <Col style={{ ...style, position: 'relative' }}>
                    <Col
                        style={{
                            position: 'absolute',
                            background: 'gray',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Image style={{ width: '100%', height: '100%' }} src={App.Api.fileURL(value)} preview={false} />
                    </Col>

                    <StyledIButton
                        style={{
                            position: 'absolute',
                            top: -6,
                            right: 0,
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setLoading(false)
                            onChange('')
                        }}
                    >
                        <i className={`iconfont icon-xingzhuangjiehe11`} style={{ fontSize: 20, color: '#ffffff' }} />
                    </StyledIButton>
                </Col>
            ) : button ? (
                button
            ) : (
                <Button style={style} type="link" loading={loading} icon={<PlusOutlined />}>
                    上传图片
                </Button>
            )}
        </Upload>
    )

    return disableCrop ? (
        uploadView
    ) : (
        <ImgCrop zoomSlider={true} rotationSlider={true} aspect={aspect || (16 / 9)}>
            {uploadView}
        </ImgCrop>
    )
}
