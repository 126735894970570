import { RootRouterAppHubLogin } from '../index'
import { sha256 } from './crypto'
import { Deferred } from './deferred'
import { showError, showSuccess } from './message'

function extractCustomMessage(e: Error): string {
    const regex = /<(.*)>/g
    const found = e.message.match(regex)
    if (found) {
        return found[0].slice(1, -1)
    } else {
        return ''
    }
}

async function callService<T>(
    url: string,
    param: {
        Project: string
        Service: string
        Security: string
        Action: string
        Param?: any
    },
    effects?: {
        onSuccess?: (v: any) => void
        onError?: (e: Error) => void
    }
): Promise<T> {
    let deferred = new Deferred<T>()

    const onSuccess = effects?.onSuccess || (() => {})
    const onError = effects?.onError || (() => {})

    try {
        const res = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(param),
        })

        const contentType = res.headers.get('content-type')

        if (contentType && contentType.indexOf('application/json') !== -1) {
            const json = await res.json()

            if (json.OK) {
                deferred.doResolve(json.Value)
                onSuccess(json.Value)
            } else {
                if (json.Error.indexOf('security error') >= 0) {
                    window.location.href = RootRouterAppHubLogin
                }

                const e = new Error(json.Error)
                deferred.doReject(e)
                onError(e)
            }
        } else {
            const text = await res.text()
            const e = new Error(`http error ${res.status}: ${text}`)
            deferred.doReject(e)
            onError(e)
        }
    } catch (e) {
        deferred.doReject(e as Error)
        onError(e as Error)
    }

    return deferred.promise
}

export class Api {
    private project: string
    private security: string
    private readonly apiURL: string
    private readonly fsURL: string

    constructor(apiURL: string, fsURL: string) {
        this.security = ''
        this.project = ''
        this.apiURL = apiURL
        this.fsURL = fsURL
    }

    setProject(project: string) {
        this.project = project
    }

    fileURL(fileID?: string): string {
        if (!fileID) {
            return ''
        }

        return encodeURI(this.fsURL + '?&s=' + sha256(this.security) + '&fileName=' + fileID)
    }

    uploadURL(bucketId?: string): string {
        return encodeURI(this.fsURL + '?s=' + sha256(this.security) + '&bucketId=' + bucketId)
    }

    async registerUser(name: string, phone: string, smsCode: string, password: string, gender: boolean, email: string) {
        return await callService<boolean>(
            this.apiURL,
            {
                Project: this.project,
                Service: 'user',
                Action: 'Register',
                Security: this.security,
                Param: {
                    name: name,
                    phone: phone,
                    smsCode: smsCode,
                    password: password,
                    gender: gender,
                    email: email,
                },
            },
            {
                onSuccess: () => {
                    showSuccess('注册用户成功')
                },
                onError: (e) => {
                    if (e.message.indexOf('object exists') >= 0) {
                        showError('注册用户失败，用户已经存在')
                    } else {
                        showError('注册用户失败')
                    }
                },
            }
        )
    }

    async createCompanyProject(
        companyId: string,
        projectName: string,
        projectLabel: string,
        successMsg?: string,
        errorMsg?: string
    ) {
        return await callService<boolean>(
            this.apiURL,
            {
                Project: this.project,
                Service: 'company_project',
                Action: 'Create',
                Security: this.security,
                Param: { companyId: companyId, name: projectName, label: projectLabel },
            },
            {
                onSuccess: () => {
                    successMsg && showSuccess(successMsg)
                },
                onError: (e) => {
                    if (e.message.indexOf('object exists') >= 0) {
                        showError(errorMsg + ', 对象已经存在')
                    } else {
                        errorMsg && showError(errorMsg + ' ' + e.message)
                    }
                },
            }
        )
    }

    async createCompanyProjectUser(
        projectId: string,
        role: string,
        username: string,
        realname: string,
        phone: string,
        successMsg?: string,
        errorMsg?: string
    ) {
        return await callService<boolean>(
            this.apiURL,
            {
                Project: this.project,
                Service: 'company_project_user',
                Action: 'Create',
                Security: this.security,
                Param: { projectId: projectId, role: role, username: username, realname: realname, phone: phone },
            },
            {
                onSuccess: () => {
                    successMsg && showSuccess(successMsg)
                },
                onError: (e) => {
                    if (e.message.indexOf('object exists') >= 0) {
                        showError(errorMsg + ', 对象已经存在')
                    } else {
                        errorMsg && showError(errorMsg + ' ' + e.message)
                    }
                },
            }
        )
    }

    async createCompany(name: string, successMsg?: string, errorMsg?: string) {
        return await callService<boolean>(
            this.apiURL,
            {
                Project: this.project,
                Service: 'company',
                Action: 'Create',
                Security: this.security,
                Param: { name: name },
            },
            {
                onSuccess: () => {
                    successMsg && showSuccess(successMsg)
                },
                onError: (e) => {
                    if (e.message.indexOf('object exists') >= 0) {
                        showError(errorMsg + ', 对象已经存在')
                    } else {
                        errorMsg && showError(errorMsg + ' ' + e.message)
                    }
                },
            }
        )
    }

    async add(service: string, items: Array<any>, successMsg?: string, errorMsg?: string) {
        return await callService<boolean>(
            this.apiURL,
            {
                Project: this.project,
                Service: service,
                Action: 'Add',
                Security: this.security,
                Param: { items: items },
            },
            {
                onSuccess: () => {
                    successMsg && showSuccess(successMsg)
                },
                onError: (e) => {
                    if (e.message.indexOf('object exists') >= 0) {
                        showError(errorMsg + ', 对象已经存在')
                    } else {
                        errorMsg && showError(errorMsg + ' ' + e.message)
                    }
                },
            }
        )
    }

    async del(service: string, idArray: Array<string>, successMsg?: string, errorMsg?: string) {
        return await callService<boolean>(
            this.apiURL,
            {
                Project: this.project,
                Service: service,
                Action: 'Del',
                Security: this.security,
                Param: { idArray: idArray },
            },
            {
                onSuccess: () => {
                    successMsg && showSuccess(successMsg)
                },
                onError: (e) => {
                    errorMsg && showError(errorMsg + ' ' + e.message)
                },
            }
        )
    }

    async list(service: string, select: Array<string>, where: { string: any }, orderBy?: Array<string>) {
        return await callService<Array<any>>(this.apiURL, {
            Project: this.project,
            Service: service,
            Action: 'List',
            Security: this.security,
            Param: {
                Select: select,
                Where: where,
                OrderBy: orderBy,
            },
        })
    }

    async details(service: string, idArray: Array<string>) {
        if (idArray.length === 0) {
            return []
        }

        if (!idArray[0]) {
            console.trace('bug')
        }

        return await callService<Array<any>>(
            this.apiURL,
            {
                Project: this.project,
                Service: service,
                Action: 'Details',
                Security: this.security,
                Param: { idArray: idArray },
            },
            {
                onError: (e) => {
                    console.log(e)
                },
            }
        )
    }

    async update(
        service: string,
        idArray: Array<string>,
        name: string,
        value: any,
        successMsg?: string,
        errorMsg?: string
    ) {
        return await callService<boolean>(
            this.apiURL,
            {
                Project: this.project,
                Service: service,
                Action: 'Update',
                Security: this.security,
                Param: {
                    idArray: idArray,
                    name: name,
                    value: value,
                },
            },
            {
                onSuccess: () => {
                    successMsg && showSuccess(successMsg)
                },
                onError: (e) => {
                    const msg = extractCustomMessage(e)
                    if (msg) {
                        showError(msg)
                    } else {
                        console.log(e)
                        errorMsg && showError(errorMsg + ' ' + e.message)
                    }
                },
            }
        )
    }

    async sendSmsCode(phone: string) {
        return callService<boolean>(
            this.apiURL,
            {
                Project: this.project,
                Service: 'system',
                Action: 'SendSmsCode',
                Security: '',
                Param: { phone: phone },
            },
            {
                onSuccess: () => {
                    showSuccess('验证码已发送，请查收')
                },
                onError: (e) => {
                    console.log(e)
                    showError('验证码发送失败 ' + e.message)
                },
            }
        )
    }

    async loginByPhone(phone: string, password: string, smsCode: string) {
        return callService<any>(
            this.apiURL,
            {
                Project: this.project,
                Service: 'user',
                Action: 'LoginByPhone',
                Security: '',
                Param: {
                    phone: phone,
                    password: password,
                    smsCode: smsCode,
                },
            },
            {
                onSuccess: (v) => {
                    if (v && v.security) {
                        this.security = v.security
                    } else {
                        this.security = ''
                    }
                },
                onError: (e) => {
                    console.log(e)
                    this.security = ''
                    if (e.message.indexOf('auth error') >= 0) {
                        showError('登陆失败 无效的用户名或密码')
                    } else {
                        showError('登陆失败')
                    }
                },
            }
        )
    }

    async checkPassword(password: string) {
        return callService<any>(this.apiURL, {
            Project: this.project,
            Service: 'user',
            Action: 'CheckPassword',
            Security: this.security,
            Param: {
                password: password,
            },
        })
    }

    async activePhone(user: string, phone: string, smsCode: string, password: string) {
        return callService<any>(
            this.apiURL,
            {
                Project: this.project,
                Service: 'user',
                Action: 'ActivePhone',
                Security: this.security,
                Param: {
                    user: user,
                    phone: phone,
                    smsCode: smsCode,
                    password: password,
                },
            },
            {
                onSuccess: (v) => {
                    if (v && v.security) {
                        this.security = v.security
                    } else {
                        this.security = ''
                    }
                },
                onError: (e) => {
                    console.log(e)
                    this.security = ''
                    showError('激活用户失败 请输入正确的手机号和验证码')
                },
            }
        )
    }

    async loginBySecurity(security: string) {
        return callService<any>(
            this.apiURL,
            {
                Project: this.project,
                Service: 'user',
                Action: 'LoginBySecurity',
                Security: '',
                Param: {
                    security: security,
                },
            },
            {
                onSuccess: (v) => {
                    if (v && v.security) {
                        this.security = v.security
                    } else {
                        this.security = ''
                    }
                },
                onError: (e) => {
                    console.log(e)
                    this.security = ''
                },
            }
        )
    }

    async changePassword(oldPassword: string, newPassword: string) {
        return callService<any>(
            this.apiURL,
            {
                Project: this.project,
                Service: 'user',
                Action: 'ChangePassword',
                Security: this.security,
                Param: {
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                },
            },
            {
                onSuccess: (v) => {
                    showSuccess('密码修改成功')
                },
                onError: (e) => {
                    showError('密码修改失败')
                },
            }
        )
    }

    async logout() {
        return callService<any>(this.apiURL, {
            Project: this.project,
            Service: 'user',
            Action: 'Logout',
            Security: this.security,
            Param: {},
        })
    }
}
