import { Button, Checkbox, Row } from 'antd'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import config from '../../package.json'
import bgImage from '../assets/common/bg.png'
import { Page } from '../components/common/Page'
import { CenterWindow } from '../components/common/Window'
import { CheckCodeRectInput } from '../components/input/checkcode'
import { InputRectContainer } from '../components/input/container'
import { PasswordRectInput } from '../components/input/password'
import { StringRectInput } from '../components/input/string'
import { RootRouterAppHubLogin } from '../index'
import { App } from '../utils/app'
import { sha256 } from '../utils/crypto'
import { useValidateMap } from '../utils/hook'
import { getNavigatePath } from '../utils/location'
import { validateAlwaysOK, validatePassword, validatePhone, validateSmsCode, validateUsername } from '../utils/validate'

const IsDebug = config.app.Debug && config.app.DebugSystemSetup

export const RegisterPage: React.FC = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const backHRef = location.state?.backHRef

    const data = useValidateMap(
        new Map([
            ['name', validateUsername],
            ['phone', validatePhone],
            ['smsCode', validateSmsCode],
            ['passwordOne', validatePassword],
            ['passwordTwo', validatePassword],
            ['gender', validateAlwaysOK],
        ]),
        new Map([
            ['name', IsDebug ? '测试用户' : ''],
            ['phone', IsDebug ? '11111111111' : ''],
            ['smsCode', IsDebug ? '329726' : ''],
            ['passwordOne', IsDebug ? 'Test1234' : ''],
            ['passwordTwo', IsDebug ? 'Test1234' : ''],
            ['gender', 'male'],
        ])
    )

    return (
        <Page
            align="middle"
            justify="center"
            style={{
                background: `url(${bgImage}) no-repeat center center fixed`,
                backgroundSize: 'cover',
            }}
        >
            <CenterWindow width={364} height={636}>
                <Row justify={'center'} style={{ marginTop: 30, marginBottom: 24 }}>
                    <Row
                        style={{
                            width: 300,
                            fontSize: config.app.fontSizeBiggest,
                            color: config.app.fontColorStronger,
                            transition: config.app.transitionAll,
                        }}
                    >
                        注册制作人账号
                    </Row>
                </Row>

                <Row justify={'center'} style={{ marginTop: 6 }}>
                    <StringRectInput
                        label={'用户名'}
                        width={300}
                        value={data.valueMap.get('name')}
                        placeholder={'请输入用户名'}
                        allowClear={true}
                        onChange={(v) => {
                            data.setValue('name', v)
                        }}
                        disabled={loading}
                        error={data.errorMap.get('name')}
                    />
                </Row>
                <Row justify={'center'} style={{ marginTop: 6 }}>
                    <StringRectInput
                        label={'手机号'}
                        width={300}
                        value={data.valueMap.get('phone')}
                        placeholder={'系统管理员手机号'}
                        allowClear={true}
                        onChange={(v) => {
                            data.setValue('phone', v)
                        }}
                        disabled={loading}
                        error={data.errorMap.get('phone')}
                    />
                </Row>
                <Row justify={'center'} style={{ marginTop: 6 }}>
                    <CheckCodeRectInput
                        label={'验证码'}
                        width={300}
                        phone={data.valueMap.get('phone')}
                        value={data.valueMap.get('smsCode')}
                        placeholder={'手机验证码'}
                        onChange={(v) => {
                            data.setValue('smsCode', v)
                        }}
                        disabled={loading}
                        error={data.errorMap.get('smsCode')}
                    />
                </Row>
                <Row justify={'center'} style={{ marginTop: 6 }}>
                    <PasswordRectInput
                        label={'密码'}
                        width={300}
                        value={data.valueMap.get('passwordOne')}
                        placeholder={'输入密码'}
                        onChange={(v) => {
                            data.setValue('passwordOne', v)
                        }}
                        disabled={loading}
                        error={data.errorMap.get('passwordOne')}
                    />
                </Row>
                <Row justify={'center'} style={{ marginTop: 6 }}>
                    <PasswordRectInput
                        label={'确认密码'}
                        width={300}
                        value={data.valueMap.get('passwordTwo')}
                        placeholder={'输入密码'}
                        onChange={(v) => {
                            data.setValue('passwordTwo', v)
                        }}
                        disabled={loading}
                        error={
                            data.valueMap.get('passwordOne') !== data.valueMap.get('passwordTwo')
                                ? new Error('密码不一致')
                                : null
                        }
                    />
                </Row>
                <Row justify={'center'} style={{ marginTop: 6 }}>
                    <StringRectInput
                        label={'邮箱'}
                        width={300}
                        value={data.valueMap.get('email')}
                        placeholder={'联系邮箱'}
                        allowClear={true}
                        onChange={(v) => {
                            data.setValue('email', v)
                        }}
                        disabled={loading}
                        error={data.errorMap.get('email')}
                    />
                </Row>
                <Row justify={'center'} style={{ marginTop: 6 }}>
                    <InputRectContainer
                        style={{ border: 0 }}
                        label={'性别'}
                        width={300}
                        element={
                            <Row>
                                <Checkbox
                                    checked={data.valueMap.get('gender') === 'male'}
                                    onChange={() => {
                                        data.setValue('gender', 'male')
                                    }}
                                >
                                    男
                                </Checkbox>
                                <Checkbox
                                    checked={data.valueMap.get('gender') === 'famale'}
                                    onChange={() => {
                                        data.setValue('gender', 'famale')
                                    }}
                                >
                                    女
                                </Checkbox>
                            </Row>
                        }
                        disabled={loading}
                        error={data.errorMap.get('realname')}
                    />
                </Row>

                <Row justify={'center'} style={{ marginTop: 6 }}>
                    <Button
                        type={'primary'}
                        style={{ width: 300 }}
                        loading={loading}
                        disabled={
                            IsDebug
                                ? false
                                : !data.checked || data.valueMap.get('passwordOne') !== data.valueMap.get('passwordTwo')
                        }
                        onClick={() => {
                            if (!loading) {
                                setLoading(true)
                                App.Api.registerUser(
                                    data.valueMap.get('name'),
                                    data.valueMap.get('phone'),
                                    data.valueMap.get('smsCode'),
                                    sha256(data.valueMap.get('passwordOne')),
                                    data.valueMap.get('gender') === 'male',
                                    data.valueMap.get('email') || ''
                                )
                                    .then(async () => {
                                        if (backHRef && backHRef !== getNavigatePath()) {
                                            navigate(backHRef, {
                                                replace: true,
                                            })
                                        } else {
                                            navigate(RootRouterAppHubLogin, {
                                                replace: true,
                                            })
                                        }
                                    })
                                    .finally(async () => {
                                        setLoading(false)
                                    })
                            }
                        }}
                    >
                        注册
                    </Button>
                </Row>

                <Row justify={'center'} style={{ marginTop: 16 }}>
                    <Button
                        type={'ghost'}
                        style={{ width: 300 }}
                        loading={loading}
                        onClick={() => {
                            navigate(RootRouterAppHubLogin, {
                                replace: true,
                            })
                        }}
                    >
                        已有账号 直接登录
                    </Button>
                </Row>
            </CenterWindow>
        </Page>
    )
}
